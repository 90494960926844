import React, { useMemo, useState } from 'react';
import GridOnIcon from '@material-ui/icons/GridOn';
import TableChartIcon from '@material-ui/icons/TableChart';
import Tooltip from '@material-ui/core/Tooltip';
import { toReal, truncateString } from '../../../../_core/services/formaters';
import DocumentationIcon from '../../../../assets/documentation.png';
import VentoIcon from '../../../../assets/vento.png';
import ListIcon from '../../../../assets/list.png';
import JudgeIcon from '../../../../assets/judge.png';
import GuiaIcon from '../../../../assets/guia.png';
import BillIcon from '../../../../assets/bill.png';

import * as S from '../styles';
import * as TS from '../table-styles';
import { Button } from '../../../../_core/_components';
import { AcompanhamentoAjuizamentoItem, AjuizamentoResponse } from '../types';

type Props = {
  ajuizamentos: AjuizamentoResponse;
  handleClickAcompanhar: (acao: AcompanhamentoAjuizamentoItem) => void;
  handleExport: () => void;
  handlePageChange: (_: any, page: number) => void;
  page: number;
};

const Main: React.FC<Props> = ({ ajuizamentos, handleClickAcompanhar, handleExport, handlePageChange, page }) => {
  const [currentView, setCurrentView] = useState<'table' | 'card'>('table');

  const getAcaoIcon = (fase: string) => {
    switch (fase) {
      case 'Documentação':
        return DocumentationIcon;
      case 'Guia de Pagamento':
        return GuiaIcon;
      case 'PO':
        return VentoIcon;
      case 'Comprovantes de Pagamento':
        return BillIcon;
      case 'Chamado para Ajuizamento':
        return ListIcon;
      case 'Ação Ajuizada':
        return JudgeIcon;

      default:
        return JudgeIcon;
    }
  };

  const ajuizamentoDetails = useMemo(() => {
    if (ajuizamentos?.pageInfo === null) {
      return null;
    }

    return (
      <>
        <S.AcoesPorFase>
          <h2>Ações por Fase</h2>
          <S.Acoes>
            {ajuizamentos?.pageInfo.acoesPorFase.map((item, i) => {
              return (
                <S.Acao key={i}>
                  <S.FaseIcon src={getAcaoIcon(item.fase)} />
                  <S.AcaoText>{item.fase}</S.AcaoText>
                  <S.AcaoPercent>{item.percentual.toFixed(2)}%</S.AcaoPercent>
                </S.Acao>
              );
            })}
            <S.TotaizadorBox>
              <S.TotalizadorLine>
                Total de Contratos: <S.TotalValue>{ajuizamentos?.pageInfo.totalContratos}</S.TotalValue>{' '}
              </S.TotalizadorLine>
              <S.TotalizadorLine>
                Total de Clientes: <S.TotalValue> {ajuizamentos?.pageInfo.totalClientes}</S.TotalValue>
              </S.TotalizadorLine>
              <S.TotalizadorLine>
                Valor Total de Contratos:{' '}
                <S.TotalValue> {toReal(ajuizamentos?.pageInfo.saldoAbertoTotal)} </S.TotalValue>
              </S.TotalizadorLine>
            </S.TotaizadorBox>
          </S.Acoes>
        </S.AcoesPorFase>
      </>
    );
  }, [ajuizamentos]);

  return (
    <>
      {ajuizamentoDetails}
      <S.ViewChanger>
        <GridOnIcon onClick={() => setCurrentView('card')} style={S.getGridIconStyle(currentView)} />
        <TableChartIcon onClick={() => setCurrentView('table')} style={S.getTableIconStyle(currentView)} />
      </S.ViewChanger>
      {currentView === 'table' && (
        <S.TableWrapper>
          <TS.TableContainer>
            <TS.CustTable>
              <thead>
                <tr>
                  <TS.HeaderItem>Origem</TS.HeaderItem>
                  <TS.HeaderItem>Shopping</TS.HeaderItem>
                  <TS.HeaderItem>Nome Fantasia</TS.HeaderItem>
                  <TS.HeaderItem>LUC</TS.HeaderItem>
                  <TS.HeaderItem>Razão Social</TS.HeaderItem>
                  <TS.HeaderItem>CPF/CNPJ</TS.HeaderItem>
                  <TS.HeaderItem>Contrato</TS.HeaderItem>
                  <TS.HeaderItem>Saldo Devedor Corrigido</TS.HeaderItem>
                  <TS.HeaderItem>Saldo Aberto Vencido para Critério</TS.HeaderItem>
                  <TS.HeaderItem>Saldo Aberto Total</TS.HeaderItem>
                  <TS.HeaderItem>Tipo Ação</TS.HeaderItem>
                  <TS.HeaderItem>Tipo Dívida</TS.HeaderItem>
                  <TS.HeaderItem>Status do Processo</TS.HeaderItem>
                  <TS.HeaderItem>Fase Atual</TS.HeaderItem>
                  <TS.HeaderItem>Prazo de Conclusao</TS.HeaderItem>
                </tr>
              </thead>
              <tbody>
                {ajuizamentos?.data.map((acao, i) => {
                  return (
                    <TS.TableRow onClick={() => handleClickAcompanhar(acao)} key={i}>
                      <TS.RowItem>{acao.origem}</TS.RowItem>
                      <TS.RowItem>{acao.shopping}</TS.RowItem>
                      <TS.RowItem>{acao.nomeFantasia}</TS.RowItem>
                      <TS.RowItem>{acao.luc}</TS.RowItem>
                      <TS.RowItem>
                        <Tooltip title={acao.razaoSocial}>
                          <span>{truncateString(acao.razaoSocial, 14)}</span>
                        </Tooltip>
                      </TS.RowItem>
                      <TS.RowItem>{acao.cpfCnpj}</TS.RowItem>
                      <TS.RowItem>{acao.contrato}</TS.RowItem>
                      <TS.RowItem>{acao.saldoVencidoAtualizado && toReal(acao.saldoVencidoAtualizado)}</TS.RowItem>
                      <TS.RowItem>{acao.saldoAbertoCriterio && toReal(acao.saldoAbertoCriterio)}</TS.RowItem>
                      <TS.RowItem>{acao.saldoAbertoTotal && toReal(acao.saldoAbertoTotal)}</TS.RowItem>
                      <TS.RowItem>{acao.tipoAcao}</TS.RowItem>
                      <TS.RowItem>{acao.tipoDivida}</TS.RowItem>
                      <TS.RowItem>{acao.statusProcesso}</TS.RowItem>
                      <TS.RowItem>{acao.faseAtual}</TS.RowItem>
                      <TS.RowItem>{acao.prazoConclusao}</TS.RowItem>
                    </TS.TableRow>
                  );
                })}
              </tbody>
            </TS.CustTable>
          </TS.TableContainer>
        </S.TableWrapper>
      )}
      {currentView === 'card' && (
        <S.CardWrapper>
          {ajuizamentos?.data.map((acao, i) => {
            return (
              <S.Card key={i}>
                <S.CardLine>
                  <S.CardCol>
                    <S.CardLabel>Tipo de Dívida</S.CardLabel>
                    <S.CardInfo tipo={acao.tipoDivida}>{acao.tipoDivida}</S.CardInfo>
                  </S.CardCol>
                </S.CardLine>
                <S.CardSeparator />
                <S.CardLine>
                  <S.CardCol>
                    <S.CardLabel>Origem</S.CardLabel>
                    <S.CardInfo bold>{acao.origem}</S.CardInfo>
                  </S.CardCol>
                  <S.CardCol>
                    <S.CardLabel>Shopping</S.CardLabel>
                    <S.CardInfo bold>{acao.shopping}</S.CardInfo>
                  </S.CardCol>
                </S.CardLine>
                <S.CardLine>
                  <S.CardCol>
                    <S.CardLabel>Nome Fantasia</S.CardLabel>
                    <S.CardInfo bold>{acao.nomeFantasia}</S.CardInfo>
                  </S.CardCol>
                  <S.CardCol>
                    <S.CardLabel>LUC</S.CardLabel>
                    <S.CardInfo bold>{acao.luc}</S.CardInfo>
                  </S.CardCol>
                </S.CardLine>
                <S.CardLine>
                  <S.CardCol>
                    <S.CardLabel>Razão Social</S.CardLabel>
                    <S.CardInfo bold>{truncateString(acao.razaoSocial, 14)}</S.CardInfo>
                  </S.CardCol>
                  <S.CardCol>
                    <S.CardLabel>CPF/CNPJ</S.CardLabel>
                    <S.CardInfo bold>{acao.cpfCnpj}</S.CardInfo>
                  </S.CardCol>
                </S.CardLine>
                <S.CardSeparator />
                <TS.AcompanharButton full onClick={() => handleClickAcompanhar(acao)}>
                  ACOMPANHAR
                </TS.AcompanharButton>
              </S.Card>
            );
          })}
        </S.CardWrapper>
      )}
      {ajuizamentos.pageInfo && (
        <>
          <S.ActionsRow>
            <Button variant="outlined" text="Exportar" onClick={() => handleExport()} />
            <TS.Pagination
              count={ajuizamentos?.pageInfo.totalPages}
              page={page}
              // onChange={handleChangePagination}
              onChange={handlePageChange}
            />
          </S.ActionsRow>
        </>
      )}
    </>
  );
};

export default Main;
