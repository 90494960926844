import React from 'react';
import * as S from './styles';
import { Resumo } from './types';

export function Table({ data, visaoResumo }: { data: Resumo; visaoResumo: string }): JSX.Element {
  return (
    <S.TableContainer>
      <thead>
        <tr>
          <th rowSpan={2}>RESUMO</th>
          <th colSpan={4}>{visaoResumo.toUpperCase()}</th>
          <th colSpan={2}>INADIMPLÊNCIA YTD</th>
        </tr>
        <tr>
          <th>ALUGUEL</th>
          <th>CONDOMÍNIO</th>
          <th>FUNDO</th>
          <th>COMERCIAIS</th>
          <th>ALUGUEL</th>
          <th>CONDOMÍNIO</th>
        </tr>
      </thead>
      <tbody>
        {data.map(item => (
          <tr key={item.id}>
            <td>{item.descricao.descricao}</td>
            <td>{item.valorMensalAluguel?.toLocaleString('pt-br')}</td>
            <td>{item.valorMensalCondominio?.toLocaleString('pt-br')}</td>
            <td>{item.valorMensalFundo?.toLocaleString('pt-br')}</td>
            <td>{item.valorMensalComerciais?.toLocaleString('pt-br')}</td>
            <td>{item.valorAnualAluguel?.toLocaleString('pt-br')}</td>
            <td>{item.valorAnualCondominio?.toLocaleString('pt-br')}</td>
          </tr>
        ))}
      </tbody>
    </S.TableContainer>
  );
}
