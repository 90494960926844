import styled from 'styled-components';
import { createStyles, makeStyles, withStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

export const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: '#fff',
      color: '#00959c',
      fontWeight: 'bold',
      border: '1px solid #E5E9EA',
    },
    body: {
      fontSize: 12,
    },
    root: {
      padding: '4px',
      borderBottom: 'none',
    },
  }),
)(TableCell);

export const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#FBFBFB',

      '&:nth-of-type(4n), &:nth-of-type(4n-3)': {
        backgroundColor: '#FBFBFB',
      },
      '&:nth-of-type(4n-1), &:nth-of-type(4n)': {
        backgroundColor: '#E5E9EA', // Cor para linhas não
      },
    },
  }),
)(TableRow);

export const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export const Wrapper = styled.div`
  font-size: 10px;
`;

export const Title = styled.div`
  font-size: 1%.8;
  color: #1e1e1e;
  font-weight: 600;
`;

export const selectContainerStyles = {
  width: '13rem',
};

export const selectControlStyles = {
  fontSize: '0.8rem',
  width: '100%',
};

export const ActionsWrapper = styled.div`
  margin-top: 4em;
  display: flex;
  justify-content: right;
  gap: 6px;
`;
