import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import api from '../../../_core/api';
import { AuthContext } from '../../../_main/contexts/auth';
import { handleErrors, warning } from '../../../_core/services/toast';
import { Input, Button, Option, Form, PaginatedTable, SingleSelect } from '../../../_core/_components';
import { downloadXls } from '../../../_core/services/download';
import { mascaraDDMMAAAA } from '../../../_core/masks';
import { Boleto } from './types';

const DATA_ATUAL = moment().format('DD/MM/YYYY');

export const CartasEmail: React.FC = () => {
  const { shoppings: profileShoppings } = useContext(AuthContext);
  const [shoppings, setShoppings] = useState<Option[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<Option>();
  const [cliente, setCliente] = useState('');
  const [luc, setLuc] = useState('');
  const [boleto, setBoleto] = useState('');
  const [tipoAcao, setTipoAcao] = useState<string>('');
  const [dtprocessamento, setDtprocessamento] = useState(DATA_ATUAL);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Boleto[]>([]);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    totalPages: 0,
  });

  const getInfo = async (page: number) => {
    setData([]);
    setLoading(true);
    try {
      const response = await api.get(`/notified-bills/list`, {
        params: {
          page,
          nomeShopping: selectedShoppings?.label,
          dtprocessamento:
            dtprocessamento.length === 10 ? moment(dtprocessamento, 'DD/MM/YYYY').format('YYYY-MM-DD') : undefined,
          nomeRazao: cliente.length > 0 ? cliente : undefined,
          luc: luc.length > 0 ? luc : undefined,
          numBoleto: boleto.length > 0 ? boleto : undefined,
          tipoAcao: tipoAcao.length > 0 ? tipoAcao : undefined,
        },
      });
      if (_.isEmpty(response.data.data)) {
        warning('Não obteve resultados.');
      } else {
        setPageInfo(response.data.pageInfo);
        setData(response.data.data);
      }
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const exportXls = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/notified-bills/xls`, {
        responseType: 'arraybuffer',
        params: {
          nomeShopping: selectedShoppings?.label,
          dtprocessamento:
            dtprocessamento.length === 10 ? moment(dtprocessamento, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
          nomeRazao: cliente.length > 0 ? cliente : undefined,
          luc: luc.length > 0 ? luc : undefined,
          numBoleto: boleto.length > 0 ? boleto : undefined,
          tipoAcao: tipoAcao.length > 0 ? tipoAcao : undefined,
        },
      });
      downloadXls(response);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (profileShoppings) {
      const listShopping: Option[] = profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
      listShopping.sort((item1: Option, item2: Option) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppings(listShopping);
    }
  }, [profileShoppings]);

  const renderedData = useMemo(() => {
    if (data.length > 0) {
      return data.map(item => ({
        ...item,
        dtVencimentoBoleto: item.dtVencimentoBoleto ? moment(item.dtVencimentoBoleto).format('DD/MM/YYYY') : '',
        dtProcessamento: item.dtProcessamento ? moment(item.dtProcessamento).format('DD/MM/YYYY') : '',
        valBoleto: item.valBoleto?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
      }));
    }
    return [];
  }, [data]);

  const resetFilters = () => {
    setSelectedShoppings(undefined);
    setCliente('');
    setLuc('');
    setBoleto('');
    setTipoAcao('');
    setDtprocessamento(DATA_ATUAL);
  };

  const submitRow = (
    <div style={{ gap: '1rem', display: 'flex' }}>
      <Button text="Pesquisar" onClick={() => getInfo(1)} disabled={dtprocessamento.length !== 10} />
      <Button text="Limpar filtros" onClick={resetFilters} />
    </div>
  );

  return (
    <>
      <FullScreenLoading isEnabled={loading} />
      <Form
        items={[
          <SingleSelect
            state={[selectedShoppings, setSelectedShoppings]}
            placeholder="Shoppings"
            options={shoppings}
          />,
          <Input label="Cliente" state={[cliente, setCliente]} />,
          <Input label="Luc" state={[luc, setLuc]} />,
          <Input label="Boleto" state={[boleto, setBoleto]} />,
          <Input
            label="Data"
            state={[dtprocessamento, setDtprocessamento]}
            pattern={mascaraDDMMAAAA}
            error={dtprocessamento.length !== 10}
          />,
        ]}
        submitButton={submitRow}
      />
      {renderedData.length > 0 && (
        <>
          <PaginatedTable
            columns={[
              { label: 'Shopping', key: 'nomeShopping', orderable: true },
              { label: 'Cliente', key: 'nomeRazaoSocial', orderable: true },
              { label: 'Tipo Cliente', key: 'tipoNomeRazaoSocial', orderable: true },
              { label: 'LUC', key: 'luc', orderable: true },
              { label: 'Boleto', key: 'numBoleto', orderable: true },
              { label: 'Vencimento', key: 'dtVencimentoBoleto', orderable: true },
              { label: 'Valor do boleto', key: 'valBoleto', orderable: true },
              { label: 'Data da comunicação', key: 'dtProcessamento', orderable: true },
              { label: 'Tipo da ação', key: 'nomeArquivo', orderable: true },
            ]}
            data={renderedData}
            pagination={pageInfo}
            onChangePage={getInfo}
          />
          <Button text="Exportar" onClick={exportXls} />
        </>
      )}
    </>
  );
};
