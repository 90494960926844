import React, { useRef, useState } from 'react';
import { CloudUpload } from '@material-ui/icons';
import api from '../../../_core/api';
import icon from '../../../assets/cloud-computing.svg';
import { Container, Content, ImportArea, InputGroup } from './styles';
import { Button, Input } from '../../../_core/_components';
import { handleErrors } from '../../../_core/services/toast';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';

export const ImportacaoAjuizamento: React.FC = () => {
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const refXls = useRef<HTMLInputElement | null>(null);

  const sendFormXls = async () => {
    setLoading(true);
    try {
      if (file) {
        const formdata = new FormData();
        formdata.append('file', file);
        await api.post(`/judgment/upload`, formdata);
      }
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const blob = e.target.files ? e.target.files[0] : undefined;
    if (blob === undefined) return;
    setFile(blob);
  };

  return (
    <Container>
      <FullScreenLoading isEnabled={loading} />
      <Content />
      <Content>
        <h1>Importar dados da planilha com a apuração final do ajuizamento</h1>
        <ImportArea onClick={() => refXls.current?.click()}>
          <h2>Buscar arquivos</h2>
          <img src={icon} alt="" />
          <InputGroup onClick={e => e.stopPropagation()}>
            <Input
              state={[file?.name ?? 'Escolher arquivo', () => null]}
              disabled
              style={{ backgroundColor: 'white' }}
              startIcon={<CloudUpload />}
            />
            <input id="inputFileXls" type="file" hidden onChange={onFileChange} ref={refXls} />
          </InputGroup>
        </ImportArea>
        <Button text="Importar" onClick={sendFormXls} />
      </Content>
      <Content />
    </Container>
  );
};
