/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles, withStyles, createStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import { CircularProgress } from '@material-ui/core';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useSpring, animated } from 'react-spring'; // web.cjs is required for IE 11 support
import { TransitionProps } from '@material-ui/core/transitions';
import Collapse from '@material-ui/core/Collapse';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import moment from 'moment';
import TextField from '../../../../../_core/_components/text-field';
import * as S from './styles';
import { toReal } from '../../../../../_core/services/formaters';
import Button from '../../../components/button';
import { Contract, Slip } from '../../types';
import { TableRow } from '../../../../configuracoes/parametros/components/table';
import api from '../../../../../_core/api';
import { handleErrors, success } from '../../../../../_core/services/toast';
import { AuthContext } from '../../../../../_main/contexts/auth';
import { Option, SingleSelect } from '../../../../../_core/_components';
import { FullScreenLoading } from '../../../../../_core/_components/fullscreen-loading';

function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = withStyles(() =>
  createStyles({
    root: {
      fontSize: '12px',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    label: {
      fontSize: '12px',
    },
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed #00959c`,
    },
  }),
)((props: TreeItemProps) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles(
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 600,
    },
  }),
);

type Props = {
  contracts?: Contract[];
  onClose: () => void;
  onRequestUpdate: () => void;
};

const ModalNegociar: React.FC<Props> = ({ onClose, contracts, onRequestUpdate }) => {
  const classes = useStyles();
  const [tiposNegociacao, setTiposNegociacao] = useState<TableRow[]>();
  const [isLoading, setLoading] = useState(false);
  const [contactDate, setContactDate] = useState('');
  const [agreementDate, setAgreementDate] = useState('');
  const [observation, setObservation] = useState('');
  const [selectedTipoNegociacao, setSelectedTipoNegciacao] = useState<Option>();

  const { userName } = useContext(AuthContext);

  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    try {
      setLoading(true);
      const resTypeNeg = await api.get<TableRow[]>('/api/allos/v1/prioritization/type-negotiation/all');
      setTiposNegociacao(resTypeNeg.data);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const filteredContracts = contracts?.filter(c => c.slips && c.slips.length > 0);

  const formIsValid = () => {
    const validateDates = isDisabled ? !contactDate : !contactDate || !agreementDate;
    if (!observation || validateDates || !selectedTipoNegociacao?.value) {
      return false;
    }

    return true;
  };

  const saveNegotiation = async () => {
    try {
      setLoading(true);
      const slips = filteredContracts?.reduce((acc: Slip[], contract) => {
        if (contract.slips) {
          return acc.concat(contract.slips);
        }
        return acc;
      }, []);

      const payload = {
        contactDate,
        agreementDate: isDisabled ? contactDate : agreementDate,
        idNegotiationType: selectedTipoNegociacao?.value,
        slipIds: slips?.map(s => s.slipId),
        observation,
        usuarioInclusao: userName,
      };

      await api.post('/api/allos/v1/prioritization/agreement-negotiation-slip', payload);
      success('Registrado com sucesso');
      onRequestUpdate();
      handleClose();
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const resetComponent = () => {
    setTiposNegociacao(undefined);
    setContactDate('');
    setAgreementDate('');
    setObservation('');
    setSelectedTipoNegciacao(undefined);
  };

  const handleClose = () => {
    resetComponent();
    onClose();
  };

  const tiposNegociacaoOption = useMemo(() => {
    if (tiposNegociacao) {
      return tiposNegociacao.map(e => ({ value: e.id, label: e.description }));
    }
    return [];
  }, [tiposNegociacao]);

  const isDisabled = useMemo(() => {
    if (selectedTipoNegociacao) {
      return ['Sem Previsão', 'Contato Sem Sucesso'].includes(selectedTipoNegociacao.label);
    }
    return false;
  }, [selectedTipoNegociacao]);

  return (
    <Dialog fullWidth maxWidth="lg" open onClose={handleClose} aria-labelledby="max-width-dialog-title">
      {isLoading && <FullScreenLoading isEnabled={isLoading} />}
      <S.DialogWrapper>
        <S.CloseAction onClick={handleClose}>
          <CloseIcon />
        </S.CloseAction>
        <S.DialogHeader>
          <h1>Negociar</h1>
        </S.DialogHeader>
        <S.DialogContent>
          <S.Section>
            <S.SectionTitle>SELEÇÃO</S.SectionTitle>
            <S.DialogSelecao>
              <TreeView
                className={classes.root}
                defaultCollapseIcon={<MinusSquare />}
                defaultExpandIcon={<PlusSquare />}
                defaultExpanded={
                  filteredContracts && Array.from({ length: filteredContracts.length + 1 }, (_, i) => i.toString())
                }
              >
                {filteredContracts?.map((contrato, idx) => (
                  <StyledTreeItem key={idx} nodeId={String(idx)} label={`Contrato ${contrato.contractId}`}>
                    <StyledTreeItem
                      nodeId={`header_${idx}`}
                      label={
                        <S.TreeLabelBolHeader>
                          <span>Boleto</span>
                          <span>Vencimento</span>
                          <span>Valor</span>
                        </S.TreeLabelBolHeader>
                      }
                    />
                    {contrato?.slips?.map((bol, i) => (
                      <StyledTreeItem
                        key={i}
                        nodeId={String(bol.slipId)}
                        label={
                          <S.TreeLabelItem>
                            <span>{bol.slipNumber}</span>
                            <span>{moment(bol.dueDate).format('DD/MM/YYYY')}</span>
                            <span>{toReal(bol.slipValue)}</span>
                          </S.TreeLabelItem>
                        }
                      >
                        {/* <StyledTreeItem
                          nodeId={`header_${bol.slipId}`}
                          label={
                            <S.TreeLabelBolHeader>
                              <span>Transação</span>
                              <span>Vencimento</span>
                              <span>Valor</span>
                            </S.TreeLabelBolHeader>
                          }
                        />
                        {bol.transacoes.map(t => (
                          <StyledTreeItem
                            nodeId={`transacao_${t.numeroTransacao}`}
                            label={
                              <S.TreeLabelItem>
                                <span>{t.numeroTransacao}</span>
                                <span>{t.dataVencimento}</span>
                                <span>{toReal(t.valor)}</span>
                              </S.TreeLabelItem>
                            }
                          />
                        ))} */}
                      </StyledTreeItem>
                    ))}
                  </StyledTreeItem>
                ))}
              </TreeView>
            </S.DialogSelecao>
          </S.Section>

          <S.Section>
            <S.SectionTitle>DADOS</S.SectionTitle>
            <S.DialogDados>
              <S.InputLine>
                <S.InputGroup>
                  <S.Label>Data do Contato</S.Label>
                  <TextField
                    state={[contactDate, v => setContactDate(v)]}
                    fullWidth
                    type="date"
                    size="small"
                    variant="outlined"
                  />
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>Data do Acordo</S.Label>
                  <TextField
                    state={[isDisabled ? contactDate : agreementDate, v => setAgreementDate(v)]}
                    fullWidth
                    type="date"
                    size="small"
                    variant="outlined"
                    disabled={isDisabled}
                  />
                </S.InputGroup>
                <S.InputGroup>
                  <S.Label>Estágio de Negociação</S.Label>
                  <SingleSelect
                    state={[selectedTipoNegociacao, setSelectedTipoNegciacao]}
                    options={tiposNegociacaoOption}
                  />
                </S.InputGroup>
              </S.InputLine>
              <S.InputLine>
                <S.InputGroupFull>
                  <S.Label>Observações</S.Label>
                  <TextField
                    state={[observation, v => setObservation(v)]}
                    fullWidth
                    multiline
                    size="small"
                    variant="outlined"
                    rows={5}
                  />
                </S.InputGroupFull>
              </S.InputLine>
            </S.DialogDados>
          </S.Section>
        </S.DialogContent>
        <S.DialogActions>
          <Button onClick={saveNegotiation} startIcon={<SaveIcon />} text="Salvar" disabled={!formIsValid()} />
        </S.DialogActions>
      </S.DialogWrapper>
    </Dialog>
  );
};

export default ModalNegociar;
