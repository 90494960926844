import { IconButton, withStyles } from '@material-ui/core';
import styled from 'styled-components';

type CustomIconButtonProps = {
  isActive: boolean;
};
export const CustomIconButton = withStyles({
  root: (props: CustomIconButtonProps) => ({
    color: props.isActive ? '#008c90' : '#9e9e9e',
  }),
})(IconButton);

export const Container = styled.div`
  > h2 {
    color: #7e9395;

    margin-bottom: 1rem;
    font-weight: 600;
  }

  .approveNegative__multiselect {
    padding: 0.9rem 2.2rem;
    p {
    }
  }
`;

export const Section = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
`;
