import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 10px;
`;
export const Form = styled.div``;
export const FormGroup = styled.div`
  margin: 2em;
`;
export const FormLabel = styled.div`
  font-size: 1.4em;
  font-weight: 400;
  color: #8890b5;
`;
export const Text = styled.p`
  font-weight: 700;
  font-size: 1.5em;
  color: #555555;
`;
export const TextBox = styled.textarea`
  background-color: #fff;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 32px;
  border: 1px solid #7e93954d;
  width: 100%;
  height: 20rem;

  ::placeholder {
    color: #7e9395;
  }
`;
