import styled, { css } from 'styled-components';
import Pages from '@material-ui/lab/Pagination';

/* Table styles */
export const TableContainer = styled.div`
  margin-top: 1rem;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const CustTable = styled.table`
  width: 100%;
  text-align: left;
  border-spacing: 0px;
  font-size: 0.7rem;
`;

export const TableRow = styled.tr`
  color: #3f3f3f;
  transition: all 0.3s ease;
  cursor: pointer;

  &:nth-child(even) {
    background-color: #fff;
  }

  &:nth-child(odd) {
    background-color: #46e0e81f;
  }
  &:hover {
    background-color: #006369bd;
    color: #fff;
  }
`;

export const RowItem = styled.td`
  padding: 0.7rem 0.5rem;
  .row-select > div {
    width: 15rem;
  }
`;

export const HeaderItem = styled.td`
  color: #00959c;
  padding: 0rem 1rem;
  font-weight: 700;
`;

export const Pagination = styled(Pages)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ul {
    flex-wrap: nowrap;
    padding-left: 20px;
  }
  li {
    width: 28px;
    height: 28px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1px;

    button {
      border-radius: 0 !important;
      color: #7e9395 !important;
      font-size: 9px !important;
      font-weight: 600 !important;
      span {
        border-radius: 0 !important;
      }
    }

    .MuiPaginationItem-page.Mui-selected {
      background-color: #00959c27;
    }

    .MuiPaginationItem-page:hover {
      background-color: #00959c27;
    }
  }
`;

export const PaginationWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  .MuiPaginationItem-root {
    margin: 0 !important;
    border-radius: 0 !important;
    border: 1px solid #edeeee !important;
    color: #7e9395 !important;
    font-weight: 600 !important;
    font-family: 'Open Sans', sans-serif !important ;
  }

  .MuiPaginationItem-page.Mui-selected {
    background-color: #edeeee !important;
  }

  .MuiPaginationItem-ellipsis {
    border: none !important;
  }
`;

type AcompanharButtonProps = {
  full?: boolean;
};

export const AcompanharButton = styled.button<AcompanharButtonProps>`
  background-color: #00959c;
  color: #fff;
  border: none;
  padding: 0.3rem 1.3rem;
  border-radius: 5px;
  font-size: 0.7rem;

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}
`;
