import styled from 'styled-components';

export const ModalHeader = styled.div`
  height: 68px;
  min-height: 68px;
  background-color: #008c90;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
`;

export const Title = styled.span`
  color: white;
  font-size: 24px;
  font-weight: bold;
`;
