import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import moment from 'moment';
import {
  Container,
  MainRow,
  SearchColumn,
  UploadColumn,
  SizedBox,
  UploadBox,
  UploadFileRow,
  SizedBoxHeight,
  Selecteds,
  FileDownload,
} from './styles';
import Modal from './modal';
import api from '../../../_core/api';
import Table from '../../../_core/_components/table';
import { handleErrors, success } from '../../../_core/services/toast';
import { downloadXls } from '../../../_core/services/download';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { useCache } from '../../../_core/cache';
import { requests } from '../../../requests';
import { Form, Select, Option, Button, Input, SingleSelect, Checkbox } from '../../../_core/_components';
import { mascaraDDMMAAAA, mascaraMMAAAA } from '../../../_core/masks';
import { verificaTamanhoEmBytes } from '../../../_core/services/permissions';

export const ProcessamentoInadimplencia: React.FC = () => {
  const { response: responseFiltersShoppings, isLoading: isLoadingFiltersShoppings } = useCache({
    key: 'getAllShoppings',
    fn: requests.get('getAllShoppings'),
  });
  const { response: responseStatus, isLoading: isLoadingStatus } = useCache({
    key: 'getStatus',
    fn: requests.get('getStatus'),
  });

  const ref = useRef<any>();
  const [shoppingsOptions, setShoppingsOptions] = useState<Option[]>([]);
  const [statusOptions, setStatusOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [shopping, setShopping] = useState<Option[]>([]);
  const [date, setDate] = useState('');
  const [processDate, setProcessDate] = useState('');
  const [status, setStatus] = useState<Option>();
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [result, setResult] = useState<any[]>([]);
  const [shoppings, setShoppings] = useState<string[]>([]);

  useEffect(() => {
    if (responseFiltersShoppings !== undefined) {
      const shoppingList = responseFiltersShoppings.data.map((item: any) => ({
        value: item.id,
        label: item.nome,
      }));
      setShoppingsOptions(shoppingList);
    }
  }, [responseFiltersShoppings]);

  useEffect(() => {
    if (responseStatus !== undefined) {
      const statusList: Option[] = [
        {
          value: 0,
          label: 'Selecione',
        },
      ];
      responseStatus.data.forEach((item: any) => {
        statusList.push({
          value: item.id,
          label: item.status,
        });
      });
      setStatusOptions(statusList);
    }
  }, [responseStatus]);

  const data = useMemo(
    () =>
      result.map(e => ({
        id: e.id,
        nameShopping: e.nameShopping,
        processingDate: moment(e.processingDate).format('DD/MM/YYYY'),
        dateCreated: moment(e.dateCreated).format('DD/MM/YYYY hh:mm:ss'),
        status: e.status.status,
      })),
    [result],
  );

  const onClickSearch = useCallback(async () => {
    setLoading(true);
    try {
      const [month, year] = date.split('/');
      const queryDate = year ? `month=${month}&year=${year}` : '';
      const queryShopping = shopping.length > 0 ? `shopping=${shopping.map(e => e.label).join(',')}` : '';
      const queryProcessDate = processDate ? `processingDate=${convertDate(processDate)}` : '';
      const queryStatus = status?.value ? `status=${status!.value}` : '';
      const query = [queryDate, queryShopping, queryProcessDate, queryStatus].filter(e => e).join('&');
      const response = await Promise.all([
        api.get(`/spredsheet/shopping/ORACLE?${query}`),
        api.get(`/spredsheet/shopping/ADJUSTMENT?${query}`),
      ]);
      setResult([...response[0].data, ...response[1].data]);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  }, [shopping, date, status, processDate]);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const blob = e.target.files ? e.target.files[0] : undefined;
    if (blob === undefined) return;
    if (verificaTamanhoEmBytes(blob!.size)) {
      setFileSizeError(true);
      setFile(undefined);
      return;
    }
    setFileSizeError(false);
    setFile(blob);
  };

  const onClickReset = () => {
    ref.current.value = '';
    setFile(undefined);
  };

  const onClickSubmitUpload = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const dateForm = moment().format('MM/YYYY');
      const formData = new FormData();
      formData.append('file', file!);
      formData.append('dateReference', dateForm);
      formData.append('spreadSheetType', 'ADJUSTMENT');
      await api.post('/producer/updload/spreadsheet', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setFile(undefined);
      success('Envio realizado com sucesso');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const convertDate = (value: string) => {
    const arr = value.split('/');
    return `${arr[2]}-${arr[1]}-${arr[0]}`;
  };

  const onClickDownload = useCallback(
    async (item: any) => {
      setLoading(true);
      const response = await api.get(`/spredsheet/download/${item.id}`, { responseType: 'arraybuffer' });
      downloadXls(response);
      setLoading(false);
    },
    [result],
  );

  const onClickCheckbox = (item: any) => {
    const arr = [...shoppings];
    if (arr.includes(item.nameShopping)) {
      setShoppings(arr.filter(e => e !== item.nameShopping));
    } else {
      arr.push(item.nameShopping);
      setShoppings(arr);
    }
  };

  const onClickProcess = async (values: any) => {
    setLoading(true);
    setOpen(false);
    try {
      await api.post(`/base-inadimplencia`, { ...values, shoppings });
      success('Processo de cálculo submetido com sucesso');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const bToggleAll = useMemo(() => {
    const allShoppingsNames = data.map(e => e.nameShopping);
    return allShoppingsNames.every(e => shoppings.includes(e));
  }, [shoppings, data]);

  const onToggleAll = () => {
    const allShoppingsNames = data.map(e => e.nameShopping);
    if (bToggleAll) {
      setShoppings([]);
    } else {
      setShoppings(allShoppingsNames);
    }
  };

  const renderedData = useMemo(() => {
    if (data.length > 0) {
      return data.map(item => ({
        toggle: <Checkbox checked={shoppings.includes(item.nameShopping)} onClick={() => onClickCheckbox(item)} />,
        nameShopping: item.nameShopping,
        processingDate: item.processingDate,
        dateCreated: item.dateCreated,
        status: item.status,
        download: <FileDownload onClick={() => onClickDownload(item)}>Download</FileDownload>,
      }));
    }
    return [];
  }, [data, shoppings]);

  return (
    <>
      <FullScreenLoading isEnabled={loading || isLoadingFiltersShoppings || isLoadingStatus} />
      <Container>
        <MainRow>
          <SearchColumn>
            <Form
              items={[
                <Select placeholder="Shoppings" options={shoppingsOptions} state={[shopping, setShopping]} />,
                <Input
                  placeholder="MM/AAAA"
                  label="Mês e ano de processamento"
                  state={[date, setDate]}
                  pattern={mascaraMMAAAA}
                />,
                <Input
                  placeholder="DD/MM/AAAA"
                  label="Data de processamento"
                  state={[processDate, setProcessDate]}
                  pattern={mascaraDDMMAAAA}
                />,
                <SingleSelect placeholder="Status" options={statusOptions} state={[status, setStatus]} />,
              ]}
              submitButton={<Button text="Pesquisar" onClick={onClickSearch} />}
            />
          </SearchColumn>
          <SizedBox />
          <UploadColumn>
            <UploadBox onSubmit={onClickSubmitUpload}>
              <label htmlFor="selecao-arquivo-input" id="selecao-arquivo-label">
                Selecionar um arquivo
              </label>
              <input id="selecao-arquivo-input" type="file" onChange={onFileChange} ref={ref} />
              {fileSizeError && (
                <UploadFileRow>
                  <span>O arquivo não pode exceder 1MB.</span>
                </UploadFileRow>
              )}
              {file && !fileSizeError && (
                <UploadFileRow>
                  <span>{file?.name}</span>
                  <SizedBox />
                  <span id="remove-span" onClick={onClickReset}>
                    x
                  </span>
                </UploadFileRow>
              )}
              <SizedBoxHeight />
              <button type="submit" disabled={!file || !fileSizeError}>
                Enviar
              </button>
            </UploadBox>
          </UploadColumn>
        </MainRow>
        {renderedData.length > 0 && (
          <>
            <Table
              columns={[
                { label: <Checkbox checked={bToggleAll} onClick={onToggleAll} type="secondary" />, key: 'toggle' },
                { label: 'Shopping', key: 'nameShopping', orderable: true },
                { label: 'Data de processamento', key: 'processingDate', orderable: true },
                { label: 'Data de carregamento', key: 'dateCreated', orderable: true },
                { label: 'Status', key: 'status', orderable: true },
                { label: 'Arquivo', key: 'download' },
              ]}
              data={renderedData}
            />
            <SizedBoxHeight />
            <div>
              <Button onClick={() => setOpen(true)} text="Processar" />
            </div>
            <Selecteds>{shoppings.length} selecionados</Selecteds>
          </>
        )}
      </Container>
      {open && <Modal onClose={() => setOpen(false)} onSubmit={onClickProcess} />}
    </>
  );
};
