import React from 'react';
import { Grid } from '@material-ui/core';
import { Checkbox, Form, Input, Option, SingleSelect } from '../../../../_core/_components';
import { TipoMovimento } from './types';

type Props = {
  tipoContaOptions: Option[];
  state: [TipoMovimento, React.Dispatch<React.SetStateAction<TipoMovimento | undefined>>];
};

export const ModalContent: React.FC<Props> = ({ tipoContaOptions, state }: Props) => {
  const [tipoMovimento, setTipoMovimento] = state;

  return (
    <Grid>
      <Form
        items={[
          <Input
            label="Código"
            state={[tipoMovimento.codigo, value => setTipoMovimento({ ...tipoMovimento, codigo: value.toString() })]}
          />,
          <SingleSelect
            placeholder="Tipo conta"
            state={[
              { label: tipoMovimento.tipoConta, value: tipoMovimento.idTipoConta },
              option =>
                setTipoMovimento({
                  ...tipoMovimento,
                  tipoConta: option!.label,
                  idTipoConta: option!.value as number,
                }),
            ]}
            options={tipoContaOptions}
          />,
          <Checkbox
            label="Ativo"
            checked={tipoMovimento.ativo}
            onClick={() => setTipoMovimento({ ...tipoMovimento, ativo: !tipoMovimento.ativo })}
          />,
        ]}
      />
    </Grid>
  );
};
