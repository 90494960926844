/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import SaldoEmAberto from './abas/SaldoEmAberto';
import { PriorizationItem, ShoppingEntity } from '../priorizacao/types';
import Contatos from './abas/Contatos';
import HistoricoNegociacao from './abas/HistoricoNegociacao';
import Inadimplencia from './abas/Inadimplencia';
import { ContractResponse } from './types';
import { toReal } from '../../../_core/services/formaters';
import { Option } from '../../../_core/_components';

export const Boletos: React.FC = () => {
  const history = useHistory<
    PriorizationItem & { fullData: PriorizationItem[]; selectedDate: Date; shopping: ShoppingEntity }
  >();
  const historyBack = useHistory<{ selectedDate: Date; shopping: Option; refresh: boolean }>();
  const customerPriorizationData = history.location.state;
  const [currentTab, setCurrentTab] = useState(1);
  const [contractRes, setContractRes] = useState<ContractResponse>();
  const [currentClient, setCurrentClient] = useState<
    PriorizationItem & { selectedDate: Date; shopping: ShoppingEntity }
  >();
  const [currentClientIdx, setCurrentClientIdx] = useState<number>();

  useEffect(() => {
    setCurrentClient(customerPriorizationData);
    const currentIdx = customerPriorizationData.fullData.findIndex(
      c => c.clientId === customerPriorizationData.clientId,
    );
    setCurrentClientIdx(currentIdx);
  }, []);

  if (!currentClient) {
    return <div>carregando...</div>;
  }

  return (
    <S.Content>
      <S.TopActions>
        <S.Back
          onClick={() =>
            historyBack.push('/priorizacao', {
              selectedDate: customerPriorizationData.selectedDate,
              shopping: {
                label: customerPriorizationData.shopping.label ?? '',
                value: customerPriorizationData.shopping.id,
              },
              refresh: true,
            })
          }
        >
          <ArrowBackIcon />
          Retornar para Lista de Priorização
        </S.Back>
      </S.TopActions>
      <S.Header>
        <S.HeaderLeft>
          <S.HeaderInfo>
            <S.HeaderInfoLabel>Razão Social</S.HeaderInfoLabel>
            <S.HeaderInfoValue>{currentClient.companyName}</S.HeaderInfoValue>
          </S.HeaderInfo>
          <S.HeaderInfo>
            <S.HeaderInfoLabel>Cliente</S.HeaderInfoLabel>
            <S.HeaderInfoValue>{currentClient.clientId}</S.HeaderInfoValue>
          </S.HeaderInfo>
          <S.HeaderInfo>
            <S.HeaderInfoLabel>CPF/CNPJ</S.HeaderInfoLabel>
            <S.HeaderInfoValue>{currentClient.documentNumber}</S.HeaderInfoValue>
          </S.HeaderInfo>
        </S.HeaderLeft>
        <S.HeaderRight>
          <S.HeaderRightLine>
            <S.HeaderIndicatorWrapper>
              <S.HeaderIndicatorLabel>Vencido mês</S.HeaderIndicatorLabel>
              <S.HeaderIndicatorValue textColor="red">{toReal(currentClient.overdueThisMonth)}</S.HeaderIndicatorValue>
            </S.HeaderIndicatorWrapper>
            <S.HeaderIndicatorWrapper>
              <S.HeaderIndicatorLabel>Vencido Total (120 dias)</S.HeaderIndicatorLabel>
              <S.HeaderIndicatorValue textColor="black">{toReal(currentClient.overdue120Days)}</S.HeaderIndicatorValue>
            </S.HeaderIndicatorWrapper>
            <S.HeaderIndicatorWrapper>
              <S.HeaderIndicatorLabel>Vencido Total</S.HeaderIndicatorLabel>
              <S.HeaderIndicatorValue textColor="black">{toReal(currentClient.totalOverdue)}</S.HeaderIndicatorValue>
            </S.HeaderIndicatorWrapper>
            <S.HeaderIndicatorWrapper>
              <S.HeaderIndicatorLabel>A vencer mês</S.HeaderIndicatorLabel>
              <S.HeaderIndicatorValue textColor="yellow">{toReal(currentClient.amountDue)}</S.HeaderIndicatorValue>
            </S.HeaderIndicatorWrapper>
          </S.HeaderRightLine>
        </S.HeaderRight>
      </S.Header>

      <S.TabsWrapper>
        <S.Tabs>
          <S.Tab onClick={() => setCurrentTab(1)} active={currentTab === 1}>
            Saldo em Aberto
          </S.Tab>
          <S.Tab active={currentTab === 2} onClick={() => setCurrentTab(2)}>
            Contatos
          </S.Tab>
          <S.Tab active={currentTab === 3} onClick={() => setCurrentTab(3)}>
            Histórico de Negociação
          </S.Tab>
          <S.Tab active={currentTab === 4} onClick={() => setCurrentTab(4)}>
            Inadimplência
          </S.Tab>
        </S.Tabs>
        <S.TabsContent>
          {currentTab === 1 && (
            <SaldoEmAberto
              onUpdateContracts={(contractRes: ContractResponse) => setContractRes(contractRes)}
              customerPriorizationData={currentClient}
            />
          )}
        </S.TabsContent>
        <S.TabsContent>
          {currentTab === 2 && <Contatos contracts={contractRes} customerPriorizationData={currentClient} />}
        </S.TabsContent>
        <S.TabsContent>
          {currentTab === 3 && <HistoricoNegociacao customerPriorizationData={currentClient} />}
        </S.TabsContent>
        <S.TabsContent>{currentTab === 4 && <Inadimplencia customerPriorizationData={currentClient} />}</S.TabsContent>
      </S.TabsWrapper>
    </S.Content>
  );
};
