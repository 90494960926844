import React, { useEffect, useMemo, useState } from 'react';
import { Container } from './styles';
import { Button, Input, Select, Form, Option } from '../../../_core/_components';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { requests } from '../../../requests';
import { useCache } from '../../../_core/cache';
import { handleErrors } from '../../../_core/services/toast';
import api from '../../../_core/api';
import { mascaraMMAAAA } from '../../../_core/masks';
import { downloadXls } from '../../../_core/services/download';

export function CalculoLucLucInadimplencia(): any {
  const { response: responseFiltersShoppings, isLoading: isLoadingFiltersShoppings } = useCache({
    key: 'getAllShoppings',
    fn: requests.get('getAllShoppings'),
  });

  const { response: responseFiltersTipoMovimento, isLoading: isLoadingFiltersTipoMovimento } = useCache({
    key: 'getTipoMovimento',
    fn: requests.get('getTipoMovimento'),
  });

  const { response: responseFiltersTipoContrato, isLoading: isLoadingFiltersTipoContrato } = useCache({
    key: 'getTipoContrato',
    fn: requests.get('getTipoContrato'),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [shoppingsOptions, setShoppingsOptions] = useState<any[]>([]);
  const [shopping, setShopping] = useState<Option[]>([]);
  const [startMountYear, setStartMountYear] = useState('');
  const [endMountYear, setEndMountYear] = useState('');
  const [tipoMovimentoOptions, setTipoMovimentoOptions] = useState<any[]>([]);
  const [tipoMovimento, setTipoMovimento] = useState<any[]>([]);
  const [tipoContratoOptions, setTipoContratoOptions] = useState<Option[]>([]);
  const [tipoContrato, setTipoContrato] = useState<Option[]>([]);

  useEffect(() => {
    if (responseFiltersTipoContrato !== undefined) {
      const arr = responseFiltersTipoContrato.data.map((item: any) => ({
        value: item.codigo,
        label: item.codigo,
      }));
      setTipoContratoOptions(arr);
    }
  }, [responseFiltersTipoContrato]);

  useEffect(() => {
    if (responseFiltersShoppings !== undefined) {
      const shoppingList = responseFiltersShoppings.data.map((item: any) => ({
        value: item.id,
        label: item.nome,
      }));
      setShoppingsOptions(shoppingList);
    }
  }, [responseFiltersShoppings]);

  useEffect(() => {
    if (responseFiltersTipoMovimento !== undefined) {
      const tipoMovimentoList = responseFiltersTipoMovimento.data.map((item: any) => ({
        value: item.id,
        label: item.codigo,
        idTipoConta: item.idTipoConta,
      }));
      setTipoMovimentoOptions(tipoMovimentoList);
    }
  }, [responseFiltersTipoMovimento]);

  const params = useMemo(
    () => ({
      shoppingIds: shopping.map(e => e.value).join(','),
      mesAnoInicio: startMountYear.replace('/', '-'),
      mesAnoFim: endMountYear.replace('/', '-'),
      codigosTipoMovimento: tipoMovimento.map(e => e.label).join(','),
      codigosTipoContrato: tipoContrato.map(e => e.label).join(','),
    }),
    [shopping, startMountYear, endMountYear, tipoMovimento],
  );

  const getXls = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/inadimplencia/luc-luc`, { responseType: 'arraybuffer', params });
      downloadXls(response);
    } catch (error) {
      handleErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <FullScreenLoading
        isEnabled={[
          isLoading,
          isLoadingFiltersShoppings,
          isLoadingFiltersTipoMovimento,
          isLoadingFiltersTipoContrato,
        ].some(e => e)}
      />
      <Form
        items={[
          <Select placeholder="Shoppings" options={shoppingsOptions} state={[shopping, setShopping]} />,
          <Input
            placeholder="MM/AAAA"
            label="Mês/ano de início"
            state={[startMountYear, setStartMountYear]}
            pattern={mascaraMMAAAA}
          />,
          <Input
            placeholder="MM/AAAA"
            label="Mês/ano de fim"
            state={[endMountYear, setEndMountYear]}
            pattern={mascaraMMAAAA}
          />,
          <Select placeholder="Tipo Conta" options={tipoMovimentoOptions} state={[tipoMovimento, setTipoMovimento]} />,
          <Select placeholder="Tipo Contrato" options={tipoContratoOptions} state={[tipoContrato, setTipoContrato]} />,
        ]}
        submitButton={<Button text="Gerar relatório" onClick={getXls} />}
      />
    </Container>
  );
}
