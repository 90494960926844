import React, { useState, useEffect, useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import MoreVert from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import moment from 'moment';
import * as S from '../styles';
import Modal from './modal';
import api from '../../../../_core/api';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { Button, PaginatedTable } from '../../../../_core/_components';
import { handleErrors, success } from '../../../../_core/services/toast';
import ConfirmationModal from '../../../../_core/_components/confirmation-modal';

export type Justificativa = {
  idAjzJustificativa: number;
  flagAtivo: boolean;
  txtEstagio: string;
  descricao: string;
  flagObrigaChamadoRn: boolean;
  flagRequerAnexo: boolean;
  limiteJustificativa: number;
  aprovador: boolean;
  dataInicio: Date;
  dataFinal: Date;
};

const Justificativas: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [registerList, setRegisterList] = useState<Justificativa[]>([]);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [itemInAction, setItemInAction] = useState<Justificativa | null>();
  const [deletionSelected, setDeletionSelected] = useState<Justificativa | null>();

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get<Justificativa[]>(`/setup/rules/justificativa`);

      setRegisterList(data);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const excludeItem = async () => {
    try {
      await api.delete(`/setup/rules/justificativa/${deletionSelected?.idAjzJustificativa}`);
      success('O registro foi removido');
      setDeletionSelected(null);
      getData();
    } catch (e) {
      handleErrors(e);
      setDeletionSelected(null);
    }
  };

  const renderedData = useMemo(() => {
    if (registerList.length > 0) {
      return registerList.map(item => ({
        descricao: item.descricao,
        chamadoObrigatorio: item.flagObrigaChamadoRn ? 'Sim' : 'Não',
        anexoObrigatorio: item.flagRequerAnexo ? 'Sim' : 'Não',
        aprovador: item.aprovador ? 'Sim' : 'Não',
        limiteJustificativa: item.limiteJustificativa,
        dataInicio: item.dataInicio && moment(item.dataInicio).format('DD/MM/YYYY'),
        dataFinal: item.dataFinal && moment(item.dataFinal).format('DD/MM/YYYY'),
        actions: (
          <S.Actions>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              color="primary"
              component="span"
              id={item.idAjzJustificativa.toString()}
              onClick={() => setItemInAction(item)}
            >
              <MoreVert />
              <S.IconLabel>Editar</S.IconLabel>
            </IconButton>

            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              color="primary"
              component="span"
              id={item.idAjzJustificativa.toString()}
              onClick={() => setDeletionSelected(item)}
            >
              <DeleteIcon />
              <S.IconLabel>Remover</S.IconLabel>
            </IconButton>
          </S.Actions>
        ),
      }));
    }
    return [];
  }, [registerList]);

  return (
    <S.DataContainer>
      <FullScreenLoading isEnabled={loading} />
      <ConfirmationModal
        open={Boolean(deletionSelected)}
        text={`Deseja excluir o item "${deletionSelected?.descricao}" ?`}
        handleCancel={() => setDeletionSelected(null)}
        handleOk={excludeItem}
      />
      {itemInAction !== undefined && (
        <Modal selectedRegister={itemInAction} onClose={() => setItemInAction(undefined)} refresh={getData} />
      )}
      <S.TopBar>
        <S.SearchBar>
          <S.SearchField />
        </S.SearchBar>
        <Button text="Criar novo" onClick={() => setItemInAction(null)} />
      </S.TopBar>
      {renderedData.length > 0 && (
        <PaginatedTable
          subItemSpan={2}
          columns={[
            { label: 'Justificativa', key: 'descricao' },
            { label: 'Limite Justificativa', key: 'limiteJustificativa' },
            { label: 'Chamado Obrigatório', key: 'chamadoObrigatorio' },
            { label: 'Anexo Obrigatório', key: 'anexoObrigatorio' },
            { label: 'Aprovador', key: 'aprovador' },
            { label: 'Data Início', key: 'dataInicio' },
            { label: 'Data Final', key: 'dataFinal' },
            { label: '', key: 'actions' },
          ]}
          data={renderedData}
          pagination={pageInfo}
          onChangePage={setPage}
        />
      )}
    </S.DataContainer>
  );
};

export default Justificativas;
