import React, { useEffect, useState } from 'react';
import { Dialog, Grid } from '@material-ui/core';
import api from '../../../../_core/api';
import { failure, success } from '../../../../_core/services/toast';
import { Button, Form, Input, Select, Option, SingleSelect } from '../../../../_core/_components';
import { NumeroDocumentoItem, Shopping } from './types';

import * as S from '../styles';
import { mascaraNumeros } from '../../../../_core/masks';

type Modulo = {
  id: number;
  codigo: string;
  nome: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  onFinishSave: () => void;
  selectedRegister?: NumeroDocumentoItem | null;
  empresas: any[];
};

const Modal: React.FC<Props> = ({ open, onClose, selectedRegister, onFinishSave, empresas }: Props) => {
  const [loading, setLoading] = useState(false);
  const [shoppingOptions, setShoppingOptions] = useState<Option[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [observacao, setObservacao] = useState('');
  const [name, setName] = useState('');
  const [selectedModulos, setSelectedModulos] = useState<Option[]>([]);
  const [modulos, setModulos] = useState<Option[]>([]);
  const [anoCompetencia, setAnoCompetencia] = useState('');

  useEffect(() => {
    getModulos();
  }, []);

  const getModulos = async () => {
    const { data } = await api.get<Modulo[]>(`/config/excecoes/modulo`);

    const moduloOptions = data.map(m => ({
      label: m.nome,
      value: m.id,
    }));
    setModulos(moduloOptions);
  };

  useEffect(() => {
    if (!selectedRegister) {
      resetModalState();
    } else {
      setSelectedShoppings(selectedRegister.shoppings.map(s => ({ label: s.nome, value: s.id })));
      setSelectedModulos(selectedRegister.modulos.map(s => ({ label: s.nome, value: s.id })));
      setObservacao(selectedRegister.observacao);
      setName(selectedRegister.numero);
      setAnoCompetencia(selectedRegister.anoCompetencia);
    }
    getShoppingsOptions();
  }, [selectedRegister]);

  const getShoppingsOptions = async () => {
    const { data } = await api.get<Shopping[]>('/empresas/shoppings/simple-umpaged');
    const options: Option[] = data.map(s => ({
      label: s.nome,
      value: s.id,
    }));
    setShoppingOptions(options);
  };

  const resetModalState = () => {
    setSelectedShoppings([]);
    setSelectedModulos([]);
    setObservacao('');
    setName('');
    setAnoCompetencia('');
  };

  const save = async () => {
    setLoading(true);
    const payload = {
      numero: name || selectedRegister?.numero,
      ativo: true,
      anoCompetencia,
      observacao: observacao || selectedRegister?.observacao,
      shoppings:
        selectedShoppings.map(s => ({
          id: s.value,
        })) || selectedRegister?.shoppings,
      modulos:
        selectedModulos.map(m => ({
          id: m.value,
        })) || selectedRegister?.modulos,
    };

    if (selectedRegister) {
      try {
        await api.post(`/config/excecoes/numero-documento/${selectedRegister.id}`, payload);
        success('Atualizado com sucesso.');
        setLoading(false);
        resetModalState();
        onFinishSave();
      } catch (e) {
        failure('Erro ao tentar atualizar registro.');
        setLoading(false);
      }
    } else {
      try {
        await api.post('/config/excecoes/numero-documento', payload);
        success('Criado com sucesso.');
        setLoading(false);
        resetModalState();
        onFinishSave();
      } catch (e) {
        failure('Erro ao tentar criar registro.');
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <S.ModalTop>
        <S.ModalTittle> {!selectedRegister ? 'Adicionar' : 'Editar'} </S.ModalTittle>
      </S.ModalTop>

      <Grid>
        <Form
          items={[
            <Input label="Número Documento" state={[name, setName]} />,
            <Input label="Observações" state={[observacao, setObservacao]} />,
            <Input pattern={mascaraNumeros} label="Ano Competência" state={[anoCompetencia, setAnoCompetencia]} />,
            <Select
              state={[selectedShoppings, setSelectedShoppings]}
              options={shoppingOptions}
              placeholder="Shoppings"
            />,
            <Select state={[selectedModulos, setSelectedModulos]} options={modulos} placeholder="Módulos" />,
          ]}
        />
        <S.ModalActions>
          <Button onClick={save} text="Salvar" disabled={loading} />
        </S.ModalActions>
      </Grid>
    </Dialog>
  );
};

export default Modal;
