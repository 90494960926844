import { ValuesState } from './ModalExpectativa';
import { ExpectationReceiptDTO } from './types';

export function parserDTO(data: ExpectationReceiptDTO): ValuesState {
  return {
    expectationReceiptRentValue: Number.isNaN(data.expectationReceiptRentValue)
      ? ''
      : data.expectationReceiptRentValue.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
    expectationReceiptCondoValue: Number.isNaN(data.expectationReceiptCondoValue)
      ? ''
      : data.expectationReceiptCondoValue.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
    expectationReceiptFundsValue: Number.isNaN(data.expectationReceiptFundsValue)
      ? ''
      : data.expectationReceiptFundsValue.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
    expectationReceiptComercialValue: Number.isNaN(data.expectationReceiptComercialValue)
      ? ''
      : data.expectationReceiptComercialValue.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
    adjustmentRentValue: Number.isNaN(data.adjustmentRentValue)
      ? ''
      : data.adjustmentRentValue.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
    adjustmentCondoValue: Number.isNaN(data.adjustmentCondoValue)
      ? ''
      : data.adjustmentCondoValue.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
    adjustmentFundsValue: Number.isNaN(data.adjustmentFundsValue)
      ? ''
      : data.adjustmentFundsValue.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
    adjustmentComercialValue: Number.isNaN(data.adjustmentComercialValue)
      ? ''
      : data.adjustmentComercialValue.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
    expectationReceiptLiqRentValue: Number.isNaN(data.expectationReceiptLiqRentValue)
      ? ''
      : data.expectationReceiptLiqRentValue.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
    expectationReceiptLiqCondoValue: Number.isNaN(data.expectationReceiptLiqCondoValue)
      ? ''
      : data.expectationReceiptLiqCondoValue.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
    expectationReceiptLiqFundsValue: Number.isNaN(data.expectationReceiptLiqFundsValue)
      ? ''
      : data.expectationReceiptLiqFundsValue.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
    expectationReceiptLiqComercialValue: Number.isNaN(data.expectationReceiptLiqComercialValue)
      ? ''
      : data.expectationReceiptLiqComercialValue.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
  };
}
