import React, { useEffect, useState } from 'react';
import { Pagination, PaginationItem } from '@material-ui/lab';
import {
  Container,
  CustomTable,
  HeaderItem,
  PaginationWrapper,
  Row,
  RowItem,
  Orderable,
  SubRowItem,
  TableBody,
  TableHead,
} from './styles';
import ArrowDown from '../../../assets/downArrow';
import { PaginatedTableProps, SortOptions, TableHeader } from './types';

type Sort = 'asc' | 'desc' | undefined;

export function PaginatedTable({
  columns,
  data,
  pagination,
  onRowClick,
  onChangePage,
  subItemSpan = 1,
}: PaginatedTableProps): JSX.Element {
  const [sortOptions, setSortOptions] = useState<SortOptions[]>([]);
  const sortQueryString = sortOptions.map(option => `sort=${option.key},${option.type}`).join('&');

  useEffect(() => {
    return sortQueryString.length > 0
      ? onChangePage(pagination!.currentPage, `${sortQueryString}`)
      : onChangePage(pagination!.currentPage, '');
  }, [sortOptions]);

  const toggleSortType = (currentType: Sort): Sort => {
    if (currentType === 'asc') return 'desc';
    if (currentType === 'desc') return undefined;
    return 'asc';
  };

  const onClickOrderable = (item: TableHeader) => {
    setSortOptions(prevSortOptions => {
      const newSortOptions = [...prevSortOptions];
      const existingIndex = newSortOptions.findIndex(option => option.key === item.key);

      if (existingIndex !== -1) {
        const currentType = newSortOptions[existingIndex].type;
        const newType = toggleSortType(currentType);

        if (newType) {
          newSortOptions[existingIndex].type = newType;
        } else {
          newSortOptions.splice(existingIndex, 1); // Remove if undefined
        }
      } else {
        newSortOptions.push({ key: item.key, type: 'asc' });
      }

      return newSortOptions;
    });
  };

  const renderHeaderItem = (item: TableHeader) => {
    if (item.orderable) {
      const arrowDirection = sortOptions.find(option => option.key === item.key)?.type;
      return (
        <Orderable onClick={() => onClickOrderable(item)} arrowDirection={arrowDirection}>
          {item.label}
          <ArrowDown click={null} color="white" />
        </Orderable>
      );
    }
    return item.label;
  };

  const renderRowItem = (item: any) => {
    return columns.map((header, i) => <RowItem key={i}>{item[header.key]}</RowItem>);
  };

  const renderSubRowItem = (subItems: any) => {
    let currentCollumn = 0;

    return columns.map((header, i) => {
      if (currentCollumn < columns.length) {
        const content = subItems[i] || '';
        const colSpan = subItemSpan;
        currentCollumn += colSpan;

        return (
          <SubRowItem colSpan={colSpan} key={i}>
            {content}
          </SubRowItem>
        );
      }

      return null; // Retorna null para evitar a renderização de colunas vazias
    });
  };

  return (
    <Container>
      <CustomTable>
        <TableHead>
          <tr>
            {columns.map((item, i) => (
              <HeaderItem key={i}>{renderHeaderItem(item)}</HeaderItem>
            ))}
          </tr>
        </TableHead>
        <TableBody>
          {data.map((item, i) => (
            <React.Fragment key={i}>
              <Row
                style={{
                  backgroundColor: i % 2 === 0 ? ' #e1e6e7' : '#ffffff',
                  cursor: onRowClick ? 'pointer' : 'default',
                }}
                onClick={() => (onRowClick ? onRowClick(item) : null)}
              >
                {renderRowItem(item)}
              </Row>
              {item.subItems?.length > 0 && (
                <Row
                  style={{ backgroundColor: i % 2 === 0 ? ' #e1e6e7' : '#ffffff', cursor: 'default' }}
                  onClick={() => (onRowClick ? onRowClick(item) : null)}
                >
                  {renderSubRowItem(item.subItems)}
                </Row>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </CustomTable>
      <PaginationWrapper>
        <Pagination
          showFirstButton
          showLastButton
          count={pagination!.totalPages}
          page={pagination!.currentPage}
          onChange={(e, i) => onChangePage!(i, sortQueryString)}
          renderItem={item => (
            <PaginationItem
              {...item}
              onClick={() => {
                const prevPage = pagination!.currentPage;
                if (item.type === 'last') {
                  const newPage = Math.min(prevPage + 10, pagination.totalPages);
                  onChangePage!(newPage, sortQueryString); // Avança 10 páginas, respeitando o máximo
                } else if (item.type === 'first') {
                  const newPage = Math.max(prevPage - 10, 1);
                  onChangePage!(newPage, sortQueryString); // Volta 10 páginas, respeitando o mínimo
                } else {
                  onChangePage!(item.page, sortQueryString);
                }
              }}
            />
          )}
        />
      </PaginationWrapper>
    </Container>
  );
}
