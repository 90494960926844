/* eslint-disable prefer-const */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ReactSelect, { ActionMeta, ValueContainerProps, components } from 'react-select';
import _ from 'lodash';
import { Option } from './types';

interface Props {
  state: [Option[], React.Dispatch<React.SetStateAction<Option[]>>];
  placeholder?: string;
  options: Option[];
  disabled?: boolean;
  callbackSelected?: (option: Option[]) => void;
  handleChange?: (options: Option[]) => void;
}

const ValueContainer = ({ children, ...props }: ValueContainerProps<any>) => {
  let [values, input] = children as any;

  if (Array.isArray(values)) {
    const plural = values.length === 1 ? '' : 's';
    values = `${values.length} item${plural} selecionado${plural}`;
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

export function Select({
  state: [value, setValue],
  placeholder,
  options,
  disabled = false,
  callbackSelected,
  handleChange,
}: Props): JSX.Element {
  const onChange = (option: readonly Option[], actionMeta: ActionMeta<Option>) => {
    setValue(option.map(e => e));
    if (callbackSelected && actionMeta.action !== 'clear') {
      const diffArr = _.difference(value, option);
      callbackSelected(diffArr);
    }

    if (handleChange) {
      handleChange(option as Option[]);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      {placeholder && (
        <label style={{ margin: 0, color: '#6D6D6D', fontSize: 14, fontWeight: 600 }}>{placeholder}</label>
      )}
      <ReactSelect
        value={value}
        onChange={onChange}
        hideSelectedOptions={false}
        placeholder=""
        isMulti
        isSearchable
        isClearable
        options={options}
        menuPortalTarget={document.body}
        closeMenuOnSelect={false}
        classNamePrefix="react-select"
        styles={{
          control: (base, state) => ({
            ...base,
            height: 40,
            minHeight: 40,
            minWidth: '10rem',
            boxShadow: state.isFocused ? '0 0 0 1px #008C90' : 'none',
            borderColor: state.isFocused ? '#008C90' : 'hsl(0, 0%, 80%)',
            '&:hover': {
              borderColor: state.isFocused ? '#008C90' : 'hsl(0, 0%, 80%)',
            },
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
          valueContainer: base => ({
            ...base,
            flexWrap: 'nowrap',
            padding: '0 12px',
          }),
          multiValueRemove: base => ({ ...base, display: 'none' }),
          multiValue: base => ({ ...base, paddingRight: 3 }),
          placeholder: base => ({ ...base, marginLeft: '8px', marginRight: '8px' }),
          singleValue: base => ({ ...base, marginLeft: '8px', marginRight: '8px' }),
        }}
        noOptionsMessage={() => ''}
        components={{ ValueContainer }}
        isDisabled={disabled}
      />
    </div>
  );
}
