import React from 'react';
import { Dialog, DialogActions, DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ModalHeader, Title } from './styles';

type Props = {
  title: string;
  onClose: () => void;
  content?: React.ReactNode;
  actionItems?: React.ReactNode[];
  size?: 'md' | 'xs' | 'sm' | 'lg' | 'xl';
};

export const Modal: React.FC<Props> = ({ title, onClose, content, actionItems, size = 'md' }: Props) => {
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth={size}>
      <ModalHeader>
        <Title>{title}</Title>
        <IconButton onClick={onClose}>
          <CloseIcon style={{ color: 'white' }} />
        </IconButton>
      </ModalHeader>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actionItems?.map(e => e)}</DialogActions>
    </Dialog>
  );
};
