import styled from 'styled-components';

export const CloseAction = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;

  svg {
    fill: #00959c;
  }
`;

export const Form = styled.div`
  margin-bottom: 1rem;
`;

export const FormLine = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin: 1.5rem 0;
`;
export const FormGroup = styled.div`
  flex: 1;

  .MuiSwitch-root {
    left: -12px;
  }
`;

export const SwitchLabel = styled.p`
  color: rgb(109, 109, 109);
  font-size: 14px;
  font-weight: 600;
`;
