import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import { Modal as CustomModal, Form, Input, SingleSelect, Table, UploadFile } from '../../../_core/_components';
import * as S from './styles';
import { Historico, HistoricoDTO, Values } from './types';
import { handleErrors } from '../../../_core/services/toast';
import api from '../../../_core/api';

type Props = {
  onClose: () => void;
  valuesState: [Values, React.Dispatch<React.SetStateAction<Values | undefined>>];
  justificativas: any[];
};

export const Modal: React.FC<Props> = ({ onClose, valuesState, justificativas }: Props) => {
  const [values] = valuesState;
  const [{ id, justificativa, observacao, nrChamado, anexo, file }, setValues] = valuesState;
  const [historico, setHistorico] = useState<Historico[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getHistorico = async () => {
      setLoading(true);
      try {
        const { data } = await api.get<HistoricoDTO>(`/workflow/serasa/serasa-workflow/buscar/historico/${id}`);
        if (data.content) {
          setHistorico(data.content);
        }
      } catch (error) {
        handleErrors(error);
      }
      setLoading(false);
    };
    getHistorico();
  }, []);

  const camposObrigatorios = useMemo(() => {
    if (justificativa && justificativa !== 999) {
      const { campoObrigatorio } = justificativas.find((e: any) => e.value === justificativa);

      if (campoObrigatorio === 'NAO_OBRIGATORIO') {
        return '';
      }
      if (campoObrigatorio === 'CHAMADO' && !nrChamado) {
        return '*Número do chamado é obrigatório.';
      }
      if (campoObrigatorio === 'CHAMADO_OU_ANEXO' && !nrChamado && file === undefined && anexo === '') {
        return '*Número do chamado ou Anexo são obrigatórios.';
      }
      if (campoObrigatorio === 'ANEXO' && file === undefined && anexo === '') {
        return '*Anexo é obrigatório.';
      }
      return '';
    }
    return '';
  }, [justificativa, nrChamado, file, anexo]);

  const data = useMemo(() => {
    if (historico.length > 0) {
      return historico.map(item => ({
        ...item,
        motivoJustificativa: item.serasaJustificatica.txtJustificativa,
        usuarioAlteracao: item.usuarioAlteracao.nome,
        datUltimaAtualizacaoPtBr: moment(item.datUltimaAtualizacao).format('DD/MM/YYYY HH:mm'),
      }));
    }
    return [];
  }, [historico]);

  const content = (
    <>
      <Form
        items={[
          <SingleSelect
            state={[
              justificativas.find(e => e.value === justificativa),
              option => setValues({ ...values, justificativa: option?.value as number }),
            ]}
            options={justificativas}
            placeholder="Justificativas"
          />,
          <Input
            label="Observação"
            state={[observacao, value => setValues({ ...values, observacao: value.toString() })]}
          />,
          <Input
            label="Número do chamado"
            state={[nrChamado, value => setValues({ ...values, nrChamado: value.toString() })]}
          />,
          <UploadFile label="Anexo" state={[file, value => setValues({ ...values, file: value as File })]} />,
        ]}
        submitButton={camposObrigatorios}
      />
      {loading ? (
        <div style={{ padding: '0.1rem', display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <Table
          columns={[
            { key: 'motivoJustificativa', orderable: true, label: 'Motivo justificativa' },
            { key: 'numChamado', orderable: true, label: 'Número chamado' },
            { key: 'txtObservacao', orderable: true, label: 'Observação' },
            { key: 'nomeAnexo', orderable: true, label: 'Anexo' },
            { key: 'datUltimaAtualizacaoPtBr', orderable: true, label: 'Data informada' },
            { key: 'usuarioAlteracao', orderable: true, label: 'Usuário' },
          ]}
          data={data}
        />
      )}
    </>
  );

  return <CustomModal title="Histórico de justificativas" onClose={onClose} content={content} />;
};
