import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CustomSwitch from '@material-ui/core/Switch';
import * as S from './styles';

const PurpleSwitch = withStyles({
  switchBase: {
    color: '#d2d2d2',

    '&$checked': {
      color: '#00959c',
    },
    '&$checked + $track': {
      backgroundColor: '#00959c',
    },
  },
  checked: {},
  track: {},
})(CustomSwitch);

type Props = {
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  hideLabel?: boolean;
};

export function Switch({ state: [status, setStatus], hideLabel = false }: Props): JSX.Element {
  return (
    <S.Wrapper onClick={e => e.stopPropagation()}>
      <PurpleSwitch checked={status} onChange={e => setStatus(e.target.checked)} />
      {!hideLabel && <S.SwitchText>{status ? 'Sim' : 'Não'}</S.SwitchText>}
    </S.Wrapper>
  );
}

export default Switch;
