import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    font-size: 20px;
    font-weight: bold;
    color: #7e9395;
    padding: 20px 0;
  }
  label {
    font-size: 14px;
    color: #7e9395;
    margin: 0 10px;
  }
`;
