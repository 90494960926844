import React, { useState } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import Button from '../../../components/button';
import { ContatoDB } from '../../types';
import { phoneNumberMask } from '../../../../../_core/masks';
import { SingleSelect, Form, Modal, Input } from '../../../../../_core/_components';

type Props = {
  onClose: () => void;
  onSaveContact: (c: Contact) => void;
  editingContact?: ContatoDB;
};

export type Contact = {
  nome: string;
  tipo: string;
  mail: string;
  phone: string;
  id?: number;
};

const OPTIONS = [
  { label: 'Locatário', value: 'Locatário' },
  { label: 'Financeiro', value: 'Financeiro' },
  { label: 'Fiador', value: 'Fiador' },
];

const ModalContatos: React.FC<Props> = ({ onClose, onSaveContact, editingContact }) => {
  const [nome, setNome] = useState(editingContact?.contactName ?? '');
  const [tipo, setTipo] = useState(editingContact?.contactType ?? '');
  const [mail, setMail] = useState(editingContact?.email ?? '');
  const [phone, setPhone] = useState(editingContact?.phoneNumber ?? '');

  const saveContact = () => {
    onSaveContact({
      nome,
      tipo,
      mail,
      phone,
      ...(editingContact && { id: editingContact.id }),
    });
  };

  const isSaveBtnDisabled = () => {
    if (!nome || !tipo || !mail || !phone) {
      return true;
    }

    return false;
  };

  const content = (
    <Form
      items={[
        <Input label="Nome do Contato" state={[nome, v => setNome(v)]} />,
        <SingleSelect
          placeholder="Tipo"
          state={[OPTIONS.find(e => e.label === tipo), option => setTipo(option?.label as string)]}
          options={OPTIONS}
        />,
        <Input label="E-mail" state={[mail, v => setMail(v)]} />,
        <Input label="Telefone/Celular" pattern={phoneNumberMask} state={[phone, v => setPhone(v)]} />,
      ]}
    />
  );

  return (
    <Modal
      title="Adicionar/Editar Contato"
      onClose={onClose}
      content={content}
      actionItems={[
        <Button disabled={isSaveBtnDisabled()} startIcon={<SaveIcon />} onClick={saveContact} text="Salvar" />,
      ]}
    />
  );
};

export default ModalContatos;
