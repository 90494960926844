import React, { useState, useEffect, useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';

import * as S from '../styles';
import Modal from './modal';
import api from '../../../../_core/api';
import { toReal } from '../../../../_core/services/formaters';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { Button, PaginatedTable } from '../../../../_core/_components';
import { handleErrors } from '../../../../_core/services/toast';
import { DebtType } from '../tipo-divida';
import { Action } from '../tipos-acao';
import { MinimumRequirement, MinimumRequirementsResponse } from './types';

const RequisitosMinimos: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [registerList, setRegisterList] = useState<MinimumRequirement[]>([]);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [tipoDivida, setTipoDivida] = useState<DebtType[]>([]);
  const [tipoAcao, setTipoAcao] = useState<Action[]>([]);
  const [itemInAction, setItemInAction] = useState<MinimumRequirement | null>();

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get<MinimumRequirementsResponse>(
        `/parametros/ajuizamento/requisitos-minimos?page=${page}`,
      );
      if ([tipoDivida, tipoAcao].every(e => e.length === 0)) {
        const { data: dataTipoDivida } = await api.get<DebtType[]>('/setup/rules/tipo-divida');
        const { data: dataTipoAcao } = await api.get<Action[]>('/setup/rules/tipo-acao');
        setTipoDivida(dataTipoDivida);
        setTipoAcao(dataTipoAcao);
      }
      setRegisterList(data.data);
      setPageInfo(data.pageInfo);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const renderSubItems = (item: MinimumRequirement) => {
    return [
      <>
        <S.SubItemTitle>Contratos inativos:</S.SubItemTitle> {item.apenasContratosAtivos ? 'Sim' : 'Não'}
      </>,
      <>
        <S.SubItemTitle>Desconsiderar se houver acordo nos autos: </S.SubItemTitle>
        {item.requerAcordoAutos ? 'Sim' : 'Não'}
      </>,
      <>
        <S.SubItemTitle>Boleto admin: </S.SubItemTitle> {item.deveIncluirBoletosAdm ? 'Sim' : 'Não'}
      </>,
    ];
  };

  const renderedData = useMemo(() => {
    if (registerList.length > 0) {
      return registerList
        .filter(i => i.ativo)
        .map(item => ({
          id: item.id,
          tipoDivida: item.tipoDivida,
          tipoAcao: item.tipoAcao,
          agingInicio: item.inicioAgingEmAnos,
          agingFim: item.fimAgingEmDias,
          minTotal: toReal(item.divMinTotal),
          minMes: toReal(item.divMinMes),
          boletosAbertos: item.boletosAbertos,
          subItems: renderSubItems(item),
          ativo: item.ativo,
          actions: (
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              color="primary"
              component="span"
              id={item.id.toString()}
              onClick={() => setItemInAction(item)}
            >
              <MoreVert />
            </IconButton>
          ),
        }));
    }
    return [];
  }, [registerList, tipoDivida, tipoAcao]);

  return (
    <S.DataContainer>
      <FullScreenLoading isEnabled={loading} />
      {itemInAction !== undefined && (
        <Modal
          tipoAcaoOptions={tipoAcao.map(e => ({
            value: e.idAjzTipoAcao,
            label: e.nomTipoAcao,
          }))}
          tipoDividaOptions={tipoDivida.map(e => ({
            value: e.idAjzTipoDivida,
            label: e.codTipoDivida,
          }))}
          selectedRegister={itemInAction}
          onClose={() => setItemInAction(undefined)}
          refresh={getData}
        />
      )}
      <S.TopBar>
        <S.SearchBar>
          <S.SearchField />
        </S.SearchBar>
        <Button text="Criar novo" onClick={() => setItemInAction(null)} />
      </S.TopBar>
      {renderedData.length > 0 && (
        <PaginatedTable
          subItemSpan={2}
          columns={[
            { label: 'Tipo Dívida', key: 'tipoDivida' },
            { label: 'Tipo de Ação', key: 'tipoAcao' },
            { label: 'Aging início (anos)', key: 'agingInicio' },
            { label: 'Aging fim (dias)', key: 'agingFim' },
            { label: 'Div Min Total', key: 'minTotal' },
            { label: 'Div Min Mês', key: 'minMes' },
            { label: 'Boletos Abertos', key: 'boletosAbertos' },
            { label: '', key: 'actions' },
          ]}
          data={renderedData}
          pagination={pageInfo}
          onChangePage={setPage}
        />
      )}
    </S.DataContainer>
  );
};

export default RequisitosMinimos;
