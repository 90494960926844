import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { Label, TableContainer } from './styles';
import { handleErrors } from '../../../_core/services/toast';
import api from '../../../_core/api';
import { Content, LogsDTO } from './types';

type ModalProps = {
  details: any;
};

export const ModalContent: React.FC<ModalProps> = ({ details }) => {
  const { errors, idSerasaBoleto, idPessoa, tipoPessoa } = details;
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState<Content>();

  useEffect(() => {
    const getLogs = async () => {
      try {
        const { data } = await api.post<LogsDTO>(`/workflow/serasa/serasa-workflow/buscar/logs`, {
          idSerasaBoleto,
          idPessoa,
          tipoPessoa,
        });
        setContent(data.content);
      } catch (error) {
        handleErrors(error);
      }
      setLoading(false);
    };
    getLogs();
  }, []);

  return (
    <>
      {loading ? (
        <div style={{ padding: '0.1rem', display: 'flex', justifyContent: 'center', margin: '1rem 0' }}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container style={{ flexWrap: 'nowrap', gap: '1rem', paddingTop: '1rem', paddingBottom: '1rem' }}>
          <Grid xs={6}>
            <Label>Enviado ao serasa</Label>
            <TableContainer>
              <thead>
                <tr>
                  <th>Campos</th>
                  <th>Dados</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Razão social</td>
                  <td>{content?.log?.nomRazaoSocial}</td>
                </tr>
                <tr>
                  <td>Nome fantasia</td>
                  <td>{content?.log?.nomFantasia}</td>
                </tr>
                <tr>
                  <td>LUC</td>
                  <td>{content?.log?.luc}</td>
                </tr>
                <tr>
                  <td>CPF/CNPJ</td>
                  <td>{content?.log?.documentoDevedor}</td>
                </tr>
                <tr>
                  <td>NR Boleto</td>
                  <td>{content?.log?.numBoleto}</td>
                </tr>
                <tr>
                  <td>Endereço</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Contato</td>
                  <td></td>
                </tr>
              </tbody>
            </TableContainer>
          </Grid>
          <Grid xs={6}>
            <Label>Dados Siscob</Label>
            <TableContainer>
              <thead>
                <tr>
                  <th>Campos</th>
                  <th>Dados</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Razão social</td>
                  <td>{content?.registro.nomRazaoSocial}</td>
                </tr>
                <tr>
                  <td>Nome fantasia</td>
                  <td>{content?.registro.nomFantasia}</td>
                </tr>
                <tr>
                  <td>LUC</td>
                  <td>{content?.registro.luc}</td>
                </tr>
                <tr>
                  <td>CPF/CNPJ</td>
                  <td>{content?.registro.documentoDevedor}</td>
                </tr>
                <tr>
                  <td>NR Boleto</td>
                  <td>{content?.registro.numBoleto}</td>
                </tr>
                <tr>
                  <td>Endereço</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Contato</td>
                  <td></td>
                </tr>
              </tbody>
            </TableContainer>
          </Grid>
        </Grid>
      )}
      <Label>Lista de erros: {errors}</Label>
    </>
  );
};
