import { CSSProperties } from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  .MuiAccordionSummary-content {
    order: 2;
  }

  .MuiAccordionSummary-expandIcon {
    order: 1;
  }
`;

export const AccordionGroup = styled.div`
  padding: 0 1em;
`;
export const AccordionLabel = styled.span`
  font-size: 1em;
  color: #8890b5;
`;
export const AccordionValue = styled.div`
  font-size: 1.6em;
  color: #555555;
  font-weight: 600;
  width: 12em;
`;

export const Separator = styled.div`
  height: 0.1em;
  background-color: #e1e1e1;
  margin: 6em 0 2em 0;
`;
export const ActionsWrapper = styled.div`
  display: flex;
`;

export const Actions = styled.div`
  flex: 1;
  margin: 35px 0;

  button {
    border: 1px solid #fbfbfb;
    font-weight: 600;
    color: #008c90;
  }
`;
export const ActionLabel = styled.div``;
export const ActionButtons = styled.div`
  display: flex;
  gap: 1em;
  margin: 1em 0;
`;

export const DialogWrapper = styled.div`
  font-size: 10px;
  position: relative;
`;
export const DialogHeader = styled.div`
  background-color: #00959c;
  font-weight: bold;
  padding: 2em;
  h1 {
    font-size: 2em;
    color: #fff;
  }
`;
export const DialogContent = styled.div`
  padding: 2em;
  display: flex;
  gap: 1em;
`;

export const DialogActions = styled.div`
  margin: 2em;
  /* border-top: 1px solid #e1e1e1; */
  padding: 2em 0;
  display: flex;
  justify-content: space-between;

  button {
    margin: 0 1em;
  }
`;

export const DialogSelecao = styled.div`
  height: 30em;
  overflow-y: auto;
`;
export const DialogDados = styled.div`
  height: 30em;
  overflow-y: auto;
`;

export const Section = styled.div`
  border: 1px solid #00959c;
  position: relative;
  border-radius: 0.4em;
  height: 100%;
  padding: 1.8em 2em;
  background-color: #fbfbfb;
  flex: 0 0 calc(60% - 1em);

  :nth-of-type(1) {
    flex: 0 0 calc(40% - 1em);
  }
`;
export const SectionTitle = styled.h3`
  background-color: #00959c;
  font-size: 1em;
  color: #fff;
  position: absolute;
  padding: 0.27em 1.2em;
  top: -1em;
  left: 1em;
  border-radius: 0.4em;
`;

export const TreeLabelItem = styled.div`
  span {
    width: 8em;
    display: inline-block;
  }
`;

export const TreeLabelBolHeader = styled.div`
  color: #00959c;
  font-weight: bold;
  span {
    width: 8em;
    display: inline-block;
  }
`;

export const InputLine = styled.div`
  display: flex;
  gap: 1em;
  margin: 1em 0;
`;
export const InputGroup = styled.div`
  flex: 0 0 calc(33% - 1em);
`;

export const InputGroupFull = styled.div`
  flex: 1;
`;

export const Label = styled.div`
  color: #8890b5;
  font-size: 1.2em;
`;

export const MainBoletoInfo = styled.div`
  display: flex;
  justify-content: center;
  gap: 18em;
  padding: 2em;
  border-bottom: 1px solid #e1e1e1;
`;
export const InfoGroup = styled.div``;
export const InfoValue = styled.div`
  font-size: 2.2em;
  color: #444;
  font-weight: bold;
`;

export const ExpectativaSections = styled.div`
  display: flex;
`;
export const VerticalSection = styled.div`
  flex: 0 0 25%;
  padding: 2em;
  border: 1px solid #e1e1e1;
  border-top: none;
  border-right: none;
`;
export const VerticalSectionTittle = styled.div`
  font-size: 1.4em;
  color: #444;
  font-weight: bold;
`;
export const VerticalInputGroup = styled.div`
  margin: 2em 0;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20em;
  flex: 1;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20em;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.6;
  z-index: 999;
`;

export const ActionRelatorios = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CloseAction = styled.span`
  position: absolute;
  top: 2em;
  right: 2em;
  cursor: pointer;

  svg {
    fill: white;
  }
`;

export const DetailsHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
`;
export const DetailsText = styled.div`
  font-size: 14px;
`;
export const DetailsActions = styled.div`
  display: flex;
  gap: 16px;
`;

export const DetailsConteiner = styled.div`
  width: 100%;
`;

export const buttonStyles: CSSProperties = {
  fontSize: '12px',
  height: '32px',
};
