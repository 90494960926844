import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 10px;
`;

export const Navigation = styled.div`
  .MuiAppBar-colorPrimary {
    background-color: unset;
    color: #a1a1a1;
    font-weight: 500;
    font-size: 1.4em;
  }

  .Mui-selected {
    color: #00616f;
    font-weight: 600;
  }

  .MuiPaper-elevation4 {
    box-shadow: unset;
  }

  .MuiTabs-indicator {
    background-color: #00616f;
  }
`;

export const TabContent = styled.div``;
export const SubWrapper = styled.div`
  margin-top: 5.5em;
`;
export const HeadingBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .bordered {
    border: 1px solid #008c90;
    border-radius: 4px;
    background-color: #fff;
    color: #008c90;
  }
`;
export const SearchBar = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1.6em;
`;

export const TableContainer = styled.div`
  /* max-width: 50rem; */
  margin-top: 4.8em;
`;

export const ModalTop = styled.div`
  background-color: #3c8a8f;
  padding: 20px;
`;
export const ModalTittle = styled.h1`
  color: #fff;
  font-size: 1.8em;
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1em;
`;

export const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;
export const Badge = styled.span`
  color: #3f3f3f;
  font-weight: 700;
  padding: 4px 8px;
  background-color: #f8f8f8;
  border-radius: 4px;
`;
