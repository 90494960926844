import React, { useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { Form, SingleSelect } from '../../../../_core/_components';
import { EditUnidadeEconomicaProperty } from './reducer';

type Props = {
  empresas: any[];
  form: any;
  dispatch: React.Dispatch<{
    type: EditUnidadeEconomicaProperty;
    payload: any;
  }>;
  onClickDelete: (index: number) => void;
};

const ModalContent: React.FC<Props> = ({ empresas, form, dispatch, onClickDelete }: Props) => {
  const setEmpresa = (payload: unknown, index: number) => {
    const arr = [...form.empresas];
    arr[index].empresaId = payload;
    dispatch({ type: 'SET_EMPRESAS', payload: arr });
  };

  const onClickAdd = () => {
    const arr = [...form.empresas];
    arr.push({
      codigoUnidadeEconomica: '',
      empresaId: 0,
    });
    dispatch({ type: 'SET_EMPRESAS', payload: arr });
  };

  const camposRelacoes = useMemo(() => {
    const empresasOptions = empresas.map(e => ({ label: e.razaoSocial, value: e.id }));
    const arr: any[] = [];
    form.empresas.forEach((item: any, i: number) => {
      const empresaState = empresasOptions.find(e => e.value === item.empresaId);
      arr.push(
        <SingleSelect
          state={[empresaState, option => setEmpresa(option?.value, i)]}
          options={empresasOptions}
          isClearable={false}
        />,
      );
      const addButton = (
        <IconButton onClick={onClickAdd}>
          <Add />
        </IconButton>
      );
      const removeButton = (
        <IconButton onClick={() => onClickDelete(i)}>
          <Delete />
        </IconButton>
      );
      if (form.empresas.length === i + 1) {
        arr.push(
          <>
            {addButton}
            {removeButton}
          </>,
        );
      } else {
        arr.push(removeButton);
      }
      arr.push(<></>);
    });
    return arr;
  }, [form.empresas]);

  return <Form items={camposRelacoes} />;
};

export default ModalContent;
