import React, { useEffect, useState } from 'react';
import { Dialog, Grid } from '@material-ui/core';
import api from '../../../../_core/api';
import { failure, success } from '../../../../_core/services/toast';
import { Button, Form, Input, Select, Option } from '../../../../_core/_components';
import { CPFItem, Shopping } from './types';

import * as S from '../styles';
import { maskCPFCNPJ } from '../../../../_core/masks';

type Props = {
  onClose: () => void;
  onFinishSave: () => void;
  selectedRegister?: CPFItem | null;
  modulos: any[];
};

const Modal: React.FC<Props> = ({ onClose, selectedRegister, onFinishSave, modulos }: Props) => {
  const [loading, setLoading] = useState(false);
  const [shoppingOptions, setShoppingOptions] = useState<Option[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [observacao, setObservacao] = useState('');
  const [luc, setLuc] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [selectedModulos, setSelectedModulos] = useState<Option[]>([]);

  useEffect(() => {
    if (!selectedRegister) {
      resetModalState();
    } else {
      setSelectedShoppings(selectedRegister.shoppings.map(s => ({ label: s.nome, value: s.id })));
      setSelectedModulos(selectedRegister.modulos.map(s => ({ label: s.nome, value: s.id })));
      setObservacao(selectedRegister.observacao);
      setLuc(selectedRegister.luc);
      setCpfCnpj(selectedRegister.cpfCnpj);
    }
    getShoppingsOptions();
  }, [selectedRegister]);

  const getShoppingsOptions = async () => {
    const { data } = await api.get<Shopping[]>('/empresas/shoppings/simple-umpaged');
    const options: Option[] = data.map(s => ({
      label: s.nome,
      value: s.id,
    }));
    setShoppingOptions(options);
  };

  const resetModalState = () => {
    setSelectedShoppings([]);
    setSelectedModulos([]);
    setObservacao('');
    setLuc('');
    setCpfCnpj('');
  };

  const save = async () => {
    setLoading(true);
    const payload = {
      cpfCnpj: cpfCnpj || selectedRegister?.cpfCnpj,
      ativo: true,
      luc: luc || selectedRegister?.luc,
      observacao: observacao || selectedRegister?.observacao,
      shoppings:
        selectedShoppings.map(s => ({
          id: s.value,
        })) || selectedRegister?.shoppings,
      modulos:
        selectedModulos.map(m => ({
          id: m.value,
        })) || selectedRegister?.modulos,
    };

    if (selectedRegister) {
      try {
        await api.post(`/config/excecoes/luc-shopping/${selectedRegister.id}`, payload);
        success('Atualizado com sucesso.');
        setLoading(false);
        resetModalState();
        onFinishSave();
      } catch (e) {
        failure('Erro ao tentar atualizar registro.');
        setLoading(false);
      }
    } else {
      try {
        await api.post('/config/excecoes/luc-shopping', payload);
        success('Criado com sucesso.');
        setLoading(false);
        resetModalState();
        onFinishSave();
      } catch (e) {
        failure('Erro ao tentar criar registro.');
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <S.ModalTop>
        <S.ModalTittle> {!selectedRegister ? 'Adicionar' : 'Editar'} </S.ModalTittle>
      </S.ModalTop>

      <Grid>
        <Form
          items={[
            <Input
              pattern={maskCPFCNPJ}
              placeholder="05.878.397/0001-32"
              label="CPF/CNPJ"
              state={[cpfCnpj, setCpfCnpj]}
            />,
            <Input placeholder="SPN00103" label="LUC" state={[luc, setLuc]} />,
            <Input placeholder="Obs." label="Observações" state={[observacao, setObservacao]} />,
            <Select
              state={[selectedShoppings, setSelectedShoppings]}
              options={shoppingOptions}
              placeholder="Shoppings"
            />,
            <Select
              state={[selectedModulos, setSelectedModulos]}
              options={modulos.map(e => ({ value: e.id, label: e.nome }))}
              placeholder="Módulos"
            />,
          ]}
        />
        <S.ModalActions>
          <Button onClick={save} text="Salvar" disabled={loading} />
        </S.ModalActions>
      </Grid>
    </Dialog>
  );
};

export default Modal;
