import React, { useEffect, useMemo, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { MoreVert, Save as SaveIcon } from '@material-ui/icons';
import * as S from '../styles';
import { Button, Input, PaginatedTable, Modal } from '../../../../_core/_components';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { ModalContent } from './modal-content';
import api from '../../../../_core/api';
import { TipoDocumento as TipoDocumentoType, TipoDocumentoDTO } from './types';
import { handleErrors } from '../../../../_core/services/toast';

export function TipoDocumento(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [registerList, setRegisterList] = useState<TipoDocumentoDTO>();
  const [tipoDocumento, setTipoDocumento] = useState<TipoDocumentoType>();
  const [page, setPage] = useState(1);

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get<TipoDocumentoDTO>(`/config/inadimplencia/tipo-documento?page=${page}`);
      setRegisterList(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const addItem = () =>
    setTipoDocumento({
      sigla: '',
      codigo: 0,
      descricao: '',
      ativo: true,
    });

  const filteredItems = useMemo(() => {
    if (searchText) {
      const filtered = registerList?.data.filter(reg => reg.sigla.toUpperCase().includes(searchText.toUpperCase()));
      return filtered ?? [];
    }
    return registerList?.data ?? [];
  }, [searchText, registerList]);

  const renderedData = useMemo(() => {
    if (filteredItems.length > 0) {
      return filteredItems.map(item => ({
        ...item,
        actions: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.id!.toString()}
            onClick={() => setTipoDocumento(item)}
          >
            <MoreVert />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [filteredItems]);

  const saveItem = async () => {
    setLoading(true);
    try {
      if (tipoDocumento!.id) {
        await api.put(`/config/inadimplencia/tipo-documento/${tipoDocumento!.id}`, tipoDocumento);
      } else {
        await api.post('/config/inadimplencia/tipo-documento', tipoDocumento);
      }
      const { data } = await api.get<TipoDocumentoDTO>('/config/inadimplencia/tipo-documento?page=1');
      setTipoDocumento(undefined);
      setRegisterList(data);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  return (
    <S.DataContainer>
      <FullScreenLoading isEnabled={loading} />
      {tipoDocumento && (
        <Modal
          title="Adicionar/Editar Tipo Documento"
          onClose={() => setTipoDocumento(undefined)}
          content={<ModalContent state={[tipoDocumento, setTipoDocumento]} />}
          actionItems={[<Button onClick={saveItem} text="Salvar" startIcon={<SaveIcon />} />]}
        />
      )}
      <S.TopBar>
        <S.SearchBar>
          <S.SearchField>
            <Input label="Buscar" state={[searchText, setSearchText]} />
          </S.SearchField>
        </S.SearchBar>
        <Button text="Criar novo" onClick={addItem} />
      </S.TopBar>
      {renderedData.length > 0 && (
        <PaginatedTable
          columns={[
            { label: 'Id', key: 'id' },
            { label: 'Sigla', key: 'sigla' },
            { label: 'Código', key: 'codigo' },
            { label: 'Descrição', key: 'descricao' },
            { label: '', key: 'actions' },
          ]}
          data={renderedData}
          pagination={registerList!.pageInfo}
          onChangePage={setPage}
        />
      )}
    </S.DataContainer>
  );
}
