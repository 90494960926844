import React, { useMemo, useState } from 'react';
import { Container, SideMenu, ItemMenu } from './styles';
import { Justificativa } from './justificativa';
import { JobAutomatico } from './job-automatico';
import { Aging } from './aging';

const MENU_LIST = ['Configuração Aging', 'Job Automação Workflow', 'Lista Justificativa'];

export const CadastroSerasa: React.FC = () => {
  const [menu, setMenu] = useState(0);

  const menuItem = useMemo(() => {
    switch (menu) {
      case 0:
        return <Aging />;
      case 1:
        return <JobAutomatico />;
      default:
        return <Justificativa />;
    }
  }, [menu]);

  return (
    <Container>
      <SideMenu>
        {MENU_LIST.map((item: any, i: number) => (
          <ItemMenu key={i} onClick={() => setMenu(i)} active={i === menu}>
            {item}
          </ItemMenu>
        ))}
      </SideMenu>
      {menuItem}
    </Container>
  );
};
