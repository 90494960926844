import styled from 'styled-components';

export const CardFormContent = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
  padding: 1rem;
  border: 2px dashed #ccc;
  cursor: pointer;
`;
