import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 10px;
  padding: 0 4em;
  display: flex;
  gap: 4em;
  flex-wrap: wrap;
`;

export const ParamContainer = styled.div`
  background-color: #fbfbfb;
  box-shadow: 0 0.4em 0.4em rgba(0, 0, 0, 0.25);
  padding: 2em;
  flex: 0 0 55em;
`;
export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ParamTitle = styled.div`
  color: #555555;
  font-size: 1.6em;
  font-weight: bold;
`;

export const LoaderContainer = styled.div`
  margin: 10em 0;
  display: flex;
  justify-content: center;
`;

export const TableContainer = styled.div`
  max-height: 10em;
`;
