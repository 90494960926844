import React, { useContext, useState } from 'react';
import { Checkbox, Form, Input, SingleSelect, Modal as ModalComponent, Button } from '../../../../_core/_components';
import { AuthContext } from '../../../../_main/contexts/auth';
import { JobAutomatico } from './types';

type Props = {
  data: JobAutomatico;
  onClose: () => void;
  onSave: (payload: any) => void;
};

const BOOL_OPTIONS = [
  { value: true, label: 'Ativo' },
  { value: false, label: 'Inativo' },
];

export const Modal: React.FC<Props> = ({ data, onClose, onSave }: Props) => {
  const { id } = useContext(AuthContext);
  const [payload, setPayload] = useState({
    categoria: data.categoria,
    codigoParametro: data.codigoParametro,
    valor: ['false', 'true'].includes(data.valorParametro) ? JSON.parse(data.valorParametro) : data.valorParametro,
    idUsuario: id,
    statusAtivo: data.statusAtivo,
  });

  const inputValue =
    typeof payload.valor === 'boolean' ? (
      <SingleSelect
        isClearable={false}
        state={[
          BOOL_OPTIONS.find(e => e.value === payload.valor),
          option => setPayload({ ...payload, valor: option!.value }),
        ]}
        options={BOOL_OPTIONS}
        placeholder="Valor"
      />
    ) : (
      <Input label="Valor" state={[payload.valor, value => setPayload({ ...payload, valor: value.toString() })]} />
    );

  const content = (
    <Form
      items={[
        <Input
          label="Categoria"
          state={[payload.categoria, value => setPayload({ ...payload, categoria: value.toString() })]}
        />,
        <Input
          label="Código parâmetro"
          state={[payload.codigoParametro, value => setPayload({ ...payload, codigoParametro: value.toString() })]}
        />,
        inputValue,
        <Checkbox
          checked={payload.statusAtivo}
          onClick={() => setPayload({ ...payload, statusAtivo: !payload.statusAtivo })}
          label="Ativo"
        />,
      ]}
    />
  );

  return (
    <ModalComponent
      title="Adicionar/Editar Workflow"
      onClose={onClose}
      content={content}
      actionItems={[<Button onClick={() => onSave(payload)} text="Salvar" />]}
    />
  );
};
