import styled from 'styled-components';

type ButtonProps = {
  filled?: boolean;
};

export const ConfirmationContent = styled.div`
  padding: 3rem 6rem;
`;

export const ConfirmationTitle = styled.h1`
  color: #208cb9;
  padding: 1rem 0;
`;

export const ConfirmationText = styled.p`
  padding: 3rem 0;
  border-top: 1px solid #dfd5d5a8;
  border-bottom: 1px solid #dfd5d5a8;
`;

export const ConfirmationButton = styled.button<ButtonProps>`
  cursor: pointer;
  border: none;
  background-color: ${props => (props.filled ? '#208cb9' : 'transparent')};
  color: ${props => (props.filled ? '#ffffff' : '#208cb9')};
  padding: 1.1rem 3.5rem;
  margin-left: 2rem;
  border-radius: 1.4rem;
`;

export const ConfirmationActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;
`;

export const Header = styled.div`
  background: #3c8a8f;
  color: #fff;
  font-weight: 700;
  font-size: 2.4em;
  padding: 20px 16px;
`;

export const Text = styled.p`
  font-size: 1.4em;
  padding: 20px 16px;
`;

export const Wrapper = styled.div`
  font-size: 10px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 16px;
  gap: 16px;

  .bordered {
    border: 1px solid #008c90;
    border-radius: 4px;
    background-color: #fff;
    color: #008c90;
  }

  .exclude {
    border-radius: 4px;
    background-color: #e5032c;
    color: #fff;
  }
`;
