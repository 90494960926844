import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;
export const Content = styled.div``;
export const Actions = styled.div`
  margin: 2em 0;
  border-top: 1px solid #e1e1e1;
  padding: 2em 0;
  text-align: right;
`;

export const DialogWrapper = styled.div`
  font-size: 10px;
`;
export const DialogHeader = styled.div`
  background-color: #00959c;
  font-weight: bold;
  padding: 2em;
  h1 {
    font-size: 2em;
    color: #fff;
  }
`;

export const DialogContent = styled.div`
  padding: 2em;
`;

export const InputLine = styled.div`
  display: flex;
  gap: 3em;
  margin: 3em 0;
`;

export const InputGroup = styled.div`
  flex: 0 0 45%;
`;

export const Label = styled.div`
  color: #8890b5;
  font-size: 1.2em;
`;

export const ModalActions = styled.div`
  margin: 2em 0;
  border-top: 1px solid #e1e1e1;
  padding: 2em;
  text-align: right;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  height: 20em;
`;

type ActionProps = {
  variant?: 'remove';
};

export const Action = styled.div<ActionProps>`
  ${({ variant }) => css`
    color: ${variant ? '#D74242' : '#3D3D3D'};
  `}
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
  border-radius: 10px;
`;

export const CloseAction = styled.span`
  position: absolute;
  top: 2em;
  right: 2em;
  cursor: pointer;

  svg {
    fill: white;
  }
`;

export const RowActions = styled.span`
  display: flex;
  gap: 3em;
  max-width: 17em;
`;
