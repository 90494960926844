import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const BootstrapButton = withStyles({
  root: {
    backgroundColor: '#00959c',
    borderColor: '#00959c',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#00959c',
      borderColor: '#00959c',
    },
    '&:active': {
      backgroundColor: '#00959c',
      borderColor: '#00959c',
    },
  },
  textSecondary: {
    backgroundColor: '#fff',
    border: 'none',
    color: '#00959c',
    '&:hover': {
      backgroundColor: '#fff',
      borderColor: '#fff',
    },
    '&:active': {
      backgroundColor: '#fff',
      borderColor: '#fff',
    },
  },
})(Button);

type Props = {
  text: string;
  startIcon?: React.ReactNode;
} & ButtonProps;

const CustomButton: React.FC<Props> = ({ text, ...props }) => {
  return (
    <BootstrapButton variant="contained" color="primary" {...props}>
      {text}
    </BootstrapButton>
  );
};

export default CustomButton;
