/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import Table from '@material-ui/core/Table';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import { ContatoDB } from '../../types';
import * as S from './styles';

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: '#FBFBFB',
      color: '#00959c',
      fontWeight: 'bold',
      fontSize: 12,
    },
    body: {
      fontSize: 12,
    },
    stickyHeader: {
      top: '4em',
    },
    alignRight: {
      display: 'flex',
    },
  }),
)(TableCell);

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#fff',
      '&:nth-of-type(odd)': {
        backgroundColor: '#E5E9EA',
      },

      '&:hover': {
        backgroundColor: '#daeedd',
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

type Props = {
  listaContatos?: ContatoDB[];
  onEdit: (contact: ContatoDB) => void;
  onRemove: (contact: ContatoDB) => void;
};

const CustomizedTables: React.FC<Props> = ({ listaContatos, onEdit, onRemove }) => {
  const classes = useStyles();

  if (listaContatos && listaContatos.length > 0) {
    return (
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Nome</StyledTableCell>
            <StyledTableCell>Tipo</StyledTableCell>
            <StyledTableCell>E-mail</StyledTableCell>
            <StyledTableCell>Telefone</StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {listaContatos?.map((row, idx) => (
            <StyledTableRow key={idx}>
              <StyledTableCell>{row.contactName}</StyledTableCell>
              <StyledTableCell>{row.contactType}</StyledTableCell>
              <StyledTableCell>{row.email}</StyledTableCell>
              <StyledTableCell>{row.phoneNumber}</StyledTableCell>
              <StyledTableCell align="right">
                <S.RowActions>
                  <S.Action onClick={() => onEdit(row)}>
                    Editar
                    <EditIcon />
                  </S.Action>

                  <S.Action variant="remove" onClick={() => onRemove(row)}>
                    Excluir
                    <DeleteOutlineIcon />
                  </S.Action>
                </S.RowActions>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return <></>;
};

export default CustomizedTables;
