import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

export const TabsWrapper = styled.div`
  border-radius: 0 0 6px 6px;
  padding: 2em 1em;
  min-height: 10em;
  position: relative;
  background-color: #fbfbfb;
`;
export const Tabs = styled.div`
  display: flex;

  flex-wrap: wrap;
  gap: 0.1rem;
`;

type TabProps = {
  active?: boolean;
};

export const SideMenu = styled.div`
  display: flex;
  height: 30px;
`;

export const ItemMenu = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  font-weight: 600;
  font-size: 0.8rem;
  background-color: ${({ active }) => (active ? '#008C90' : '#d8d8d8')};
  color: ${({ active }) => (active ? '#fff' : '#6d6d6d')};
  flex: 1 0 0;

  &:hover {
    background-color: #008c90;
    color: #fff;
  }
`;

export const DataContainer = styled.div`
  padding: 1.5rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const SearchBar = styled.div``;

export const SearchField = styled.div``;

export const Tab = styled.div<TabProps>`
  padding: 0.3em 5em 0.3em 3em;
  font-size: 0.8rem;
  z-index: 6;
  color: #008c90;
  position: relative;
  transition: all 0.7s ease;
  flex: 0 0 17rem;
  border: 1px solid #008c90;

  ${({ active }) =>
    active &&
    css`
      z-index: 7 !important;
      color: #fbfbfb !important;
      background-color: #00959c !important;
    `}
  ${({ active }) =>
    !active &&
    css`
      &:hover {
        cursor: pointer;
        color: #fff !important;
        background-color: #00959c !important;
      }
    `};
`;

export const TabsContent = styled.div``;

export const SubItemTitle = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
`;

export const CloseAction = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;

  svg {
    fill: #00959c;
  }
`;

export const TableContainer = styled.div`
  max-width: 50rem;
`;

export const IconLabel = styled.p`
  font-size: 0.8rem;
  margin-left: 0.8rem;
`;

export const Actions = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  button:first-child {
    font-weight: bold;
  }
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: calc(0.6rem + 8px);
  margin-bottom: 28px;
`;
