import React from 'react';
import * as S from './styles';
import { InadimplenciaItem } from '.';
import { toReal } from '../../../../../_core/services/formaters';

type Props = {
  item: InadimplenciaItem;
};

const Card: React.FC<Props> = ({ item }) => {
  return (
    <S.Card>
      <S.CardHeader>
        <S.InfoGroup>
          <S.Label>LUC</S.Label>
          <S.Info>{item.luc}</S.Info>
        </S.InfoGroup>

        <S.InfoGroup>
          <S.Label>Mês/Ano</S.Label>
          <S.Info>{item.monthYear}</S.Info>
        </S.InfoGroup>

        <S.InfoGroup>
          <S.Label>Total Faturado</S.Label>
          <S.Info variant="green">{toReal(item.totalInvoiced)}</S.Info>
        </S.InfoGroup>
      </S.CardHeader>
      <S.CardDetails>
        <S.DetailTitle variant="green">DETALHE FATURAMENTO</S.DetailTitle>
        <S.Details>
          <S.DetailGroup>
            <S.Label>Aluguéis</S.Label>
            <S.DetailInfo>{toReal(item.rentsInvoiced)}</S.DetailInfo>
          </S.DetailGroup>
          <S.DetailGroup>
            <S.Label>Comerciais</S.Label>
            <S.DetailInfo>{toReal(item.commercialsInvoiced)}</S.DetailInfo>
          </S.DetailGroup>
          <S.DetailGroup>
            <S.Label>Condomínio</S.Label>
            <S.DetailInfo>{toReal(item.condominiumInvoiced)}</S.DetailInfo>
          </S.DetailGroup>
          <S.DetailGroup>
            <S.Label>Fundo</S.Label>
            <S.DetailInfo>{toReal(item.fundInvoiced)}</S.DetailInfo>
          </S.DetailGroup>
          <S.DetailGroup>
            <S.Label>Confissão</S.Label>
            <S.DetailInfo>{toReal(item.confessionInvoiced)}</S.DetailInfo>
          </S.DetailGroup>
        </S.Details>
      </S.CardDetails>

      <S.CardHeader>
        <S.InfoGroup>
          <S.Label>Total Inad. Mês</S.Label>
          <S.Info>{toReal(item.totalDefaultMonth)}</S.Info>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.Label>% Inad. Shopping</S.Label>
          <S.Info variant="purple">{item.shoppingDefaultPercentage} %</S.Info>
        </S.InfoGroup>
      </S.CardHeader>

      <S.CardDetails>
        <S.DetailTitle variant="purple">DETALHE INADIMPLÊNCIA</S.DetailTitle>
        <S.Details>
          <S.DetailGroup>
            <S.Label>Aluguéis</S.Label>
            <S.DetailInfo>{toReal(item.rentsDefault)}</S.DetailInfo>
          </S.DetailGroup>
          <S.DetailGroup>
            <S.Label>Comerciais</S.Label>
            <S.DetailInfo>{toReal(item.commercialsDefault)}</S.DetailInfo>
          </S.DetailGroup>
          <S.DetailGroup>
            <S.Label>Condomínio</S.Label>
            <S.DetailInfo>{toReal(item.condominiumDefault)}</S.DetailInfo>
          </S.DetailGroup>
          <S.DetailGroup>
            <S.Label>Fundo</S.Label>
            <S.DetailInfo>{toReal(item.fundDefault)}</S.DetailInfo>
          </S.DetailGroup>
          <S.DetailGroup>
            <S.Label>Confissão</S.Label>
            <S.DetailInfo>{toReal(item.confessionDefault)}</S.DetailInfo>
          </S.DetailGroup>
        </S.Details>
      </S.CardDetails>
    </S.Card>
  );
};

export default Card;
