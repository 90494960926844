import React, { useRef, useState } from 'react';
import { CalendarToday, CloudUpload } from '@material-ui/icons';
import api, { baseURL } from '../../../_core/api';
import icon from '../../../assets/cloud-computing.svg';
import { Container, Content, ImportArea, InputGroup } from './styles';
import { Button, Input } from '../../../_core/_components';
import { failure, handleErrors, success } from '../../../_core/services/toast';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { mascaraDDMMAAAA, mascaraMMAAAA } from '../../../_core/masks';

export const ImportacoesInadimplencia: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [dateUploadXls, setDateUploadXls] = useState<string>('');
  const [dateUploadZip, setDateUploadZip] = useState<string>('');

  const [files, setFiles] = useState<File[]>([]);

  const refXls = useRef<HTMLInputElement | null>(null);
  const refZip = useRef<HTMLInputElement | null>(null);
  const refResumo = useRef<HTMLInputElement | null>(null);

  const handleSendButton = (postType: string) => {
    setLoading(true);
    if (postType === 'inputDateXls') {
      sendFormXls();
      return;
    }
    sendFormZip();
  };

  const sendFormXls = async () => {
    const token = localStorage.getItem('@Siscob:token');
    const fileInput = document.getElementById('inputFileXls') as HTMLInputElement;

    if (fileInput.files![0] != null) {
      if (dateUploadXls !== '') {
        const formdata = new FormData();
        formdata.append('file', fileInput.files![0]);
        formdata.append('date', dateUploadXls);

        const requestOptions: RequestInit = {
          method: 'POST',
          body: formdata,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        fetch(`${baseURL}/non-payment-summary/upload`, requestOptions).then(response => {
          if (response.ok) {
            response.text();
            success('Enviado com sucesso.');
          } else {
            failure('Insira um arquivo válido.');
          }
        });
      } else {
        failure('Insira uma data válida.');
      }
    } else {
      failure('Insira um arquivo válido.');
    }
    setLoading(false);
  };

  const sendFormZip = async () => {
    const token = localStorage.getItem('@Siscob:token');
    const fileInput = document.getElementById('inputFileZip') as HTMLInputElement;

    if (fileInput.files![0] != null) {
      if (dateUploadZip != null) {
        const formdata = new FormData();
        formdata.append('file', fileInput.files![0]);
        formdata.append('month', `${dateUploadZip.split('/')[0]}`);
        formdata.append('year', `${dateUploadZip.split('/')[1]}`);

        const requestOptions: RequestInit = {
          method: 'POST',
          body: formdata,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        fetch(`${baseURL}/non-payment-file/upload`, requestOptions).then(response => {
          if (response.ok) {
            response.text();
            success('Enviado com sucesso');
          } else {
            failure('Insira um arquivo válido.');
          }
        });
      } else {
        failure('Insira uma data válida.');
      }
    } else {
      failure('Insira um arquivo válido.');
    }
    setLoading(false);
  };

  const onClickSubmitUpload = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', files[2]!);
      await api.post('/inadimplencia/upload/resumo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const arr = [...files];
      delete arr[2];
      setFiles(arr);
      success('Envio realizado com sucesso');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const blob = e.target.files ? e.target.files[0] : undefined;
    if (blob === undefined) return;
    const arr = [...files];
    arr[i] = blob;
    setFiles(arr);
  };

  return (
    <>
      <FullScreenLoading isEnabled={loading} />
      <Container>
        <Content>
          <h1>Importar dados da planilha com a apuração final da inadimplência</h1>
          <ImportArea onClick={() => refXls.current?.click()}>
            <h2>Buscar arquivos</h2>
            <img src={icon} alt="" />
            <InputGroup onClick={e => e.stopPropagation()}>
              <Input
                state={[dateUploadXls, setDateUploadXls]}
                endIcon={<CalendarToday />}
                pattern={mascaraDDMMAAAA}
                style={{ backgroundColor: 'white' }}
                placeholder="01/01/2024"
              />
              <Input
                state={[files[0]?.name ?? 'Escolher arquivo', () => null]}
                disabled
                style={{ backgroundColor: 'white' }}
                startIcon={<CloudUpload />}
              />
              <input id="inputFileXls" type="file" hidden onChange={e => onFileChange(e, 0)} ref={refXls} />
            </InputGroup>
          </ImportArea>
          <Button text="Enviar" onClick={() => handleSendButton('inputDateXls')} disabled={files[0] === undefined} />
        </Content>

        <Content>
          <h1>Importar dados da planilha ZIP</h1>
          <ImportArea onClick={() => refZip.current?.click()}>
            <h2>Buscar arquivos</h2>
            <img src={icon} alt="" />
            <InputGroup onClick={e => e.stopPropagation()}>
              <Input
                state={[dateUploadZip, setDateUploadZip]}
                endIcon={<CalendarToday />}
                pattern={mascaraMMAAAA}
                style={{ backgroundColor: 'white' }}
                placeholder="01/2024"
              />
              <Input
                state={[files[1]?.name ?? 'Escolher arquivo', () => null]}
                disabled
                style={{ backgroundColor: 'white' }}
                startIcon={<CloudUpload />}
              />
              <input id="inputFileZip" type="file" hidden onChange={e => onFileChange(e, 1)} ref={refZip} />
            </InputGroup>
          </ImportArea>
          <Button text="Enviar" onClick={() => handleSendButton('inputDateZip')} disabled={files[1] === undefined} />
        </Content>

        <Content>
          <h1>Importar dados da planilha ZIP</h1>
          <ImportArea onClick={() => refResumo.current?.click()}>
            <h2>Buscar arquivos</h2>
            <img src={icon} alt="" />
            <InputGroup onClick={e => e.stopPropagation()}>
              <Input
                state={[files[2]?.name ?? 'Escolher arquivo', () => null]}
                disabled
                style={{ backgroundColor: 'white' }}
                startIcon={<CloudUpload />}
              />
              <input id="inputFileZip" type="file" hidden onChange={e => onFileChange(e, 2)} ref={refResumo} />
            </InputGroup>
          </ImportArea>
          <Button text="Enviar" onClick={onClickSubmitUpload} disabled={files[2] === undefined} />
        </Content>
      </Container>
    </>
  );
};
