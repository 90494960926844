import React, { useMemo, useState } from 'react';
import { Container } from './styles';
import RequisitosMinimos from './requisitos-minimos';
import TiposDeContrato from './tipos-contrato';
import TiposDeAcao from './tipos-acao';
import ContaContabil from './conta-contabil';
import TipoDivida from './tipo-divida';
import StatusAcao from './status-acao';
import TipoCredito from './tipo-credito';
import TipoDividaConfig from './tipo-divida-config';
import Justificativas from './justificativas';
import { TipoMovimento } from './tipo-movimento';
import { TipoDocumento } from './tipo-documento';
import { TipoMovimentoAcaoDivida } from './tipo-movimento-acao-divida';
import * as S from './styles';

const MENU_LIST = [
  'Requisitos Mínimos',
  'Conta Contábil',
  'Tipo de Contrato',
  'Tipo de Ação',
  'Tipo de Dívida',
  'Configuração Tipo de Dívida',
  'Status da Ação',
  'Tipo de Crédito',
  'Justificativas',
  'Tipo de Movimento',
  'Tipo de Documento',
  'Tipo de Movimento e Ação Dívida',
];

export const CadastroAjuizamento: React.FC = () => {
  const [menu, setMenu] = useState(0);

  const menuItem = useMemo(() => {
    switch (menu) {
      case 0:
        return <RequisitosMinimos />;
      case 1:
        return <ContaContabil />;
      case 2:
        return <TiposDeContrato />;
      case 3:
        return <TiposDeAcao />;
      case 4:
        return <TipoDivida />;
      case 5:
        return <TipoDividaConfig />;
      case 6:
        return <StatusAcao />;
      case 7:
        return <TipoCredito />;
      case 8:
        return <Justificativas />;
      case 9:
        return <TipoMovimento />;
      case 10:
        return <TipoDocumento />;
      case 11:
        return <TipoMovimentoAcaoDivida />;

      default:
        return null;
    }
  }, [menu]);

  return (
    <Container>
      <S.TabsWrapper>
        <S.Tabs>
          {MENU_LIST.map((m, i) => {
            return (
              <S.Tab onClick={() => setMenu(i)} active={menu === i}>
                {m}
              </S.Tab>
            );
          })}
        </S.Tabs>

        <S.TabsContent>{menuItem}</S.TabsContent>
      </S.TabsWrapper>
    </Container>
  );
};
