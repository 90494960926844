import styled, { css } from 'styled-components';

export const Content = styled.div`
  font-size: 10px;
  border-radius: 6px;
  background-color: #fff;
`;
export const Header = styled.div`
  background-color: #fbfbfb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px 6px 0px 0px;
  display: flex;
  padding: 2em 1em;
`;
export const HeaderLeft = styled.div`
  flex: 0 0 60%;
  padding: 0 2em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 3em;
`;
export const HeaderRight = styled.div`
  flex: 0 0 40%;
  border-left: 1px solid #e1e1e1;
  padding: 0 2em;
`;
export const HeaderInfoLabel = styled.div`
  font-size: 1.4em;
  font-weight: 400;
  color: #8890b5;
`;
export const HeaderInfo = styled.div``;

export const HeaderInfoValue = styled.div`
  font-weight: 700;
  font-size: 1.6em;
  color: #555555;
`;

export const HeaderRightLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  > div {
    width: 20em;
  }
`;
export const HeaderIndicatorWrapper = styled.div``;
export const HeaderIndicatorLabel = styled.div`
  font-size: 1.4em;
  font-weight: 400;
  color: #8890b5;
`;

const hoverIndicatorModifier: any = {
  red: () => css`
    color: #c27272;
  `,
  yellow: () => css`
    color: #c2b172;
  `,
  green: () => css`
    color: #72c2bd;
  `,
};

type HeaderIndicatorValueProps = {
  textColor: string;
};
export const HeaderIndicatorValue = styled.div<HeaderIndicatorValueProps>`
  font-weight: 700;
  font-size: 1.9em;
  color: #c27272;
  ${({ textColor }) => hoverIndicatorModifier[textColor]}
`;

export const TabsWrapper = styled.div`
  background-color: #fbfbfb;
  border-radius: 0 0 6px 6px;
  padding: 2em 1em;
  min-height: 10em;
  margin-top: 8em;
  position: relative;
`;
export const Tabs = styled.div`
  display: flex;
  position: absolute;
  top: -2.8em;
  left: 0em;
`;

type TabProps = {
  active?: boolean;
};

export const Tab = styled.div<TabProps>`
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 90% 0%);
  padding: 0.3em 5em 0.3em 3em;
  font-size: 1.4em;
  z-index: 6;
  background-color: #dddddd;
  color: #8e8e8e;
  position: relative;
  transition: all 0.7s ease;

  &:nth-child(2) {
    left: -1.6em;
    z-index: 5;
  }

  &:nth-child(3) {
    left: -3em;
    z-index: 4;
  }

  &:nth-child(4) {
    left: -4.9em;
    z-index: 3;
  }

  ${({ active }) =>
    active &&
    css`
      z-index: 7 !important;
      color: #00959c !important;
      background-color: #fbfbfb !important;
    `}

  ${({ active }) =>
    !active &&
    css`
      &:hover {
        cursor: pointer;
        color: #fff !important;
        background-color: #00959c !important;
      }
    `}
`;
export const TabsContent = styled.div``;

export const Back = styled.div`
  cursor: pointer;
  display: flex;
  gap: 1em;
  color: #00959c;
  align-items: center;

  svg {
    fill: #00959c;
  }
`;

export const LoadingBolWrapper = styled.div`
  display: flex;
  height: 20em;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

export const TopActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
  user-select: none;
  font-size: 1.2em;
`;

export const ClientChanger = styled.div`
  display: flex;
  gap: 4em;
  align-items: center;
  p {
    color: #00959c;
    font-weight: bold;
  }
`;
