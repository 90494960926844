import React from 'react';
import { Grid } from '@material-ui/core';
import { Checkbox, Form, Input } from '../../../../_core/_components';
import { TipoDocumento } from './types';

type Props = {
  state: [TipoDocumento, React.Dispatch<React.SetStateAction<TipoDocumento | undefined>>];
};

export const ModalContent: React.FC<Props> = ({ state }: Props) => {
  const [tipoDocumento, setTipoDocumento] = state;

  return (
    <Grid>
      <Form
        items={[
          <Input
            label="Sigla"
            state={[tipoDocumento.sigla, value => setTipoDocumento({ ...tipoDocumento, sigla: value.toString() })]}
          />,
          <Input
            label="Código"
            state={[
              tipoDocumento.codigo ? tipoDocumento.codigo.toString() : '',
              value => setTipoDocumento({ ...tipoDocumento, codigo: parseInt(value.toString(), 10) }),
            ]}
          />,
          <Input
            label="Descrição"
            state={[
              tipoDocumento.descricao,
              value => setTipoDocumento({ ...tipoDocumento, descricao: value.toString() }),
            ]}
          />,
          <Checkbox
            label="Ativo"
            checked={tipoDocumento.ativo}
            onClick={() => setTipoDocumento({ ...tipoDocumento, ativo: !tipoDocumento.ativo })}
          />,
        ]}
      />
    </Grid>
  );
};
