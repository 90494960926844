import React from 'react';
import { Checkbox, Form, Input, Select, SingleSelect, Option } from '../../../../_core/_components';
import { EditUserProperty } from './reducer';

type Props = {
  shoppingsList: Option[];
  perfisList: Option[];
  form: any;
  dispatch: React.Dispatch<{
    type: EditUserProperty;
    payload: any;
  }>;
  onBlurUsername: () => void;
};

const ModalContent: React.FC<Props> = ({ shoppingsList, perfisList, form, dispatch, onBlurUsername }: Props) => {
  const onEditShoppings = (payload: any) => {
    dispatch({ type: 'SHOPPINGS', payload });
  };

  return (
    <Form
      items={[
        <Input
          state={[
            form.userName ?? '',
            (value: React.SetStateAction<string>) => dispatch({ type: 'USERNAME', payload: value }),
          ]}
          onBlur={onBlurUsername}
          label="Email (Obrigatório)"
        />,
        <Input
          state={[form.name ?? '', (value: React.SetStateAction<string>) => dispatch({ type: 'NAME', payload: value })]}
          label="Nome (Obrigatório)"
        />,
        <Input
          state={[
            form.lotacao ?? '',
            (value: React.SetStateAction<string>) => dispatch({ type: 'LOTACAO', payload: value }),
          ]}
          label="Shopping lotação"
        />,
        <SingleSelect
          options={perfisList}
          state={[form.profile, option => dispatch({ type: 'PROFILE', payload: option })]}
          placeholder="Perfil (Obrigatório)"
        />,
        <Select options={shoppingsList} state={[form.shoppings, onEditShoppings]} placeholder="Shoppings" />,
        <Checkbox
          checked={form.ativo ?? false}
          onClick={() => dispatch({ type: 'ACTIVE', payload: !form.ativo })}
          label="Ativo"
        />,
      ]}
    />
  );
};

export default ModalContent;
