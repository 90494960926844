import React, { useMemo } from 'react';
import Table from '../../../_core/_components/table';
import { Checkbox } from '../../../_core/_components';

export default function ModalContent({
  values,
  selectedModalData,
}: {
  values: any[];
  selectedModalData: [number[], React.Dispatch<React.SetStateAction<number[]>>];
}): JSX.Element {
  const [selectedData, setSelectedData] = selectedModalData;

  const renderedData = useMemo(
    () =>
      values.map(item => ({
        toggle: <Checkbox checked={selectedData.includes(item.numBoleto)} onClick={() => clickRow(item.numBoleto)} />,
        numBoleto: item.numBoleto,
        nomFantasia: item.nomFantasia,
        luc: item.luc,
      })),
    [values, selectedData],
  );

  const clickRow = (numBoleto: number) => {
    const arr = [...selectedData];
    const indexOf = arr.indexOf(numBoleto);
    if (indexOf !== -1) {
      setSelectedData(selectedData.filter(e => e !== numBoleto));
    } else {
      setSelectedData([...selectedData, numBoleto]);
    }
  };

  return (
    <Table
      columns={[
        { label: 'Selecionar', key: 'toggle' },
        { label: 'Número do Boleto', key: 'numBoleto', orderable: true },
        { label: 'Razão Social', key: 'nomFantasia', orderable: true },
        { label: 'luc', key: 'luc', orderable: true },
      ]}
      data={renderedData}
    />
  );
}
