import React, { useEffect, useMemo, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import * as S from '../styles';
import { Button, Input, Option, Table } from '../../../../_core/_components';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import api from '../../../../_core/api';
import { handleErrors } from '../../../../_core/services/toast';
import { CamposObrigatoriosDTO, JustificativasDTO, Justificativa as JustificativaType } from './types';
import { Modal } from './modal';

export function Justificativa(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState<JustificativaType[]>([]);
  const [selectedData, setSelectedData] = useState<JustificativaType>();
  const [camposObrigatorios, setCamposObrigatorios] = useState<Option[]>([]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get<JustificativasDTO>(`/configuracao/serasa/justificativa/`);
      const { data: dataCamposObrigatorios } = await api.get<CamposObrigatoriosDTO>(
        '/configuracao/serasa/justificativa/campos',
      );
      setData(data.content);
      setCamposObrigatorios(dataCamposObrigatorios.content.map(e => ({ label: e, value: e })));
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const renderedData = useMemo(() => {
    if (data.length > 0) {
      return data.map(item => ({
        ...item,
        flagAprovadorString: item.flagAprovador ? 'Ativo' : 'Inativo',
        actions: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.idSerasaJustificatica!.toString()}
            onClick={() => setSelectedData(item)}
          >
            <MoreVert />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [data]);

  const onSave = async (payload: JustificativaType) => {
    setLoading(true);
    try {
      if (payload.idSerasaJustificatica) {
        await api.put(`/configuracao/serasa/justificativa/${payload.idSerasaJustificatica}`, payload);
      } else {
        await api.post(`/configuracao/serasa/justificativa/`, payload);
      }
      setSelectedData(undefined);
      const { data } = await api.get<JustificativasDTO>(`/configuracao/serasa/justificativa/`);
      setData(data.content);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  return (
    <S.DataContainer>
      <FullScreenLoading isEnabled={loading} />
      {selectedData && (
        <Modal
          data={selectedData}
          onSave={onSave}
          onClose={() => setSelectedData(undefined)}
          camposObrigatorios={camposObrigatorios}
        />
      )}
      <S.TopBar>
        <S.SearchBar>
          <S.SearchField>
            <Input label="Buscar" state={[searchText, setSearchText]} />
          </S.SearchField>
        </S.SearchBar>
        <Button
          text="Criar novo"
          onClick={() =>
            setSelectedData({
              txtJustificativa: '',
              campoObrigatorio: '<combo> campos',
              limiteExcecao: '',
              flagAprovador: true,
            })
          }
        />
      </S.TopBar>
      {renderedData.length > 0 && (
        <>
          <Table
            columns={[
              { label: 'Id', key: 'idSerasaJustificatica' },
              { label: 'Justificativa', key: 'txtJustificativa' },
              { label: 'Campo obrigatório', key: 'campoObrigatorio' },
              { label: 'Limite exceção', key: 'limiteExcecao' },
              { label: 'Flag aprovador', key: 'flagAprovadorString' },
              { label: 'Editar', key: 'actions' },
            ]}
            data={renderedData}
          />
        </>
      )}
    </S.DataContainer>
  );
}
