import React, { useMemo, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import * as S from './styles';
import CPFCNPJ from './cpf-cnpj';
import TipoDocumento from './tipo-documento';
import CentroLucro from './centro-lucro';
import NumeroDocumento from './numero-documento';

const MENU_LIST = ['CPF/CNPJ', 'TIPO DOCUMENTO', 'CENTRO LUCRO', 'Nº DOCUMENTO'];

const ConfiguracoesExcecoes = () => {
  const [value, setValue] = useState(0);

  const a11yProps = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const TabContent = useMemo(() => {
    switch (value) {
      case 0:
        return <CPFCNPJ />;
      case 1:
        return <TipoDocumento />;
      case 2:
        return <CentroLucro />;
      case 3:
        return <NumeroDocumento />;
      default:
        return null;
    }
  }, [value]);

  return (
    <S.Wrapper>
      <S.Navigation>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            {MENU_LIST.map((m, i) => {
              return <Tab label={m} {...a11yProps(i)} />;
            })}
          </Tabs>
        </AppBar>
      </S.Navigation>

      <S.TabContent>{TabContent}</S.TabContent>
    </S.Wrapper>
  );
};

export default ConfiguracoesExcecoes;
