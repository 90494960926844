/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import { Button, Form, Input } from '../../../../_core/_components';

type Props = {
  onSave: (payload: any) => void;
  onClose: () => void;
};

const Modal: React.FC<Props> = ({ onSave, onClose }: Props) => {
  const [conta, setConta] = useState('');

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Adicionar / Alterar
        </span>
        <Grid>
          <Form
            items={[<Input label="Tipo de Crédito" state={[conta, setConta]} />]}
            submitButton={<Button onClick={() => onSave({ conta })} text="Salvar" />}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
