import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

type Props = {
  checked: boolean;
  change: (val: boolean) => void;
} & CheckboxProps;

const GreenCheckbox = withStyles({
  root: {
    // color: 'red',
    '&$checked': {
      color: '#00959c',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const CustomCheckbox: React.FC<Props> = ({ checked, change, ...props }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    change(event.target.checked);
  };

  return <GreenCheckbox checked={checked} onChange={handleChange} name="checkedG" {...props} />;
};

export default CustomCheckbox;
