import React from 'react';
import { Dialog } from '@material-ui/core';

import * as S from './styles';
import { Button } from '../button';

type Props = {
  handleCancel: () => void;
  handleOk: () => void;
  open: boolean;
  text: string | JSX.Element;
  title?: string;
  confirmButtonText?: string;
};

const ConfirmationModal: React.FC<Props> = ({
  handleCancel,
  handleOk,
  open,
  text,
  title = 'Confirmação',
  confirmButtonText = 'CONFIRMAR',
}: Props) => {
  return (
    <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
      <S.Wrapper>
        <S.Header>{title}</S.Header>
        <S.Text>{text}</S.Text>
        <S.Actions>
          <Button text="CANCELAR" classname="bordered" onClick={handleCancel} />
          <Button text={confirmButtonText} onClick={handleOk} />
        </S.Actions>
      </S.Wrapper>
    </Dialog>
  );
};

export default ConfirmationModal;
