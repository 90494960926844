import React, { useEffect, useState, useContext, useMemo } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import api from '../../../_core/api';
import Table from './components/table';
import { AuthContext } from '../../../_main/contexts/auth';
import { warning, handleErrors } from '../../../_core/services/toast';
import * as S from './styles';
import * as T from './types';
import { Button, Checkbox, Form, Option, SingleSelect } from '../../../_core/_components';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';

export const Priorizacao: React.FC = () => {
  const history = useHistory<{ selectedDate: Date; shopping: Option; refresh: boolean }>();
  const { state } = history.location;
  const { profile, shoppings: profileShoppings } = useContext(AuthContext);

  const isCarteira = ['COBRANÇA CORPORATIVA', 'APROVADOR HOLDING'].includes(profile);

  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(state?.refresh);

  const [portfolios, setPortfolios] = useState<Option[]>([]);

  const [selectedPortfolio, setSelectedPortfolio] = useState<Option>();
  const [selectedShopping, setSelectedShopping] = useState<Option | undefined>(state?.shopping);
  const [selectedDate, setSelectedDate] = useState(moment(state?.selectedDate).format('YYYY-MM'));
  const [corporativo, setCorporativo] = useState<boolean>(false);

  const [priorizationList, setPriorizationList] = useState<T.PriorizationItem[]>();

  useEffect(() => {
    const getShoppingsAndPortfolios = async () => {
      try {
        if (isCarteira) {
          const { data } = await api.get<T.Portfolio[]>(
            `/grupo-cobranca/grupo/buscar/shopping/portfolio?origens=ORACLE,SAP`,
          );
          setPortfolios(data.map(e => ({ value: e.shoppingIds.join(','), label: e.portfolioName })));
        }
        if (refresh) {
          setRefresh(false);
          await search();
        }
      } catch (e) {
        handleErrors(e);
      }
      setLoading(false);
    };
    getShoppingsAndPortfolios();
  }, []);

  const shoppingsOptions = useMemo(() => {
    if (profileShoppings) {
      return profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
    }
    return [];
  }, [profileShoppings]);

  const search = async () => {
    setLoading(true);
    try {
      const [year, month] = selectedDate.split('-');
      const { data } = await api.post<T.PrioritazionListResponse>(`/api/allos/v1/prioritization/past-due-balance`, {
        malls: selectedShopping ? [selectedShopping.value] : undefined,
        month,
        year,
        corporate: corporativo,
        portifolio: selectedPortfolio,
      });

      if (data.data.length === 0) {
        warning('Nenhum resultado encontrado');
      }
      setPriorizationList(data.data);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const formItems = () => {
    const inputs: React.ReactNode[] = [];
    if (isCarteira) {
      inputs.push(
        <SingleSelect placeholder="Carteira" state={[selectedPortfolio, setSelectedPortfolio]} options={portfolios} />,
      );
    } else {
      inputs.push(
        <SingleSelect
          placeholder="Shoppings"
          state={[selectedShopping, setSelectedShopping]}
          options={shoppingsOptions}
        />,
      );
    }
    inputs.push(
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '100%' }}>
        <label style={{ margin: 0, color: '#6D6D6D', fontSize: 14, fontWeight: 600 }}>Mês/Ano de Inadimplência</label>
        <TextField
          value={selectedDate}
          onChange={(e: any) => setSelectedDate(e.target.value)}
          variant="outlined"
          fullWidth
          size="small"
          type="month"
        />
      </div>,
    );
    inputs.push(
      <Checkbox label="Clientes Corporativos" checked={corporativo} onClick={() => setCorporativo(!corporativo)} />,
    );
    return inputs;
  };

  const isDisabled = useMemo(() => {
    if (selectedDate.length !== 7) return true;
    if (isCarteira && !selectedPortfolio) return true;
    if (!selectedShopping) return true;
    return false;
  }, [selectedDate, selectedPortfolio, selectedShopping, isCarteira]);

  const convertToDate = (mmYYYY: string): Date => {
    const [year, month] = mmYYYY.split('-');
    const monthNumber = parseInt(month, 10);
    const yearNumber = parseInt(year, 10);
    return new Date(yearNumber, monthNumber - 1);
  };

  return (
    <>
      <FullScreenLoading isEnabled={loading} />
      <Form
        columns={3}
        items={formItems()}
        submitButton={<Button onClick={search} disabled={isDisabled} text="Pesquisar" />}
      />
      {priorizationList && (
        <S.Content>
          <Table
            shopping={{ id: selectedShopping?.value as number, label: selectedShopping?.label }}
            selectedDate={convertToDate(selectedDate)}
            priorizationList={priorizationList}
          />
        </S.Content>
      )}
    </>
  );
};
