import React, { useEffect, useMemo, useRef, useState } from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';

import FileSaver from 'file-saver';
import * as S from '../styles';
import { Button, Input, PaginatedTable } from '../../../../_core/_components';
import api from '../../../../_core/api';
import { NumeroDocumentoItem, NumeroDocumentoResponse } from './types';
import { failure, success } from '../../../../_core/services/toast';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import Modal from './modal';
import { truncateString } from '../../../../_core/services/formaters';
import ConfirmationModal from '../../../../_core/_components/confirmation-modal';
import { useCache } from '../../../../_core/cache';
import { requests } from '../../../../requests';

// TODO : A paginação está bugada. ao trocar para pag. dois ela traz totalItens incorretamente
const PAGE_LIMIT = 999999;

const NumeroDocumento: React.FC = () => {
  const { response: responseEmpresas, isLoading: isLoadingEmpresas } = useCache({
    key: 'getEmpresas',
    fn: requests.get('getEmpresas'),
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [searchText, setSearchText] = useState('');
  const [itens, setItens] = useState<NumeroDocumentoItem[]>();
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<NumeroDocumentoItem | null>(null);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [page, setPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [deletionSelected, setDeletionSelected] = useState<NumeroDocumentoItem | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get<NumeroDocumentoResponse>('/config/excecoes/numero-documento', {
        params: {
          page,
          size: PAGE_LIMIT,
        },
      });
      const totalPages = Math.floor(data.pageInfo.totalItems / PAGE_LIMIT);
      setPageInfo({ ...data.pageInfo, totalPages });
      setItens(data.data);

      setLoading(false);
    } catch (e) {
      failure('Erro ao tentar carregar dados.');
      setLoading(false);
    }
  };

  const filteredItems = useMemo(() => {
    if (searchText) {
      const filtered = itens?.filter(reg => reg.numero.toUpperCase().includes(searchText.toUpperCase()));
      return filtered;
    }
    return itens;
  }, [searchText, itens]);

  const renderedData = useMemo(() => {
    if (filteredItems && filteredItems.length > 0) {
      return filteredItems.map(item => ({
        numero: item.numero,
        unidEcon: item.unidEcon,
        anoCompetencia: item.anoCompetencia,
        shoppings: (
          <S.BadgeContainer>
            <span>{item.shoppings.map(s => s.nome)[0]}</span>
            {item.shoppings.length > 1 && (
              <Tooltip
                title={item.shoppings
                  .slice(1)
                  .map(s => s.nome)
                  .join(', ')}
              >
                <S.Badge>+{item.shoppings.length - 1}</S.Badge>
              </Tooltip>
            )}
          </S.BadgeContainer>
        ),
        modulos: (
          <S.BadgeContainer>
            <span>{item.modulos.map(s => s.nome)[0]}</span>
            {item.modulos.length > 1 && (
              <Tooltip
                title={item.modulos
                  .slice(1)
                  .map(s => s.nome)
                  .join(', ')}
              >
                <S.Badge>+{item.modulos.length - 1}</S.Badge>
              </Tooltip>
            )}
          </S.BadgeContainer>
        ),
        observacao: (
          <Tooltip title={item.observacao}>
            <span>{truncateString(item.observacao, 20)}</span>
          </Tooltip>
        ),
        actions: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.id.toString()}
            onClick={(e: any) => {
              setAnchorEl(e.currentTarget);
              setSelectedItem(item);
            }}
          >
            <MoreVert />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [filteredItems]);

  const handleFinishedSaving = () => {
    setModalOpen(false);
    setSelectedItem(null);
    getData();
  };

  const handleEditItem = () => {
    setModalOpen(true);
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedItem(null);
  };

  const handleExcludeItem = () => {
    setAnchorEl(null);
    setSelectedItem(null);
    setDeletionSelected(selectedItem);
  };

  const excludeItem = async () => {
    try {
      await api.delete(`/config/excecoes/numero-documento/${deletionSelected?.id}`);
      success('O registro foi removido');
      setDeletionSelected(null);
      getData();
    } catch (e) {
      failure('Erro ao tentar excluir');
      setDeletionSelected(null);
    }
  };

  const exportar = async () => {
    try {
      setLoading(true);

      const response = await api.get(`/config/excecoes/numero-documento/download`, {
        responseType: 'arraybuffer',
      });
      const blob = new Blob([response.data], {
        type: 'text/csv',
      });
      FileSaver.saveAs(blob, 'Excecoes - Numero Documento');
      setLoading(false);
    } catch (e) {
      failure('Erro ao tentar fazer download do documento');
      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    // eslint-disable-next-line no-unused-expressions
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      try {
        await api.post(`/config/excecoes/numero-documento/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        success('Arquivo enviado com sucesso.');
      } catch (error) {
        failure('Erro ao enviar o arquivo:');
      }
      setLoading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <S.SubWrapper>
      <FullScreenLoading isEnabled={loading || isLoadingEmpresas} />
      <Modal
        onFinishSave={() => handleFinishedSaving()}
        open={modalOpen}
        onClose={() => handleCloseModal()}
        selectedRegister={selectedItem}
        empresas={responseEmpresas?.data ?? []}
      />

      <ConfirmationModal
        open={Boolean(deletionSelected)}
        text="Deseja excluir o item ?"
        handleCancel={() => setDeletionSelected(null)}
        handleOk={excludeItem}
      />
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleEditItem}>Editar</MenuItem>
        <MenuItem onClick={() => handleExcludeItem()}>Excluir</MenuItem>
      </Menu>
      <S.HeadingBar>
        <S.SearchBar>
          <Input placeholder="Número Documento" label="Buscar" state={[searchText, setSearchText]} />
          {/* <Button text="PESQUISAR" onClick={() => null} /> */}
        </S.SearchBar>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          classname="bordered"
          text="ADICIONAR ITEM"
          onClick={() => setModalOpen(true)}
        />
      </S.HeadingBar>
      {renderedData.length > 0 && (
        <S.TableContainer>
          <PaginatedTable
            columns={[
              { label: 'Número Documento', key: 'numero' },
              { label: 'Ano competência', key: 'anoCompetencia' },
              { label: 'Shoppings', key: 'shoppings' },
              { label: 'Observação', key: 'observacao' },
              { label: 'Módulos', key: 'modulos' },
              { label: 'Ações', key: 'actions' },
            ]}
            data={renderedData}
            pagination={pageInfo}
            onChangePage={pageNumber => setPage(pageNumber)}
          />
        </S.TableContainer>
      )}
      <div style={{ display: 'flex', gap: '1rem' }}>
        <Button text="Exportar" onClick={() => exportar()} />
        <Button onClick={() => handleButtonClick()} text="Upload" />
        <input type="file" ref={fileInputRef} style={{ display: 'none' }} accept=".csv" onChange={handleFileChange} />
      </div>
    </S.SubWrapper>
  );
};

export default NumeroDocumento;
