import React from 'react';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const NegociadoChip = withStyles(() =>
  createStyles({
    root: {
      color: '#00630A',
      backgroundColor: 'rgba(66,251,84,0.22)',
      width: '10em',
    },
  }),
)(Chip);

const VencidoChip = withStyles(() =>
  createStyles({
    root: {
      color: '#970000',
      backgroundColor: '#FFB9B9',
      width: '10em',
    },
  }),
)(Chip);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#fff',
      color: '#00959c',
      fontWeight: 'bold',
      border: '1px solid #E5E9EA',
      fontSize: 12,
    },
    body: {
      fontSize: 12,
    },
    stickyHeader: {
      top: '4em',
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#FBFBFB',
      '&:nth-of-type(odd)': {
        backgroundColor: '#E5E9EA',
      },

      '&:hover': {
        backgroundColor: '#daeedd',
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export type HistoricoItem = {
  negotiationAgreement: {
    id: number;
    agreementDate: string;
    contactDate: string;
    negotiationType: {
      id: number;
      description: string;
      creationDate: string;
      active: true;
    };
    observation: string;
    usuarioInclusao: string;
    dataInclusao: Date;
  };
  slipNumber: number[];
};

type Props = {
  historico?: HistoricoItem[];
};

const CustomizedTables: React.FC<Props> = ({ historico }) => {
  const classes = useStyles();

  if (historico && historico.length > 0) {
    return (
      <Table stickyHeader className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Boleto</StyledTableCell>
            <StyledTableCell>Data Contato</StyledTableCell>
            <StyledTableCell>Data Acordo</StyledTableCell>
            <StyledTableCell>Status da Negociação</StyledTableCell>
            <StyledTableCell>Usuário</StyledTableCell>
            <StyledTableCell>Observação</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historico?.map(row => (
            <StyledTableRow key={row.negotiationAgreement.id}>
              <StyledTableCell>{row.slipNumber?.join(', ')}</StyledTableCell>
              <StyledTableCell>{moment(row.negotiationAgreement.contactDate).format('DD/MM/YYYY')}</StyledTableCell>
              <StyledTableCell>{moment(row.negotiationAgreement.agreementDate).format('DD/MM/YYYY')}</StyledTableCell>
              <StyledTableCell>{row.negotiationAgreement.negotiationType?.description}</StyledTableCell>
              <StyledTableCell>{row.negotiationAgreement.usuarioInclusao}</StyledTableCell>
              <StyledTableCell>{row.negotiationAgreement.observation}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return <></>;
};

export default CustomizedTables;
