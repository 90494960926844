import styled from 'styled-components/macro';

export const Container = styled.div`
  > h2 {
    color: #7e9395;
    margin-bottom: 1rem;
    font-weight: 600;
  }
`;

export const Label = styled.p`
  color: #7e9395;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-right: 1rem;
`;

export const Actions = styled.div`
  display: flex;
  gap: 1rem;
`;

export const TableContainer = styled.table`
  width: 100%;
  background: white;
  margin-top: 1rem;
  padding: 0.6rem;
  border-radius: 0.6rem;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #c0c0c0;
    padding: 8px;
    text-align: left;
  }
  th {
    background-color: #f2f2f2;
    margin: 0;
  }
  th[colspan] {
    text-align: center;
  }
`;
