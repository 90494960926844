import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 10px;
`;
export const Title = styled.div`
  font-size: 1.8em;
  color: #1e1e1e;
  font-weight: 600;
`;
export const Form = styled.div`
  display: flex;
  gap: 4em;
`;

export const FormGroup = styled.div`
  flex: 1;
  .MuiSwitch-root {
    left: -12px;
  }
`;

export const Label = styled.div`
  color: #6d6d6d;
  font-size: 1.4em;
  font-weight: 600;
`;

export const ActionsWrapper = styled.div`
  margin-top: 4em;
  display: flex;
  justify-content: right;
`;
