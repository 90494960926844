import styled, { css } from 'styled-components';

type SubMenuTextProps = {
  active?: boolean;
};

export const IconStyle = {
  color: '#00959c',
  fontSize: 14,
  marginRight: 22,
};

export const ArrowStyle = {
  color: '#bababa',
  fontSize: 16,
};

export const MenuItem = styled.div`
  padding: 1rem 1.8rem;
  color: #00959c;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const MenuText = styled.div`
  flex: 1;
`;

export const SubMenuItem = styled.div`
  padding: 0.6rem 1.8rem;
  color: #8890b5;
  font-size: 0.6rem;
  cursor: pointer;
`;

export const SubMenuText = styled.div<SubMenuTextProps>`
  font-size: 0.9rem;
  margin-left: 2.2rem;

  ${({ active }) => css`
    ${active && {
      color: '#7ED6DA',
    }}
  `}
`;

export const Body = styled.div`
  padding: 1rem;
  background-color: white;
  border-radius: 4px;
  margin: 0px 8px 8px 8px;
  top: -0.5rem;
  position: relative;
`;
