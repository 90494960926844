import React from 'react';
import { RadioGroup as RadioGroupMaterial, FormControlLabel, Radio } from '@material-ui/core';
import { Option } from './types';

type Props = {
  label?: string;
  value: any;
  onChange: (value: string) => void;
  list: Option[];
};

export function RadioGroup({ label, value, onChange, list }: Props): JSX.Element {
  return (
    <RadioGroupMaterial aria-label={label} name={label} value={value} onChange={e => onChange(e.target.value)}>
      {list.map((item, i) => (
        <FormControlLabel value={item.value} control={<Radio color="primary" />} label={item.label} key={i} />
      ))}
    </RadioGroupMaterial>
  );
}
