import React, { useEffect, useState } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import { Button, Option, SingleSelect } from '../../../../../_core/_components';

import * as S from './styles';
import api from '../../../../../_core/api';
import { AjuizamentoDetalhes } from '../../types';
import { Status } from '../../../cadastro/status-acao';
import { TipoCredito } from '../../../cadastro/tipo-credito';

export type ControleDeAcoesData = {
  statusAcao?: Option;
  tipoCredito?: Option;
};

type Props = {
  ajuizamentoDetalhes: AjuizamentoDetalhes;
  onChangeControleDeAcoes: (cad: ControleDeAcoesData) => void;
  onSave: () => void;
  disableSelects: boolean;
};

const ControleDeAcoes: React.FC<Props> = ({ ajuizamentoDetalhes, onChangeControleDeAcoes, onSave, disableSelects }) => {
  const [statusAcao, setStatusAcao] = useState<Option | undefined>();
  const [statusAcaoOptions, setStatusAcaoOptions] = useState<Option[]>();
  const [tipoCredito, setTipoCredito] = useState<Option | undefined>();
  const [tipoCreditoOptions, setTipoCreditoOptions] = useState<Option[]>();

  useEffect(() => {
    setStatusAcao(statusAcaoOptions?.find(s => s.value === ajuizamentoDetalhes.idStatusAcao));
    setTipoCredito(tipoCreditoOptions?.find(s => s.value === ajuizamentoDetalhes.idEspecieCredito));
  }, [ajuizamentoDetalhes, statusAcaoOptions, tipoCreditoOptions]);

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    onChangeControleDeAcoes({
      statusAcao,
      tipoCredito,
    });
  }, [statusAcao, tipoCredito]);

  const getInitialData = async () => {
    // status da Ação
    const { data: dataStatusAcao } = await api.get<Status[]>('/setup/rules/status-acao');
    setStatusAcaoOptions(
      dataStatusAcao.map(status => ({
        value: status.idAjzStatusAcao,
        label: status.nome,
      })),
    );

    // Tipo de credito
    const { data: dataTipoCredito } = await api.get<TipoCredito[]>('/setup/rules/especie-credito');
    setTipoCreditoOptions(
      dataTipoCredito.map(tc => ({
        value: tc.idAjzEspecieCredito,
        label: tc.nome,
      })),
    );
  };

  return (
    <S.Wrapper>
      <S.Form>
        <S.FormGroup>
          <S.Label>Status Da Ação</S.Label>
          <SingleSelect
            disabled={disableSelects}
            state={[statusAcao, setStatusAcao]}
            options={statusAcaoOptions || []}
          />
        </S.FormGroup>
        <S.FormGroup>
          <S.Label>Tipo de Crédito</S.Label>
          <SingleSelect
            disabled={disableSelects}
            state={[tipoCredito, setTipoCredito]}
            options={tipoCreditoOptions || []}
          />
        </S.FormGroup>
      </S.Form>
      <S.ActionsWrapper>
        <Button onClick={() => onSave()} startIcon={<SaveIcon />} text="Salvar" />,
      </S.ActionsWrapper>
    </S.Wrapper>
  );
};

export default ControleDeAcoes;
