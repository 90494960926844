import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import Table, { HistoricoItem } from './table';
import * as S from './styles';
import { PriorizationItem } from '../../../priorizacao/types';
import api from '../../../../../_core/api';
import { failure } from '../../../../../_core/services/toast';

type Props = {
  customerPriorizationData: PriorizationItem & { selectedDate: Date };
};

const HistoricoNegociacao: React.FC<Props> = ({ customerPriorizationData }) => {
  const [loading, setLoading] = useState(false);
  const [listaHistorico, setListaHistorico] = useState<HistoricoItem[]>();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await api.get<HistoricoItem[]>(
        `/api/allos/v1/prioritization/agreement-negotiation-slip/history/${customerPriorizationData.clientId}`,
      );
      setLoading(false);
      setListaHistorico(data);
    } catch (e) {
      setLoading(false);
      failure('Erro ao tentar recuerar dados, entre em contato com a equipe técnica');
    }
  };

  return (
    <S.Wrapper>
      {loading ? (
        <S.LoadingWrapper>
          <CircularProgress />
        </S.LoadingWrapper>
      ) : (
        <Table historico={listaHistorico} />
      )}
    </S.Wrapper>
  );
};

export default HistoricoNegociacao;
