import React, { useEffect, useRef, useState } from 'react';
import { CloudUpload } from '@material-ui/icons';
import moment from 'moment';
import api from '../../../_core/api';
import icon from '../../../assets/cloud-computing.svg';
import { Container, Content, ImportArea, InputGroup } from './styles';
import { handleErrors } from '../../../_core/services/toast';
import { SingleSelect, Option, Input, Button } from '../../../_core/_components';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { useCache } from '../../../_core/cache';
import { requests } from '../../../requests';

export const ImportarDadosOracle: React.FC = () => {
  const { response: responseFiltersShoppings, isLoading: isLoadingFiltersShoppings } = useCache({
    key: 'getAllShoppings',
    fn: requests.get('getAllShoppings'),
  });

  const [loading, setLoading] = useState(true);
  const [shoppings, setShoppings] = useState<Option[]>([]);
  const [oracleShopping, setOracleShopping] = useState<Option>();
  const [file, setFile] = useState<File>();
  const refXls = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (responseFiltersShoppings !== undefined) {
      const arrShoppingsOptions = responseFiltersShoppings.data.map((e: any) => ({ value: e.id, label: e.nome }));
      setShoppings(arrShoppingsOptions);
    }
  }, [responseFiltersShoppings]);

  const sendFormOracle = async () => {
    const fileInput = document.getElementById('inputOracleFile') as HTMLInputElement;
    if (fileInput.files![0] == null || oracleShopping === undefined) return;
    setLoading(true);
    try {
      const dateForm = moment().format('MM/YYYY');
      const formData = new FormData();
      formData.append('file', fileInput.files![0]);
      formData.append('dateReference', dateForm);
      formData.append('spreadSheetType', 'ORACLE');
      formData.append('shopping', oracleShopping.label);
      await api.post('/producer/updload/spreadsheet', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const blob = e.target.files ? e.target.files[0] : undefined;
    if (blob === undefined) return;
    setFile(blob);
  };

  return (
    <Container>
      <FullScreenLoading isEnabled={loading || isLoadingFiltersShoppings} />
      <Content />
      <Content>
        <h1>Importar dados da planilha com acordos Oracle</h1>
        <ImportArea onClick={() => refXls.current?.click()}>
          <h2>Buscar arquivos</h2>
          <img src={icon} alt="" />
          <InputGroup onClick={e => e.stopPropagation()}>
            <SingleSelect state={[oracleShopping, setOracleShopping]} options={shoppings} placeholder="Shoppings" />
            <Input
              label="Selecione o arquivo"
              state={[file?.name ?? 'Escolher arquivo', () => null]}
              disabled
              style={{ backgroundColor: 'white' }}
              startIcon={<CloudUpload />}
            />
            <input id="inputFileXls" type="file" hidden onChange={onFileChange} ref={refXls} />
          </InputGroup>
        </ImportArea>
        <Button text="Importar" onClick={sendFormOracle} />
      </Content>
      <Content />
    </Container>
  );
};
