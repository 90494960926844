import { toast, ToastContainer as Container } from 'react-toastify';

export type HttpError = {
  response: {
    data: {
      message: string;
    };
    status: number;
  };
};

export const ToastContainer = Container;

export const handleErrors = (exception: unknown): void => {
  if ((exception as HttpError).response?.data?.message) {
    failure((exception as HttpError).response.data.message);
    return;
  }

  if (exception instanceof Error) {
    const msg = exception.message ? exception.message : 'Erro interno';
    failure(msg);
  }
};

export const failure = (text: string): void => {
  toast.error(text);
};

export const warning = (text: string): void => {
  toast.warning(text);
};

export const success = (text: string): void => {
  toast.success(text);
};
