import React, { useEffect, useMemo, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { MoreVert, Save } from '@material-ui/icons';
import * as S from '../styles';
import { Button, Input, PaginatedTable, Modal } from '../../../../_core/_components';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { ModalContent } from './modal-content';
import api from '../../../../_core/api';
import { TipoMovimento as TipoMovimentoType, TipoMovimentoDTO } from './types';
import { handleErrors } from '../../../../_core/services/toast';
import { useCache } from '../../../../_core/cache';
import { requests } from '../../../../requests';

export function TipoMovimento(): JSX.Element {
  const { response, isLoading } = useCache({
    key: 'getTipoConta',
    fn: requests.get('getTipoConta'),
  });

  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [registerList, setRegisterList] = useState<TipoMovimentoDTO>();
  const [tipoMovimento, setTipoMovimento] = useState<TipoMovimentoType>();
  const [page, setPage] = useState(1);

  const tipoContaOptions = useMemo(() => {
    if (response) {
      return response.data.map((item: any) => ({
        value: item.id,
        label: item.codigo,
      }));
    }
    return [];
  }, [response]);

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get<TipoMovimentoDTO>(`/config/inadimplencia/tipo-movimento?page=${page}`);
      setRegisterList(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const filteredItems = useMemo(() => {
    if (searchText) {
      const filtered = registerList?.data.filter(reg => reg.codigo.toUpperCase().includes(searchText.toUpperCase()));
      return filtered ?? [];
    }
    return registerList?.data ?? [];
  }, [searchText, registerList]);

  const renderedData = useMemo(() => {
    if (filteredItems.length > 0) {
      return filteredItems.map(item => ({
        ...item,
        actions: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.id!.toString()}
            onClick={() => setTipoMovimento(item)}
          >
            <MoreVert />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [filteredItems]);

  const addItem = () =>
    setTipoMovimento({
      codigo: '',
      ativo: true,
      idTipoConta: 0,
      tipoConta: '',
    });

  const saveItem = async () => {
    setLoading(true);
    try {
      if (tipoMovimento!.id) {
        await api.put(`/config/inadimplencia/tipo-movimento/${tipoMovimento!.id}`, tipoMovimento);
      } else {
        await api.post('/config/inadimplencia/tipo-movimento', tipoMovimento);
      }
      const { data } = await api.get<TipoMovimentoDTO>('/config/inadimplencia/tipo-movimento?page=1');
      setTipoMovimento(undefined);
      setRegisterList(data);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  return (
    <S.DataContainer>
      <FullScreenLoading isEnabled={loading || isLoading} />
      {tipoMovimento && (
        <Modal
          title="Adicionar/Editar Tipo Movimento"
          onClose={() => setTipoMovimento(undefined)}
          content={<ModalContent state={[tipoMovimento, setTipoMovimento]} tipoContaOptions={tipoContaOptions} />}
          actionItems={[<Button onClick={saveItem} text="Salvar" startIcon={<Save />} />]}
        />
      )}
      <S.TopBar>
        <S.SearchBar>
          <S.SearchField>
            <Input label="Buscar" state={[searchText, setSearchText]} />
          </S.SearchField>
        </S.SearchBar>
        <Button text="Criar novo" onClick={addItem} />
      </S.TopBar>
      {renderedData.length > 0 && (
        <PaginatedTable
          columns={[
            { label: 'Id', key: 'id' },
            { label: 'Código', key: 'codigo' },
            { label: 'Tipo conta', key: 'tipoConta' },
            { label: 'Editar', key: 'actions' },
          ]}
          data={renderedData}
          pagination={registerList!.pageInfo}
          onChangePage={setPage}
        />
      )}
    </S.DataContainer>
  );
}
