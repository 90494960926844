import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as S from './styles';
import TextField from '../../../../../_core/_components/text-field';
import Button from '../button';
import { Switch } from '../../../../../_core/_components/switch';
import { OptionTypes } from '../..';
import api from '../../../../../_core/api';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  type: OptionTypes | undefined;
  onUpdate: () => void;
};

const DetailsModal: React.FC<Props> = ({ isOpen, onClose, type, onUpdate }) => {
  const [description, setDescription] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);

  const getTitle = (param: Props['type']) => {
    switch (param) {
      case 'expecativa':
        return 'EXPECTATIVA DE RECEBIMENTO';
      case 'tipoNeg':
        return 'ESTÁGIOS DE NEGOCIAÇÃO';
      case 'motivoImprovavel':
        return 'MOTIVO IMPROVÁVEL RECEBIMENTO';
      default:
        return '';
    }
  };

  const saveOption = async () => {
    let url = '';
    switch (type) {
      case 'expecativa':
        url = '/api/allos/v1/prioritization/expectation-receipt';
        break;
      case 'tipoNeg':
        url = '/api/allos/v1/prioritization/type-negotiation';
        break;
      case 'motivoImprovavel':
        url = '/api/allos/v1/prioritization/unlikely-reason-receipt';
        break;
      default:
        break;
    }

    setLoading(true);
    await api.post(url, description, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    setDescription('');
    setLoading(false);
    onUpdate();
  };

  return (
    <Dialog maxWidth="sm" open={isOpen} onClose={onClose} aria-labelledby="max-width-dialog-title">
      {isLoading && <LinearProgress />}
      <S.Wrapper>
        <S.Title>{getTitle(type)}</S.Title>
        <S.InputGroup>
          <S.Label>Descrição</S.Label>
          <TextField size="small" state={[description, v => setDescription(v)]} variant="outlined" fullWidth />
        </S.InputGroup>

        <S.InputGroup>
          <S.Label>Status</S.Label>
          <Switch state={[status, setStatus]} />
        </S.InputGroup>

        <S.Actions>
          <Button disabled={isLoading} onClick={() => saveOption()} size="small" text="Salvar" />
        </S.Actions>
      </S.Wrapper>
    </Dialog>
  );
};

export default DetailsModal;
