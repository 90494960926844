import React, { useEffect, useMemo, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import * as S from '../styles';
import { Input, Table } from '../../../../_core/_components';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import api from '../../../../_core/api';
import { handleErrors } from '../../../../_core/services/toast';
import { Modal } from './modal';
import { WorkflowDTO, Workflow as WorkflowType } from './types';

export function Aging(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState<WorkflowType[]>([]);
  const [selectedData, setSelectedData] = useState<WorkflowType>();

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get<WorkflowDTO>(`/configuracao/serasa/parametro/buscar`);
      setData(data.content);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const renderedData = useMemo(() => {
    if (data.length > 0) {
      return data.map(item => ({
        ...item,
        actions: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.idParametro!.toString()}
            onClick={() => setSelectedData(item)}
          >
            <MoreVert />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [data]);

  const onSave = async (payload: any) => {
    setLoading(true);
    try {
      await api.post('/configuracao/serasa/parametro/salvar', payload);
      setSelectedData(undefined);
      const { data } = await api.get<WorkflowDTO>(`/configuracao/serasa/parametro/buscar`);
      setData(data.content);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  return (
    <S.DataContainer>
      {selectedData && <Modal data={selectedData} onClose={() => setSelectedData(undefined)} onSave={onSave} />}
      <FullScreenLoading isEnabled={loading} />
      <S.TopBar>
        <S.SearchBar>
          <S.SearchField>
            <Input label="Buscar" state={[searchText, setSearchText]} />
          </S.SearchField>
        </S.SearchBar>
      </S.TopBar>
      {renderedData.length > 0 && (
        <>
          <Table
            columns={[
              { label: 'Código parâmetro', key: 'codigoParametro' },
              { label: 'Nome parâmetro', key: 'nomeParametro' },
              { label: 'Categoria', key: 'categoria' },
              { label: 'Valor', key: 'valorParametro' },
              { label: 'Editar', key: 'actions' },
            ]}
            data={renderedData}
          />
        </>
      )}
    </S.DataContainer>
  );
}
