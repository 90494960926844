import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Person } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { AuthContext } from '../../../_main/contexts/auth';
import ConfirmationModal from '../../../_core/_components/confirmation-modal';
import { Container, Section } from './styles';
import api from '../../../_core/api';
import { Boleto, BoletoShoppingResponse, Fiador, Values } from './types';
import Table from '../../../_core/_components/table';
import { handleErrors, success, warning } from '../../../_core/services/toast';
import { downloadXls } from '../../../_core/services/download';
import { Button, Checkbox, Form, Input, Option, Select, Modal } from '../../../_core/_components';
import { mascaraNumerosVirgula } from '../../../_core/masks';
import { ModalContent } from './modal-content';
import { useCache } from '../../../_core/cache';
import { requests } from '../../../requests';

export const BaixaManual: React.FC = () => {
  const { id, profile, shoppings: profileShoppings } = useContext(AuthContext);

  const { response: responseJustificativas, isLoading: isLoadingJustificativas } = useCache({
    key: 'getJustificativas',
    fn: requests.get('getJustificativas'),
  });

  const [fiadores, setFiadores] = useState<Fiador[]>([]);
  const [values, setValues] = useState<Values>();
  const [justificativas, setJustificativas] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [data, setData] = useState<Boleto[]>([]);
  const [selectedData, setSelectedData] = useState<Boleto[]>([]);
  const [shoppingList, setShoppingList] = useState<Option[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [numeroBoleto, setNumeroBoleto] = useState<string>('');
  const [luc, setLuc] = useState<string>('');
  const [nomeFantasia, setNomeFantasia] = useState<string>('');

  useEffect(() => {
    if (responseJustificativas !== undefined) {
      const listJustificativas: any[] = [];
      responseJustificativas.data.content.map((item: any) => {
        listJustificativas.push({
          value: item.idSerasaJustificatica,
          label: item.txtJustificativa,
          campoObrigatorio: item.campoObrigatorio,
        });
        return null;
      });
      listJustificativas.unshift({
        value: 999,
        label: 'Selecione',
        campoObrigatorio: '',
      });
      setJustificativas(listJustificativas);
    }
  }, [responseJustificativas]);

  useEffect(() => {
    if (profileShoppings) {
      const listShopping: Option[] = profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
      listShopping.sort((item1: Option, item2: Option) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppingList(listShopping);
      setSelectedShoppings(listShopping);
    }
  }, [profileShoppings]);

  const getFilters = async () => {
    try {
      setSelectedData([]);
      setData([]);
      setLoading(true);
      const response = await api.post<BoletoShoppingResponse>(
        '/workflow/serasa/serasa-manual/buscar/boletos-shopping',
        {
          idShopping: selectedShoppings.map(item => item.value),
          idUsuario: id,
          numBoletos: numeroBoleto.length > 0 ? numeroBoleto.split(',') : undefined,
          tipo: 'BAIXA_MANUAL',
          marca: nomeFantasia.length > 0 ? nomeFantasia : undefined,
          luc: luc.length > 0 ? luc : undefined,
        },
      );
      if (_.isEmpty(response.data.content)) {
        warning('Não obteve resultados.');
        return;
      }
      setData(response.data.content);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const submitBaixaManual = async () => {
    setIsOpenConfirmation(false);
    setLoading(true);
    try {
      const boletos = selectedData.map((item, i) => ({
        idBoleto: item.idBoleto,
        loja: {
          idSerasaLoja: item.serasaLoja.idSerasaLoja,
          negativar: true,
        },
        fiadores: item.fiadores.map((e: any) => ({
          idSerasaBoletoFiador: e.idSerasaBoletoFiador,
          negativar: true,
        })),
      }));
      const response = await api.post('/workflow/serasa/serasa-manual/baixa-manual', {
        idUsuario: id,
        boletos,
      });
      success(response.data.message);
      getFilters();
    } catch (error) {
      setLoading(false);
      handleErrors(error);
    }
  };

  const clickRow = (element: any) => {
    const boleto = data.find(e => e.idBoleto === element.idBoleto)!;
    const isSelected = checkBoletoSelection(boleto);

    if (!isSelected) {
      setSelectedData([...selectedData, boleto]);
    } else {
      setSelectedData(selectedData.filter(s => s.idBoleto !== boleto.idBoleto));
    }
  };

  const checkBoletoSelection = (boleto: Boleto) => {
    return !!selectedData.find(s => boleto.idBoleto === s.idBoleto);
  };

  const findIdBoleto = (idBoleto: number) => {
    const boleto = data.find(e => e.idBoleto === idBoleto);
    if (boleto) {
      setValues({
        id: boleto.idBoleto,
        justificativa: boleto.serasaJustificatica?.idSerasaJustificatica ?? 999,
        observacao: boleto.txtObservacao ?? '',
        nrChamado: boleto.numChamado ?? '',
        anexo: boleto.nomeAnexo ?? '',
      });
    }
  };

  const renderSubItems = (item: Boleto) => {
    const arr = [];
    if (item.serasaJustificatica?.txtJustificativa) {
      arr.push(<b>Justificativa: </b>);
      arr.push(item.serasaJustificatica.txtJustificativa);
    }
    if (item.numChamado) {
      arr.push(<b>Nr. do chamado: </b>);
      arr.push(item.numChamado);
    }
    if (item.txtObservacao) {
      arr.push(<b>Observação: </b>);
      arr.push(item.txtObservacao);
    }
    return arr.length > 0 ? arr : undefined;
  };

  const renderedData = useMemo(() => {
    if (data.length > 0) {
      const fiadoresOptions: Option[][] = [];
      data.forEach(item => {
        fiadoresOptions.push(
          item.fiadores.map((fiador: any) => ({
            value: fiador.cpfcnpjFiador,
            label: fiador.nomeFiador,
          })),
        );
      });
      return data.map((item, i) => ({
        toggle: <Checkbox checked={checkBoletoSelection(item)} onClick={() => clickRow(item)} />,
        idBoleto: item.idBoleto,
        nmShopping: item.nmShopping,
        nomFantasia: item.nomFantasia,
        luc: item.luc,
        datVencimento: item.datVencimento,
        numBoleto: item.numBoleto,
        valTotalVencido: item.valTotalVencido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        fiadores: item.fiadores.length ? (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            onClick={() => setFiadores(item.fiadores)}
          >
            <Person />
          </IconButton>
        ) : null,
        justificativa: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            onClick={() => findIdBoleto(item.idBoleto)}
          >
            <MoreIcon />
          </IconButton>
        ),
        subItems: renderSubItems(item),
      }));
    }
    return [];
  }, [data, selectedData]);

  const bToggleAll = useMemo(() => {
    const allBoletosIds = data.map(e => e.idBoleto);
    const selectedIds = selectedData.map(e => e.idBoleto);
    return allBoletosIds.every(e => selectedIds.includes(e));
  }, [selectedData, data]);

  const onToggleAll = () => {
    if (bToggleAll) {
      setSelectedData([]);
    } else {
      setSelectedData(data);
    }
  };

  const download = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        `/workflow/serasa/serasa-manual/download/boletos-shopping`,
        {
          idShopping: selectedShoppings.map(item => item.value),
          idUsuario: id,
          numBoletos: numeroBoleto.length > 0 ? numeroBoleto.split(',') : undefined,
          tipo: 'BAIXA_MANUAL',
          marca: nomeFantasia.length > 0 ? nomeFantasia : undefined,
          luc: luc.length > 0 ? luc : undefined,
        },
        {
          responseType: 'arraybuffer',
        },
      );
      downloadXls(response);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onModalSubmit = async () => {
    setLoading(true);
    if (values === undefined) return;
    try {
      if (values.file) {
        const formData = new FormData();
        formData.append('file', values.file);
        formData.append('idBoleto', values.id.toString());
        formData.append('idJustificativa', values.justificativa.toString());
        formData.append('idUsuario', id.toString());
        formData.append('numChamado', values.nrChamado);
        formData.append('txtObservacao', values.observacao);
        await api.post('/workflow/serasa/serasa-upload/historico/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        const payload = {
          idBoleto: values.id,
          idJustificativa: values.justificativa,
          idUsuario: id,
          numChamado: values.nrChamado,
          txtObservacao: values.observacao,
        };
        await api.post('/workflow/serasa/serasa-workflow/salvar-historico', payload);
      }
      setValues(undefined);
      getFilters();
      success('Salvo com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const limparObservacoes = async () => {
    setLoading(true);
    setValues(undefined);
    try {
      await api.post('/workflow/serasa/serasa-workflow/historico/limpar', {
        idBoleto: values!.id,
        idUsuario: id,
      });
      getFilters();
      success('Submetido com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const modalContentFiador = useMemo(() => {
    const arrayInput: React.ReactNode[] = [];
    fiadores.forEach(fiador => {
      arrayInput.push(<Input label="Nome" state={[fiador.nomeFiador ?? '', () => null]} disabled />);
      arrayInput.push(<Input label="CPF/CNPJ" state={[fiador.cpfcnpjFiador ?? '', () => null]} disabled />);
      arrayInput.push(<Input label="Endereço" state={[fiador?.endereco ?? '', () => null]} disabled />);
    });
    return <Form items={arrayInput} />;
  }, [fiadores]);

  const isValid = useMemo(() => {
    if (values === undefined) return false;
    const { justificativa, nrChamado, file } = values;
    if (justificativa && justificativa !== 999) {
      const { campoObrigatorio } = justificativas.find((e: any) => e.value === justificativa);
      switch (campoObrigatorio) {
        case 'NAO_OBRIGATORIO':
          return true && justificativa;
        case 'CHAMADO':
          return !!nrChamado;
        case 'CHAMADO_OU_ANEXO':
          return !!nrChamado || file !== undefined;
        case 'ANEXO':
          return file !== undefined;
        default:
          return true && justificativa;
      }
    }
    return true;
  }, [values]);

  return (
    <>
      <FullScreenLoading isEnabled={loading || isLoadingJustificativas} />
      <Container>
        <Form
          items={[
            <Select
              state={[selectedShoppings, setSelectedShoppings]}
              options={shoppingList}
              disabled={profile.includes('SHOPPING')}
              placeholder="Shoppings"
            />,
            <Input label="Nome fantasia" state={[nomeFantasia, setNomeFantasia]} />,
            <Input label="LUC" state={[luc, setLuc]} />,
            <Input label="Número do boleto" state={[numeroBoleto, setNumeroBoleto]} pattern={mascaraNumerosVirgula} />,
          ]}
          submitButton={<Button onClick={getFilters} text="Pesquisar" />}
        />
        {renderedData.length > 0 && (
          <>
            <Table
              columns={[
                { label: <Checkbox type="secondary" checked={bToggleAll} onClick={onToggleAll} />, key: 'toggle' },
                { label: 'Nome do Shopping', key: 'nmShopping', orderable: true },
                { label: 'Nome fantasia', key: 'nomFantasia', orderable: true },
                { label: 'LUC', key: 'luc', orderable: true },
                { label: 'Vencimento do boleto', key: 'datVencimento', orderable: true },
                { label: 'Número do boleto', key: 'numBoleto', orderable: true },
                { label: 'Valor do Boleto', key: 'valTotalVencido', orderable: true },
                { label: 'Fiadores', key: 'fiadores' },
                { label: 'Justificativa', key: 'justificativa' },
              ]}
              data={renderedData}
            />
            <Section>
              <Button
                onClick={() => setIsOpenConfirmation(true)}
                text="SUBMETER RECOMENDAÇÃO"
                disabled={selectedData.length === 0}
              />
              <Button text="Exportar" onClick={download} />
            </Section>
          </>
        )}
      </Container>
      <ConfirmationModal
        open={isOpenConfirmation}
        text="Deseja submeter os itens selecionados?"
        handleCancel={() => setIsOpenConfirmation(false)}
        handleOk={submitBaixaManual}
      />
      {values && (
        <Modal
          title="Justificativa"
          onClose={() => setValues(undefined)}
          content={<ModalContent valuesState={[values, setValues]} justificativas={justificativas} />}
          actionItems={[
            <Button text="Limpar justificativa" onClick={limparObservacoes} />,
            <Button text="Salvar" onClick={onModalSubmit} disabled={!isValid} />,
          ]}
        />
      )}
      {fiadores.length > 0 && (
        <Modal title="Detalhes do Fiador" onClose={() => setFiadores([])} content={modalContentFiador} />
      )}
    </>
  );
};
