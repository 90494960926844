import React, { useState } from 'react';
import moment from 'moment';
import SaveIcon from '@material-ui/icons/Save';
import { Modal } from '../../../../../../_core/_components';
import Button from '../../../../../cobranca/components/button';
import TextField from '../../../../../../_core/_components/text-field';
import * as S from './styles';

type Props = {
  onClose: () => void;
  onSubmitDesistencia: (desc: string, numeroChamado: string) => void;
};

const Desistencia: React.FC<Props> = ({ onClose, onSubmitDesistencia }) => {
  const [descricao, setDescricao] = useState('');
  const [numeroChamado, setNumeroChamado] = useState('');

  const content = (
    <S.Wrapper>
      <S.Form>
        <S.FormGroup>
          <S.FormLabel>Data da Desistência</S.FormLabel>
          <S.Text>{moment().format('DD/MM/YYYY')}</S.Text>
        </S.FormGroup>
        <S.FormGroup>
          <S.FormLabel>Número do Chamado</S.FormLabel>
          <TextField size="small" state={[numeroChamado, v => setNumeroChamado(v)]} variant="outlined" fullWidth />
        </S.FormGroup>
        <S.FormGroup>
          <S.FormLabel>Descrição do motivo</S.FormLabel>
          <S.TextBox value={descricao} onChange={e => setDescricao(e.target.value)} />
        </S.FormGroup>
      </S.Form>
    </S.Wrapper>
  );
  return (
    <Modal
      size="lg"
      title="Desistência da Ação"
      onClose={onClose}
      content={content}
      actionItems={[
        <Button onClick={() => onSubmitDesistencia(descricao, numeroChamado)} startIcon={<SaveIcon />} text="Salvar" />,
      ]}
    />
  );
};

export default Desistencia;
