import React, { useState } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import api from '../../../../_core/api';
import { Action } from './index';
import { handleErrors, success } from '../../../../_core/services/toast';
import { Button, Form, Input } from '../../../../_core/_components';

type Props = {
  open: boolean;
  onClose: () => void;
  onRequestUpdate: () => void;
};

const Modal: React.FC<Props> = ({ open, onClose, onRequestUpdate }: Props) => {
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState<string>('');
  const [desc, setDesc] = useState<string>('');
  const [cod, setCod] = useState<string>('');

  const save = async () => {
    setLoading(true);
    try {
      const payload = {
        flagAtivo: true,
        nomTipoAcao: action,
        codigo: cod,
        nomeAr: desc,
      };

      await api.post('/setup/rules/tipo-acao', payload);
      success('Salvo com sucesso.');
      onRequestUpdate();
      onClose();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Adicionar / Alterar
        </span>
        <Grid>
          <Form
            items={[
              <Input label="Nome Tipo Ação" state={[action, setAction]} />,
              <Input label="Descrição" state={[desc, setDesc]} />,
              <Input label="Código" state={[cod, setCod]} />,
            ]}
            submitButton={<Button onClick={save} text="Salvar" disabled={loading || !action} />}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
