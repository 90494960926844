import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import * as S from './styles';
import Card from './card';
import { PriorizationItem, ShoppingEntity } from '../../../priorizacao/types';
import api from '../../../../../_core/api';
import { failure } from '../../../../../_core/services/toast';

type Props = {
  customerPriorizationData: PriorizationItem & {
    selectedDate: Date;
    shopping: ShoppingEntity;
  };
};

export type InadimplenciaItem = {
  monthYear: string;
  luc: string;
  totalInvoiced: number;
  rentsInvoiced: number;
  commercialsInvoiced: number;
  condominiumInvoiced: number;
  fundInvoiced: number;
  confessionInvoiced: number;
  totalDefaultMonth: number;
  shoppingDefaultPercentage: number;
  rentsDefault: number;
  commercialsDefault: number;
  condominiumDefault: number;
  fundDefault: number;
  confessionDefault: number;
};

const Inadimplencia: React.FC<Props> = ({ customerPriorizationData }) => {
  const [loading, setLoading] = useState(false);
  const [inadItems, setInadItems] = useState<InadimplenciaItem[]>();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await api.get<InadimplenciaItem[]>(
        `/api/allos/v1/prioritization/default/client/${customerPriorizationData.clientId}/month/${
          moment(customerPriorizationData.selectedDate).month() + 1
        }/year/${moment(customerPriorizationData.selectedDate).year()}/unidEcon/${customerPriorizationData.unitEcon}`,
      );
      setInadItems(data);
      setLoading(false);
    } catch (e) {
      failure('Erro ao tentar capturar dados');
      setLoading(false);
    }
  };

  return (
    <S.Wrapper>
      <S.CardContainer>
        {loading && (
          <S.LoadingWrapper>
            <CircularProgress />
          </S.LoadingWrapper>
        )}

        {!loading && inadItems?.map(i => <Card item={i} />)}
      </S.CardContainer>
    </S.Wrapper>
  );
};

export default Inadimplencia;
