import React, { useEffect, useMemo, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { handleErrors, success } from '../../../../_core/services/toast';
import * as S from '../styles';
import api from '../../../../_core/api';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { Button, Checkbox, Form, Input, Modal, Table } from '../../../../_core/_components';

export function TipoDocumento(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [registerList, setRegisterList] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRegister, setSelectedRegister] = useState<any>();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get<any[]>(`/setup/rules/documentos`);
      setRegisterList(data);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const filteredItems = useMemo(() => {
    if (searchText) {
      const filtered = registerList.filter(reg => reg.codTipoDivida.toUpperCase().includes(searchText.toUpperCase()));
      return filtered;
    }
    return registerList;
  }, [searchText, registerList]);

  const renderedData = useMemo(() => {
    if (filteredItems.length > 0) {
      return filteredItems.map(item => ({
        ...item,
        flagAtivoString: item.flagAtivo ? 'Sim' : 'Não',
        editar: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.idAjzTipoDocumento.toString()}
            onClick={() => setSelectedRegister(item)}
          >
            <Edit />
          </IconButton>
        ),
        excluir: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.idAjzTipoDocumento.toString()}
            onClick={() => setSelectedRegister(item)}
          >
            <Delete />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [filteredItems]);

  const onSave = async () => {
    setLoading(true);
    try {
      if (selectedRegister?.idAjzTipoDocumento) {
        await api.put(`/setup/rules/documentos/${selectedRegister!.idAjzTipoDocumento}`, selectedRegister);
      } else {
        await api.post('/setup/rules/documentos', selectedRegister);
      }
      setSelectedRegister(null);
      await getData();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onRemove = async () => {
    try {
      await api.delete(`/setup/rules/documentos/${selectedRegister?.idAjzTipoDocumento}`);
      success('O registro foi removido');
      await getData();
      setSelectedRegister(null);
    } catch (e) {
      handleErrors(e);
    }
    setSelectedRegister(null);
  };

  const modalContent = selectedRegister && (
    <Form
      items={[
        <Input
          state={[
            selectedRegister.codDocumento,
            value => setSelectedRegister({ ...selectedRegister, codDocumento: value }),
          ]}
          label="Código"
        />,
        <Input
          state={[selectedRegister.prefixo, value => setSelectedRegister({ ...selectedRegister, prefixo: value })]}
          label="Prefixo"
        />,
        <Checkbox
          label="Ativo"
          checked={selectedRegister.flagAtivo}
          onClick={() => setSelectedRegister({ ...selectedRegister, flagAtivo: !selectedRegister.flagAtivo })}
        />,
      ]}
    />
  );

  return (
    <S.DataContainer>
      <FullScreenLoading isEnabled={loading} />
      {selectedRegister && (
        <Modal
          title="Criar/Editar Tipo de Movimento"
          onClose={() => setSelectedRegister(undefined)}
          content={modalContent}
          actionItems={[<Button text="Salvar" onClick={onSave} />]}
        />
      )}
      <S.TopBar>
        <S.SearchBar>
          <S.SearchField>
            <Input label="Buscar" state={[searchText, setSearchText]} />
          </S.SearchField>
        </S.SearchBar>
        <Button
          text="Criar novo"
          onClick={() =>
            setSelectedRegister({
              codMovimento: '',
              createdBy: '',
              modifiedBy: '',
              flagAtivo: true,
            })
          }
        />
      </S.TopBar>
      {renderedData.length > 0 && (
        <Table
          columns={[
            { label: 'ID', key: 'idAjzTipoDocumento' },
            { label: 'Código', key: 'codDocumento' },
            { label: 'Prefixo', key: 'prefixo' },
            { label: 'Criado por', key: 'createdBy' },
            { label: 'Ativo', key: 'flagAtivoString' },
            { label: 'Editar', key: 'editar' },
          ]}
          data={renderedData}
        />
      )}
    </S.DataContainer>
  );
}
