import React, { useEffect, useState } from 'react';
import moment from 'moment';
import SaveIcon from '@material-ui/icons/Save';
import { Modal, SingleSelect, Option } from '../../../../../../_core/_components';
import Button from '../../../../../cobranca/components/button';
import * as S from './styles';
import api from '../../../../../../_core/api';
import { Justificativa } from '../../../../cadastro/justificativas';
import TextField from '../../../../../../_core/_components/text-field';

export type SubmitExcecao = {
  obs: string;
  numeroChamado: string;
  justificativa: Option | undefined;
};

type Props = {
  onClose: () => void;
  onSubmitExcecao: ({ justificativa, numeroChamado, obs }: SubmitExcecao) => void;
};

const Excecao: React.FC<Props> = ({ onClose, onSubmitExcecao }) => {
  const [obs, setObs] = useState('');
  const [numeroChamado, setNumeroChamado] = useState('');
  const [justificativa, setJustificativa] = useState<Option>();
  const [justificativaOptions, setJustificativaOptions] = useState<Option[]>([]);

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    // Opções de justificativa
    const { data } = await api.get<Justificativa[]>('/setup/rules/justificativa');
    const options = data.map(j => ({
      value: j.idAjzJustificativa,
      label: j.descricao,
    }));
    setJustificativaOptions(options);
  };

  const content = (
    <S.Wrapper>
      <S.Form>
        <S.FormGroup>
          <S.FormLabel>Numero Chamado</S.FormLabel>
          <TextField size="small" state={[numeroChamado, v => setNumeroChamado(v)]} variant="outlined" fullWidth />
        </S.FormGroup>
        <S.FormGroup>
          <S.FormLabel>Justificativa</S.FormLabel>
          <SingleSelect state={[justificativa, setJustificativa]} options={justificativaOptions} />
        </S.FormGroup>
        <S.FormGroup>
          <S.FormLabel>Observação</S.FormLabel>
          <S.TextBox value={obs} onChange={e => setObs(e.target.value)} />
        </S.FormGroup>
      </S.Form>
    </S.Wrapper>
  );
  return (
    <Modal
      size="lg"
      title="Inclusão como Exceção"
      onClose={onClose}
      content={content}
      actionItems={[
        <Button
          onClick={() => onSubmitExcecao({ obs, numeroChamado, justificativa })}
          startIcon={<SaveIcon />}
          text="Salvar"
        />,
      ]}
    />
  );
};

export default Excecao;
