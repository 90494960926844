import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';

import moment from 'moment';
import api from '../../../../_core/api';
import { handleErrors, success, failure, warning } from '../../../../_core/services/toast';
import { Button, Input } from '../../../../_core/_components';

import * as S from './styles';
import { Justificativa } from '.';
import { mascaraDDMMAAAA, mascaraNumeros } from '../../../../_core/masks';
import Switch from '../../../../_core/_components/switch';

type Props = {
  onClose: () => void;
  refresh: () => void;
  selectedRegister: Justificativa | null;
};

const Modal: React.FC<Props> = ({ onClose, refresh, selectedRegister }: Props) => {
  const [loading, setLoading] = useState(false);
  const [descJustificativa, setDescJustificativa] = useState('');
  const [limiteJust, setLimiteJust] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [chamado, setChamado] = useState(false);
  const [anexo, setAnexo] = useState(false);
  const [aprovador, setAprovador] = useState(false);

  useEffect(() => {
    if (selectedRegister) {
      setDescJustificativa(selectedRegister.descricao);
      setLimiteJust(String(selectedRegister.limiteJustificativa));
      setDataInicio(moment(selectedRegister.dataInicio).format('DD/MM/YYYY'));
      setDataFim(moment(selectedRegister.dataFinal).format('DD/MM/YYYY'));
      setChamado(selectedRegister.flagObrigaChamadoRn);
      setAnexo(selectedRegister.flagRequerAnexo);
      setAprovador(selectedRegister.aprovador);
    }
  }, [selectedRegister]);

  const isValid = () => {
    if (!descJustificativa || !limiteJust || !dataInicio || !dataFim) {
      return false;
    }

    return true;
  };

  const save = async () => {
    if (!isValid()) {
      warning('Preencha os campos necessários');
    }

    setLoading(true);
    try {
      const payload = {
        idAjzJustificativa: selectedRegister?.idAjzJustificativa || undefined,
        flagAtivo: true,
        txtEstagio: '',
        descricao: descJustificativa,
        flagObrigaChamadoRn: chamado,
        flagRequerAnexo: anexo,
        descricaoCampoObrigatorio: '',
        limiteJustificativa: limiteJust,
        dataInicio: moment(dataInicio, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        dataFinal: moment(dataFim, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        aprovador,
      };
      if (selectedRegister) {
        await api.put(`/setup/rules/justificativa/${selectedRegister.idAjzJustificativa}`, {
          ...payload,
        });
      } else {
        await api.post('/setup/rules/justificativa', {
          ...payload,
        });
      }
      success('Salvo com sucesso.');
      onClose();
      refresh();
      return;
    } catch (e) {
      if ((e as any).response?.data) {
        failure((e as any).response.data);
      } else {
        handleErrors(e);
      }
    }
    setLoading(false);
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <S.CloseAction onClick={onClose}>
        <CloseIcon />
      </S.CloseAction>
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Adicionar / Alterar
        </span>
        <S.Form>
          <S.FormLine>
            <S.FormGroup>
              <Input
                style={{ width: '100%' }}
                label="Justificativa"
                state={[descJustificativa, setDescJustificativa]}
              />
            </S.FormGroup>
            <S.FormGroup>
              <Input
                style={{ width: '100%' }}
                pattern={mascaraNumeros}
                label="Limite Justificativa"
                state={[limiteJust, setLimiteJust]}
              />
            </S.FormGroup>
          </S.FormLine>
          <S.FormLine>
            <S.FormGroup>
              <Input
                pattern={mascaraDDMMAAAA}
                style={{ width: '100%' }}
                label="Data Início"
                state={[dataInicio, setDataInicio]}
              />
            </S.FormGroup>
            <S.FormGroup>
              <Input
                style={{ width: '100%' }}
                pattern={mascaraDDMMAAAA}
                label="Data Fim"
                state={[dataFim, setDataFim]}
              />
            </S.FormGroup>
          </S.FormLine>
          <S.FormLine>
            <S.FormGroup>
              <S.SwitchLabel>Chamado Obrigatório</S.SwitchLabel>
              <Switch state={[chamado, setChamado]} />
            </S.FormGroup>
            <S.FormGroup>
              <S.SwitchLabel>Anexo Obrigatório</S.SwitchLabel>
              <Switch state={[anexo, setAnexo]} />
            </S.FormGroup>
            <S.FormGroup>
              <S.SwitchLabel>Aprovador?</S.SwitchLabel>
              <Switch state={[aprovador, setAprovador]} />
            </S.FormGroup>
          </S.FormLine>
        </S.Form>
        <Button startIcon={<SaveIcon />} onClick={save} text="Salvar" disabled={loading} />
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
