import React, { ReactNode, useMemo, useState } from 'react';
import { InadimplenciaProvider } from '../../../_main/contexts/inadimplencia';
import { Container, SideMenu, ItemMenu } from './styles';
import { TipoContrato } from './tipo-contrato';
import { TipoMovimento } from './tipo-movimento';
import { TipoDocumento } from './tipo-documento';

const MENU_LIST = ['Tipo Contrato', 'Tipo Movimento', 'Tipo Documento'];

export const CadastroInadimplencia: React.FC = () => {
  const [menu, setMenu] = useState(0);

  const menuItem = useMemo(() => {
    switch (menu) {
      case 0:
        return <TipoContrato />;
      case 1:
        return <TipoMovimento />;
      default:
        return <TipoDocumento />;
    }
  }, [menu]);

  return (
    <InadimplenciaProvider>
      <Container>
        <SideMenu>
          {MENU_LIST.map((item: ReactNode, i: number) => (
            <ItemMenu key={i} onClick={() => setMenu(i)} active={i === menu}>
              {item}
            </ItemMenu>
          ))}
        </SideMenu>
        {menuItem}
      </Container>
    </InadimplenciaProvider>
  );
};
