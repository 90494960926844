import React, { CSSProperties } from 'react';
import MaterialButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

interface Props {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  styles?: CSSProperties;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  variant?: 'outlined' | 'contained';
  classname?: string;
  size?: 'large' | 'medium' | 'small';
}

export function Button({
  text,
  onClick,
  disabled = false,
  styles,
  startIcon,
  endIcon,
  variant = 'contained',
  classname,
  size = 'medium',
}: Props): JSX.Element {
  const CustomButton = withStyles(() => ({
    root: {
      color: variant === 'contained' ? '#fff' : '#3C8A8F',
      height: 40,
      '& .MuiCircularProgress-root': {
        width: '20px!important',
        height: '20px!important',
      },
      ...styles,
    },
  }))(MaterialButton);

  return (
    <CustomButton
      variant={variant}
      color="primary"
      disableElevation
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      className={classname}
      size={size}
    >
      {text}
    </CustomButton>
  );
}
