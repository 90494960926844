import React, { useMemo, useState, useEffect, useReducer, useContext } from 'react';
import { Edit } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import api from '../../../../_core/api';
import * as S from '../styles';
import { Button, Input, Option, Table, Modal } from '../../../../_core/_components';
import { ADUser, User } from './types';
import { handleErrors, success } from '../../../../_core/services/toast';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { useCache } from '../../../../_core/cache';
import { requests } from '../../../../requests';
import { INITIAL_STATE, reducer } from './reducer';
import { AuthContext } from '../../../../_main/contexts/auth';
import ModalContent from './modal-content';

export const Usuarios: React.FC = () => {
  const { id } = useContext(AuthContext);
  const { response: responseFiltersShoppings, isLoading: isLoadingFiltersShoppings } = useCache({
    key: 'getAllShoppings',
    fn: requests.get('getAllShoppings'),
  });

  const { response: responsePerfis, isLoading: isLoadingPerfis } = useCache({
    key: 'getPerfis',
    fn: requests.get('getPerfis'),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [nomUsuario, setNomUsuario] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const [form, dispatch] = useReducer(reducer, INITIAL_STATE);

  const shoppingsList = useMemo(() => {
    if (responseFiltersShoppings !== undefined) {
      const shoppingList = responseFiltersShoppings.data.map((item: any) => ({
        value: item.id,
        label: item.nome,
      }));
      return shoppingList;
    }
    return [];
  }, [responseFiltersShoppings]);

  const perfisList = useMemo(() => {
    if (responsePerfis) {
      return responsePerfis.data.content.map((e: any) => ({
        value: e.idPerfil,
        label: e.nome,
      }));
    }
    return [];
  }, [responsePerfis]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post(`/perfilizacao/usuario/buscar`);
      setUsers(
        data.content.map((e: any) => ({
          ...e.usuario,
          perfil: e.perfil,
          shoppings: e.shoppings,
        })),
      );
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const data = useMemo(() => {
    return users
      .filter(e => e.login.includes(nomUsuario) || e.nome.includes(nomUsuario))
      .map(e => ({
        ...e,
        ativo: e.ativo ? 'Sim' : 'Não',
        profile: e.perfil?.nome,
        editar: (
          <IconButton onClick={() => onSetUser(e)}>
            <Edit />
          </IconButton>
        ),
      }));
  }, [users, nomUsuario]);

  const onSetUser = (user: User) => {
    const selectedUser = {
      userId: user.id,
      name: user.nome,
      userName: user.login,
      email: '',
      lotacao: '',
      ativo: user.ativo,
      profile: perfisList.find((item: any) => item.value === user.perfil.idPerfil),
      shoppings: user.shoppings.map(item => ({ value: item.idShopping, label: item.nmShopping })),
    };
    dispatch({ type: 'SET_USER', payload: selectedUser });
    setOpenModal(true);
  };

  const onClickSave = async () => {
    try {
      setIsLoading(true);
      const payload = {
        nome: form.name,
        login: form.userName,
        ativo: form.ativo,
        guardaCache: true,
        recebeEmail: true,
        usuarioIdAlteracao: id,
        perfilId: form.profile.value,
        idShoppings: form.shoppings.map((e: Option) => e.value),
      };
      if (form.userId) {
        await api.post(`/perfilizacao/usuario/salvar`, { ...payload, usuarioId: form.userId });
      } else {
        await api.post(`/perfilizacao/usuario/salvar`, payload);
      }
      setOpenModal(false);
      const { data } = await api.post(`/perfilizacao/usuario/buscar`);
      setUsers(
        data.content.map((e: any) => ({
          ...e.usuario,
          perfil: e.perfil,
          shoppings: e.shoppings,
        })),
      );
      success('Usuário salvo com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const onBlurUsername = async () => {
    if (form.userName.trim().length > 0) {
      await onRequestUser();
    }
  };

  const onRequestUser = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get<ADUser>(`charge-legal-users/valid-user?username=${form.userName}`);
      dispatch({ type: 'NAME', payload: data.name });
      dispatch({ type: 'EMAIL', payload: data.email });
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const disabled = useMemo(() => {
    return [form.userName, form.name, form.profile].some(e => ['', undefined].includes(e));
  }, [form]);

  return (
    <>
      {openModal && (
        <Modal
          title="Adicionar / Alterar Usuário"
          onClose={() => setOpenModal(false)}
          content={
            <ModalContent
              form={form}
              dispatch={dispatch}
              onBlurUsername={onBlurUsername}
              shoppingsList={shoppingsList}
              perfisList={perfisList}
            />
          }
          actionItems={[
            <Button onClick={() => dispatch({ type: 'SET_USER', payload: INITIAL_STATE })} text="Limpar" />,
            <Button onClick={onClickSave} text="Salvar" disabled={disabled} />,
          ]}
        />
      )}
      <FullScreenLoading isEnabled={isLoading || isLoadingFiltersShoppings || isLoadingPerfis} />
      <S.DataContainer>
        <S.TopBar>
          <S.SearchBar>
            <S.SearchField>
              <Input state={[nomUsuario, setNomUsuario]} label="Buscar pelo nome ou usuário" />
            </S.SearchField>
          </S.SearchBar>
          <Button
            text="Novo"
            onClick={() => {
              dispatch({ type: 'SET_USER', payload: INITIAL_STATE });
              setOpenModal(true);
            }}
          />
        </S.TopBar>
        {users.length > 0 && (
          <Table
            columns={[
              { label: 'Nome', key: 'nome' },
              { label: 'E-mail', key: 'login' },
              { label: 'Perfil', key: 'profile' },
              { label: 'Ativo', key: 'ativo' },
              { label: 'Criador', key: 'usuarioCriacao' },
              { label: 'Editar', key: 'editar' },
            ]}
            data={data}
          />
        )}
      </S.DataContainer>
    </>
  );
};
