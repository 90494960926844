import { Contato, Empresa } from './types';

export type EditEmpresaProperty =
  | 'SET_EMPRESA'
  | 'UNSET_EMPRESA'
  | 'SET_RAZAO_SOCIAL'
  | 'SET_NOME_FANTASIA'
  | 'SET_CNPJ'
  | 'SET_ID_ERP'
  | 'SET_TIPO_EMPRESA_ID'
  | 'SET_CEP'
  | 'SET_ENDERECO'
  | 'SET_CIDADE'
  | 'SET_UF'
  | 'SET_CONTATOS';

export const CONTATO: Contato = {
  tipo: 'EMAIL',
  contato: '',
};

export const INITIAL_STATE: Empresa = {
  razaoSocial: '',
  nomeFantasia: '',
  cnpj: '',
  idErp: '',
  tipoEmpresaId: undefined,
  endereco: {
    logradouro: '',
    cep: '',
    cidade: '',
    uf: '',
  },
  contatos: [CONTATO],
};

export function reducer(state: Empresa, { type, payload }: { type: EditEmpresaProperty; payload?: any }): any {
  switch (type) {
    case 'SET_EMPRESA':
      return {
        ...payload,
      };
    case 'UNSET_EMPRESA':
      return undefined;
    case 'SET_RAZAO_SOCIAL':
      return {
        ...state,
        razaoSocial: payload,
      };
    case 'SET_NOME_FANTASIA':
      return {
        ...state,
        nomeFantasia: payload,
      };
    case 'SET_CNPJ':
      return {
        ...state,
        cnpj: payload,
      };
    case 'SET_ID_ERP':
      return {
        ...state,
        idErp: payload,
      };
    case 'SET_TIPO_EMPRESA_ID':
      return {
        ...state,
        tipoEmpresaId: payload,
      };
    case 'SET_CONTATOS':
      return {
        ...state,
        contatos: payload,
      };
    case 'SET_CEP':
      return {
        ...state,
        endereco: {
          ...state.endereco,
          cep: payload,
        },
      };
    case 'SET_ENDERECO':
      return {
        ...state,
        endereco: {
          ...state.endereco,
          logradouro: payload,
        },
      };
    case 'SET_CIDADE':
      return {
        ...state,
        endereco: {
          ...state.endereco,
          cidade: payload,
        },
      };
    case 'SET_UF':
      return {
        ...state,
        endereco: {
          ...state.endereco,
          uf: payload,
        },
      };
    default:
      return state;
  }
}
