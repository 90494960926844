import React, { useState } from 'react';
import { TableContainer } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Chip from '@material-ui/core/Chip';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AlertDialog from '../../../../_core/_components/confirmation';
import api from '../../../../_core/api';
import { OptionTypes } from '..';

const NegociadoChip = withStyles(() =>
  createStyles({
    root: {
      color: '#00630A',
      backgroundColor: 'rgba(66,251,84,0.22)',
      width: '10em',
    },
  }),
)(Chip);

const VencidoChip = withStyles(() =>
  createStyles({
    root: {
      color: '#970000',
      backgroundColor: '#FFB9B9',
      width: '10em',
    },
  }),
)(Chip);

const CustomRemoveIcon = withStyles(() =>
  createStyles({
    root: {
      fill: '#555555',
      cursor: 'pointer',
    },
  }),
)(DeleteIcon);

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#FBFBFB',
      color: '#00959c',
      fontWeight: 'bold',
      fontSize: 12,
    },
    body: {
      fontSize: 12,
    },
    stickyHeader: {},
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#FBFBFB',
      '&:nth-of-type(odd)': {
        backgroundColor: '#E5E9EA',
      },

      '&:hover': {
        backgroundColor: '#daeedd',
      },
    },
  }),
)(TableRow);

const StyledCheckIcon = withStyles(() =>
  createStyles({
    root: {
      color: '#00959c',
    },
  }),
)(CheckCircleIcon);

const useStyles = makeStyles({
  container: {
    maxHeight: 440,
  },
});

export type TableRow = {
  description: string;
  active: boolean;
  creationDate: Date;
  id: number;
};

type Props = {
  tableRows?: TableRow[];
  onUpdate: (p: Promise<AxiosResponse<any>>) => void;
  paramType: OptionTypes;
};

const CustomizedTables: React.FC<Props> = ({ tableRows, onUpdate, paramType }) => {
  const classes = useStyles();
  const [confirmationId, setConfirmationId] = useState<number | undefined>();

  const deleteOption = () => {
    let url = '';

    switch (paramType) {
      case 'expecativa':
        url = '/api/allos/v1/prioritization/expectation-receipt/';
        break;
      case 'tipoNeg':
        url = '/api/allos/v1/prioritization/type-negotiation/';
        break;
      case 'motivoImprovavel':
        url = '/api/allos/v1/prioritization/unlikely-reason-receipt/';
        break;
      default:
        break;
    }

    const deletePromise = api.delete(`${url}${confirmationId}`);
    setConfirmationId(undefined);
    onUpdate(deletePromise);
  };

  if (tableRows && tableRows.length > 0) {
    return (
      <>
        <AlertDialog
          open={Boolean(confirmationId)}
          close={() => setConfirmationId(undefined)}
          handleOK={() => deleteOption()}
        />
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Descrição</StyledTableCell>
                <StyledTableCell>Criação</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows?.map(row => (
                <StyledTableRow onClick={() => console.log('a')} key={row.id}>
                  <StyledTableCell>{row.description}</StyledTableCell>
                  <StyledTableCell>{moment(row.creationDate).format('DD/MM/YYYY')}</StyledTableCell>
                  <StyledTableCell>
                    {row.active ? <NegociadoChip label="Ativo" /> : <VencidoChip label="Inativo" />}
                  </StyledTableCell>
                  <StyledTableCell>
                    <CustomRemoveIcon onClick={() => setConfirmationId(row.id)} fontSize="small" />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  return <></>;
};

export default CustomizedTables;
