import React, { useRef, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { CardFormContent } from './styles';

const useStyles = makeStyles(() =>
  createStyles({
    dropArea: {
      border: '2px dashed #ccc',
      borderRadius: '4px',
      padding: '1rem',
      textAlign: 'center',
      cursor: 'pointer', // Define cursor pointer para indicar que é clicável
      marginTop: '1rem',
      transition: 'background-color 0.2s ease',
    },
    dropAreaActive: {
      backgroundColor: '#f0f0f0',
    },
  }),
);

export function UploadFile({
  state,
  label,
}: {
  state: [File | undefined, React.Dispatch<React.SetStateAction<File | undefined>>];
  label?: string;
}): JSX.Element {
  const classes = useStyles();

  const ref = useRef<HTMLInputElement | null>(null);

  const [isDragActive, setIsDragActive] = useState(false);

  const [file, setFile] = state;

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(true);
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);

    const { files } = e.dataTransfer;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const blob = e.target.files ? e.target.files[0] : undefined;
    if (blob === undefined) return;
    setFile(blob);
  };

  return (
    <>
      {label && <label style={{ margin: 0, color: '#6D6D6D', fontSize: 14, fontWeight: 600 }}>{label}</label>}
      <CardFormContent
        className={`${classes.dropArea} ${isDragActive ? classes.dropAreaActive : ''}`}
        onClick={() => ref.current?.click()}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {file ? <p>{file.name}</p> : <p>Arraste e solte o arquivo aqui ou clique para selecionar</p>}
        <input id="selecao-arquivo-input" hidden type="file" onChange={onFileChange} ref={ref} />
      </CardFormContent>
    </>
  );
}
