import api from '../_core/api';

export const getAllShoppings = () => api.get('/empresas/shoppings/simple-umpaged');

export const getGruposCobranca = () => api.get(`/grupo-cobranca/grupo/buscar/listagrupo`);

export const getPortfolios = () => api.get('grupo-cobranca/grupo/buscar/listaportifolio');

export const getJustificativas = () => api.get('/workflow/serasa/serasa-justificativa/buscar');

export const getAjuizamentoJustificativas = () => api.get('/parametros/ajuizamento/justificativa');

export const getStatus = () => api.get('/spredsheet/status');

export const getOrigem = () => api.get('/filters/source');

export const getPerfis = () => api.post('/perfilizacao/pefil/buscar');

export const getTipoAcao = () => api.get('/parametros/ajuizamento/tipo-acao');

export const getCodigosSerasa = (payload: any) => api.post('/perfilizacao/usuario/validar/acessos', payload);

export const getEmpresas = () => api.get('/empresas/unpaged');

export const getTipoEmpresa = () => api.get('empresas/tipo-empresa');

export const getTipoMovimento = () => api.get('/config/inadimplencia/tipo-movimento/unpaged');

export const getTipoContrato = () => api.get('/config/inadimplencia/tipo-contrato/unpaged');

export const getTipoConta = () => api.get('/config/inadimplencia/tipo-conta/unpaged');

export const getModules = () => api.get('/config/excecoes/modulo');
