import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Collapse from '@material-ui/core/Collapse';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Checkbox from './checkbox';
import { toReal } from '../../../../_core/services/formaters';
import TableTransacoes from './tableTransacoes';
import api from '../../../../_core/api';
import { failure } from '../../../../_core/services/toast';
import * as S from '../styles';
import { Boleto, BoletosResponse, Titulo, TitulosResponse } from '../detalhamento/BoletosTitulos/types';
import { AjuizamentoDetalhes } from '../types';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import ConfirmationModal from '../../../../_core/_components/confirmation-modal';

const StyledTableCell = withStyles(() =>
  createStyles({
    head: {
      backgroundColor: '#00959c',
      color: '#fff',
      fontWeight: 'bold',
    },
    body: {
      fontSize: 14,
    },
    root: {
      minHeight: 30,
      padding: '1px 4px',
      borderBottom: 'none',
    },
  }),
)(TableCell);

const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#fff',
      '&:nth-of-type(4n+1)': {
        backgroundColor: '#E1E6E7',
      },
      '&:nth-of-type(even)': {
        backgroundColor: '#DFEEF0',
      },
    },
  }),
)(TableRow);

const StyledHeaderTableRow = withStyles(() =>
  createStyles({
    root: {
      '& th:first-child': {
        borderTopLeftRadius: '10px',
      },
      '& th:last-child': {
        borderTopRightRadius: '10px',
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  smCol: {
    width: '50px',
  },
  mdCol: {
    width: '150px',
    '@media (max-width: 1439px)': {
      width: '120px',
    },
  },
  lgCol: {
    width: '170px',
  },
});

type Props = {
  onChangeBoletos: (boletos?: Boleto[]) => void;
  ajuizamentoDetalhes: AjuizamentoDetalhes;
  onRequestUpdateSaldo: (boletos: Boleto[]) => void;
  isLoading: boolean;
};

const CustomizedTables: React.FC<Props> = ({
  onChangeBoletos,
  ajuizamentoDetalhes,
  onRequestUpdateSaldo,
  isLoading,
}) => {
  const classes = useStyles();
  const [boletos, setBoletos] = useState<Boleto[]>();
  const [loading, setLoading] = useState(false);

  const [changingBoleto, setChangingBoleto] = useState<Boleto>();
  const [changingTransacao, setChangingTransacao] = useState<Titulo>();

  const [currentExpandedBol, setCurrentExpandedBol] = useState<Boleto>();

  useEffect(() => {
    getBoletos();
  }, [ajuizamentoDetalhes]);

  useEffect(() => {
    onChangeBoletos(boletos);
  }, [boletos]);

  const hasFilledChamado = useMemo(() => !!ajuizamentoDetalhes.guias.find(g => g.numeroChamadoAjuizamento), [
    ajuizamentoDetalhes,
  ]);

  const getBoletos = async () => {
    try {
      // get boletos
      setLoading(true);
      const { data } = await api.get<BoletosResponse>(
        `workflow/ajuizamento/acoes/${ajuizamentoDetalhes.idAcaoAjuizamento}/boletos?size=9999`,
      );

      let filteredBols = [];

      if (currentExpandedBol) {
        filteredBols = data.data.filter(b => b.idBoleto === currentExpandedBol.idBoleto);
      } else {
        filteredBols = data.data;
      }

      // popula titulos
      const populatedBols = await Promise.all(
        filteredBols.map(async b => {
          const { data: titulosData } = await api.get<TitulosResponse>(
            `/workflow/ajuizamento/acoes/${ajuizamentoDetalhes.idAcaoAjuizamento}/boletos/${b.idBoleto}/titulos?size=9999`,
          );

          // seleciona titulos
          const titulos = titulosData.data.map(t => ({
            ...t,
            isSelected: t.considerar === true || t.considerar === null,
          }));

          const titulosCount = titulos?.length;
          const selectedTitulosCount = titulos?.filter(t => t.considerar).length;

          return {
            ...b,
            isSelected: Boolean(titulosCount && titulosCount > 0 && selectedTitulosCount === titulosCount),
            isOpen: currentExpandedBol?.idBoleto === b.idBoleto,
            titulos,
          };
        }),
      );

      if (currentExpandedBol) {
        const replacedBols = boletos?.map(b => {
          if (b.idBoleto === populatedBols[0]?.idBoleto) {
            return populatedBols[0];
          }
          return b;
        });
        setBoletos(replacedBols);
      } else {
        setBoletos(populatedBols);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      failure('Erro ao tentar carregar boletos');
    }
  };

  const handleChangeTransacao = (t: Titulo, boleto: Boleto) => {
    setChangingBoleto(boleto);
    setChangingTransacao(t);
  };

  const handleChangeBoleto = (boleto: Boleto) => {
    setChangingBoleto(boleto);
  };

  const onConfirmChange = () => {
    if (changingBoleto) {
      setBolLoading(changingBoleto, true);
      let newBols;
      if (changingTransacao) {
        newBols = toggleTransacao(changingTransacao, changingBoleto);
      } else {
        newBols = toggleBoleto(changingBoleto);
      }
      setChangingTransacao(undefined);
      setChangingBoleto(undefined);
      if (newBols) {
        onRequestUpdateSaldo(newBols);
        setCurrentExpandedBol(changingBoleto);
      }
      setBolLoading(changingBoleto, false);
    }
  };

  const toggleTransacao = (t: Titulo, boleto: Boleto) => {
    const newBols = boletos?.map(bol => {
      if (bol.idBoleto === boleto.idBoleto) {
        return {
          ...bol,
          titulos: bol.titulos?.map(titulo => {
            if (titulo.idTitulo === t.idTitulo) {
              return {
                ...titulo,
                isSelected: !titulo.isSelected,
              };
            }
            return titulo;
          }),
        };
      }

      return bol;
    });

    setBoletos(newBols);
    return newBols;

    // const newTransacoes = transacoes?.map(transacao => {
    //   if (t.idTitulo === transacao.idTitulo) {
    //     return { ...transacao, isSelected: !transacao.isSelected };
    //   }
    //   return transacao;
    // });

    // setTransacoes(newTransacoes);
  };

  const setRowOpen = (bol: Boleto) => {
    const newBoletos = boletos?.map(b => {
      if (b.idBoleto === bol.idBoleto) {
        return {
          ...b,
          isOpen: !b.isOpen,
        };
      }
      return b;
    });

    setBoletos(newBoletos);
  };

  const setBolLoading = (bol: Boleto, loading: boolean, transacoes?: any[]) => {
    const newBoletos = boletos?.map(b => {
      if (b.idBoleto === bol.idBoleto) {
        return {
          ...b,
          isLoading: false,
          isOpen: true,
          // transacoes: transacoes || [],
        };
      }
      return b;
    });

    setBoletos(newBoletos);
  };

  const toggleBoleto = (bol: Boleto) => {
    const newBoletos = boletos?.map(b => {
      if (b.idBoleto === bol.idBoleto) {
        const titles = b.titulos?.map(t => ({
          ...t,
          isSelected: !b.isSelected,
        }));

        return { ...b, titulos: titles, isSelected: !b.isSelected };
      }
      return b;
    });

    setBoletos(newBoletos);
    return newBoletos;
  };

  const handleOpenBoleto = async (boleto: any) => {
    if (!boleto.isOpen) {
      try {
        // abrir boleto
        setBolLoading(boleto, true);
        // const { data } = await api.get<any[]>(`/api/allos/v1/prioritization/detail/slipId/${boleto.slipId}/titles`);
        // setBolLoading(boleto, false, data);
      } catch (e) {
        failure('Falha ao tentar abrir boleto, entre em contato com a equipe técnica');
        setBolLoading(boleto, false);
      }
    } else {
      // fechar boleto
      setRowOpen(boleto);
    }
  };

  const getConfirmationText = () => {
    if (changingTransacao && changingTransacao.isSelected) {
      return (
        <>
          Desabilitando um título você confirma que ele <b>não será considerado </b> no processo de ajuizamento.
        </>
      );
    }

    if (changingTransacao && !changingTransacao.isSelected) {
      return (
        <>
          Habilitando um título você confirma que ele <b>será considerado</b> no processo de ajuizamento.
        </>
      );
    }

    if (changingBoleto && changingBoleto.isSelected) {
      return (
        <>
          Desabilitando um boleto você confirma que seus títulos <b>não serão considerados</b> no processo de
          ajuizamento.
        </>
      );
    }

    if (changingBoleto && !changingBoleto.isSelected) {
      return (
        <>
          Habilitando um boleto você confirma que seus títulos <b>serão considerados</b> no processo de ajuizamento.
        </>
      );
    }

    return <></>;
  };

  const getModalConfirmationTitle = () => {
    if (changingTransacao && changingTransacao.isSelected) {
      return 'Desconsiderar título';
    }

    if (changingTransacao && !changingTransacao.isSelected) {
      return 'Considerar título';
    }

    if (changingBoleto && changingBoleto.isSelected) {
      return 'Desconsiderar boleto';
    }

    if (changingBoleto && !changingBoleto.isSelected) {
      return 'Considerar boleto';
    }

    return '';
  };

  const handleCancelConfirmation = () => {
    setChangingTransacao(undefined);
    setChangingBoleto(undefined);
  };

  if (!boletos || boletos?.length === 0) {
    return <p>Carregando...</p>;
  }

  return (
    <>
      <FullScreenLoading isEnabled={loading || isLoading} />
      <ConfirmationModal
        text={getConfirmationText()}
        handleOk={() => onConfirmChange()}
        open={!!changingTransacao || !!changingBoleto}
        handleCancel={() => handleCancelConfirmation()}
        title={getModalConfirmationTitle()}
      />
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledHeaderTableRow>
            <StyledTableCell className={classes.smCol} />
            <StyledTableCell className={classes.smCol} />
            <StyledTableCell className={classes.lgCol}>Nº do boleto</StyledTableCell>
            <StyledTableCell className={classes.lgCol}>Valor do boleto</StyledTableCell>
            <StyledTableCell className={classes.lgCol}>Valor total títulos</StyledTableCell>
            <StyledTableCell className={classes.lgCol}>Data de vencimento</StyledTableCell>
            <StyledTableCell className={classes.mdCol}>Juros</StyledTableCell>
            <StyledTableCell className={classes.mdCol}>Multa</StyledTableCell>
            <StyledTableCell>Correção monetária</StyledTableCell>
          </StyledHeaderTableRow>
        </TableHead>
        <TableBody>
          {boletos?.map((row, i) => {
            const titulosCount = row.titulos?.length;
            const selectedTitulosCount = row.titulos?.filter(t => t.isSelected).length;
            return (
              <>
                <StyledTableRow key={row.idBoleto}>
                  <StyledTableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => handleOpenBoleto(row)}>
                      {row.isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Checkbox
                      disabled={hasFilledChamado}
                      indeterminate={Boolean(
                        selectedTitulosCount &&
                          titulosCount &&
                          selectedTitulosCount > 0 &&
                          selectedTitulosCount < titulosCount,
                      )}
                      size="small"
                      change={() => handleChangeBoleto(row)}
                      checked={Boolean(titulosCount && titulosCount > 0 && selectedTitulosCount === titulosCount)}
                    />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.numeroBoleto}
                  </StyledTableCell>
                  <StyledTableCell>{row.valor ? toReal(row.valor) : '-'}</StyledTableCell>
                  <StyledTableCell>{row.saldoTitulos ? toReal(row.saldoTitulos) : '-'}</StyledTableCell>
                  <StyledTableCell>{moment(row.dataVencimento).format('DD/MM/YYYY')}</StyledTableCell>
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell />
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell style={{ padding: 0 }} colSpan={11}>
                    <Collapse in={row.isOpen} timeout="auto" unmountOnExit>
                      {row.isLoading && (
                        <S.LoadingBolWrapper>
                          <CircularProgress />
                        </S.LoadingBolWrapper>
                      )}
                      {row.isOpen && !row.isLoading && (
                        <TableTransacoes
                          hasFilledChamado={hasFilledChamado}
                          onToggleTransacao={t => handleChangeTransacao(t, row)}
                          titulos={row.titulos}
                        />
                      )}
                    </Collapse>
                  </StyledTableCell>
                </StyledTableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default CustomizedTables;
