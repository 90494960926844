import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Collapse from '@material-ui/core/Collapse';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Checkbox from '../../components/checkbox';
import { toReal } from '../../../../_core/services/formaters';
import TableTransacoes from './tableTransacoes';
import { Contract, Slip, Title } from '../types';
import api from '../../../../_core/api';
import { failure } from '../../../../_core/services/toast';
import * as S from '../styles';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#fff',
      color: '#00959c',
      fontWeight: 'bold',
      border: '1px solid #E5E9EA',
    },
    body: {
      fontSize: 12,
    },
    root: {
      padding: '4px',
      borderBottom: 'none',
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#FBFBFB',

      '&:nth-of-type(4n), &:nth-of-type(4n-3)': {
        backgroundColor: '#FBFBFB',
      },
      '&:nth-of-type(4n-1), &:nth-of-type(4n)': {
        backgroundColor: '#E5E9EA', // Cor para linhas não
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

type Props = {
  contrato: Contract;
  onChangeBoletos: (boletos: Slip[] | undefined, contrato: Contract) => void;
};

const CustomizedTables: React.FC<Props> = ({ contrato, onChangeBoletos }) => {
  const classes = useStyles();
  const [boletos, setBoletos] = useState<Slip[] | undefined>([]);

  useEffect(() => {
    setBoletos(contrato.slips);
  }, [contrato]);

  useEffect(() => {
    onChangeBoletos(
      boletos?.filter(b => b.isSelected),
      contrato,
    );
  }, [boletos]);

  const setRowOpen = (bol: Slip) => {
    const newBoletos = boletos?.map(b => {
      if (b.slipNumber === bol.slipNumber) {
        return {
          ...b,
          isOpen: !b.isOpen,
        };
      }
      return b;
    });

    setBoletos(newBoletos);
  };

  const setBolLoading = (bol: Slip, loading: boolean, transacoes?: Title[]) => {
    const newBoletos = boletos?.map(b => {
      if (b.slipNumber === bol.slipNumber) {
        return {
          ...b,
          isLoading: loading,
          isOpen: true,
          transacoes: transacoes || [],
        };
      }
      return b;
    });

    setBoletos(newBoletos);
  };

  const toggleBoleto = (bol: Slip) => {
    const newBoletos = boletos?.map(b => {
      if (b.slipId === bol.slipId) {
        return { ...b, isSelected: !b.isSelected };
      }
      return b;
    });

    setBoletos(newBoletos);
  };

  const handleSelectAllClick = () => {
    const allSelected = boletos?.every(b => b.isSelected);
    const newBoletos = boletos?.map(b => ({ ...b, isSelected: !allSelected }));
    setBoletos(newBoletos);
  };

  const handleOpenBoleto = async (boleto: Slip) => {
    if (!boleto.isOpen) {
      try {
        // abrir boleto
        setBolLoading(boleto, true);
        const { data } = await api.get<Title[]>(`/api/allos/v1/prioritization/detail/slipId/${boleto.slipId}/titles`);
        setBolLoading(boleto, false, data);
      } catch (e) {
        failure('Falha ao tentar abrir boleto, entre em contato com a equipe técnica');
        setBolLoading(boleto, false);
      }
    } else {
      // fechar boleto
      setRowOpen(boleto);
    }
  };

  const selectedBoletosCount = boletos?.filter(b => b.isSelected).length;
  const allBoletosCount = boletos?.length;

  if (!boletos || boletos?.length === 0) {
    return <p>Sem dados para este contrato</p>;
  }

  return (
    <Table className={classes.table} aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell align="center" colSpan={4} />
          <StyledTableCell align="center" colSpan={1}>
            Datas
          </StyledTableCell>
          <StyledTableCell align="center" colSpan={4}>
            Status
          </StyledTableCell>
          <StyledTableCell />
        </TableRow>
      </TableHead>
      <TableHead>
        <TableRow>
          <StyledTableCell />
          <StyledTableCell>
            <Checkbox
              size="small"
              indeterminate={Boolean(
                selectedBoletosCount &&
                  allBoletosCount &&
                  selectedBoletosCount > 0 &&
                  selectedBoletosCount < allBoletosCount,
              )}
              checked={Boolean(allBoletosCount && allBoletosCount > 0 && selectedBoletosCount === allBoletosCount)}
              change={() => handleSelectAllClick()}
            />
          </StyledTableCell>
          <StyledTableCell>Boleto</StyledTableCell>
          <StyledTableCell>Valor</StyledTableCell>
          <StyledTableCell>Vencimento</StyledTableCell>
          <StyledTableCell>Cartas/Email</StyledTableCell>
          <StyledTableCell>Serasa</StyledTableCell>
          <StyledTableCell>Ajuizamento</StyledTableCell>
          <StyledTableCell>Status Negociação</StyledTableCell>
          <StyledTableCell>Status Expectativa</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {boletos?.map((row, i) => (
          <React.Fragment key={i}>
            <StyledTableRow key={row.slipId}>
              <StyledTableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => handleOpenBoleto(row)}>
                  {row.isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </StyledTableCell>
              <StyledTableCell>
                <Checkbox size="small" change={() => toggleBoleto(row)} checked={row.isSelected || false} />
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                {row.slipNumber}
              </StyledTableCell>
              <StyledTableCell>{row.slipValue ? toReal(row.slipValue) : '-'}</StyledTableCell>
              <StyledTableCell>{moment(row.dueDate).format('DD/MM/YYYY')}</StyledTableCell>
              <StyledTableCell>{row.statusCartaEmail || '-'}</StyledTableCell>
              <StyledTableCell>{row.statusSerasa || '-'}</StyledTableCell>
              <StyledTableCell>{row.statusAjuizamento || '-'}</StyledTableCell>
              <StyledTableCell>{row.typeNegotiation ?? '-'}</StyledTableCell>
              <StyledTableCell>{row.statusExpectativa ?? '-'}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell style={{ padding: 0 }} colSpan={12}>
                <Collapse in={row.isOpen} timeout="auto" unmountOnExit>
                  {row.isLoading && (
                    <S.LoadingBolWrapper>
                      <CircularProgress />
                    </S.LoadingBolWrapper>
                  )}
                  {row.isOpen && !row.isLoading && <TableTransacoes transacoes={row.transacoes} />}
                </Collapse>
              </StyledTableCell>
            </StyledTableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
};

export default CustomizedTables;
