import React, { useState, useEffect, useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import * as S from '../styles';
import Modal from './modal';
import ConfirmationModal from '../../../../_core/_components/confirmation-modal';
import api from '../../../../_core/api';
import { handleErrors, success } from '../../../../_core/services/toast';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { Button, Input, PaginatedTable } from '../../../../_core/_components';

export type Action = {
  idAjzTipoAcao: number;
  flagAtivo: boolean;
  nomTipoAcao: string;
  codigo: string;
  nomeAr: string;
};

const TiposDeAcao: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [registerList, setRegisterList] = useState<Action[]>([]);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRegister, setSelectedRegister] = useState<Action | null>(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get<Action[]>(`/setup/rules/tipo-acao`);
      setRegisterList(data);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const filteredItems = useMemo(() => {
    if (searchText) {
      const filtered = registerList.filter(reg => reg.nomTipoAcao.toUpperCase().includes(searchText.toUpperCase()));
      return filtered;
    }
    return registerList;
  }, [searchText, registerList]);

  const excludeItem = async () => {
    try {
      await api.delete(`/setup/rules/tipo-acao/${selectedRegister?.idAjzTipoAcao}`);
      success('O registro foi removido');
      getData();
      setSelectedRegister(null);
    } catch (e) {
      handleErrors(e);
    }
    setSelectedRegister(null);
  };

  const renderedData = useMemo(() => {
    if (filteredItems.length > 0) {
      return filteredItems.map(item => ({
        id: item.idAjzTipoAcao,
        action: item.nomTipoAcao.toUpperCase(),
        codigo: item.codigo,
        descricao: item.nomeAr,
        actions: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.idAjzTipoAcao.toString()}
            onClick={(e: any) => {
              setSelectedRegister(item);
            }}
          >
            <DeleteIcon />
            <S.IconLabel>Remover</S.IconLabel>
          </IconButton>
        ),
      }));
    }
    return [];
  }, [filteredItems]);

  return (
    <S.DataContainer>
      <FullScreenLoading isEnabled={loading} />
      <ConfirmationModal
        open={Boolean(selectedRegister)}
        text={`Deseja excluir o item "${selectedRegister?.nomTipoAcao}" ?`}
        handleCancel={() => setSelectedRegister(null)}
        handleOk={excludeItem}
      />
      <Modal onRequestUpdate={getData} open={isModalOpen} onClose={() => handleCloseModal()} />
      <S.TopBar>
        <S.SearchBar>
          <S.SearchField>
            <Input label="Buscar" state={[searchText, setSearchText]} />
          </S.SearchField>
        </S.SearchBar>
        <Button text="Criar novo" onClick={() => setModalOpen(true)} />
      </S.TopBar>

      {renderedData.length > 0 && (
        <PaginatedTable
          columns={[
            { label: 'Id', key: 'id' },
            { label: 'Tipo de Ação', key: 'action' },
            { label: 'Descrição', key: 'descricao' },
            { label: 'Código', key: 'codigo' },
            { label: '', key: 'actions' },
          ]}
          data={renderedData}
          pagination={pageInfo}
          onChangePage={setPage}
        />
      )}
    </S.DataContainer>
  );
};

export default TiposDeAcao;
