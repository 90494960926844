import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  font-size: 10px;
`;
export const CardContainer = styled.div`
  display: flex;
  gap: 3em;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  background-color: #fff;
  box-shadow: 0px 8px 8px rgba(196, 196, 196, 0.3);
  padding: 1em 2em;
  max-width: 40em;
`;
export const CardHeader = styled.div`
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  gap: 0 4em;
  justify-content: center;
  padding: 1em 0;
`;
export const InfoGroup = styled.div``;

type InfoProps = {
  variant?: 'green' | 'purple' | 'default';
};

const InfoModifier: any = {
  green: () => css`
    color: #72c2af;
  `,
  purple: () => css`
    color: #7284c2;
  `,
  default: () => css`
    color: #515151;
  `,
};

export const Info = styled.div<InfoProps>`
  font-size: 1.8em;
  font-weight: bold;

  ${({ variant = 'default' }) => css`
    ${InfoModifier[variant]}
  `}
`;

export const CardDetails = styled.div``;

type DetailTitleProps = {
  variant: 'purple' | 'green';
};

const DetailTitleModifier = {
  green: () => css`
    color: #72c2af;
  `,
  purple: () => css`
    color: #7284c2;
  `,
};

export const DetailTitle = styled.div<DetailTitleProps>`
  ${({ variant }) => DetailTitleModifier[variant]}
  font-size: 1.4em;
  font-weight: bold;
  margin: 1em 0;
  text-align: center;
`;
export const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5em 0.5em;
  margin-bottom: 2em;
`;
export const DetailGroup = styled.div`
  flex: 0 0 30%;
`;
export const DetailInfo = styled.div`
  font-weight: bold;
  font-size: 1.2em;
  color: #515151;
`;
export const Label = styled.p`
  color: #8890b5;
  font-size: 1.2em;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  height: 20em;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
