import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../modules/cobranca/components/button';
import * as S from './styles';

type Props = {
  handleOK: () => void;
  open: boolean;
  close: () => void;
};

const AlertDialog: React.FC<Props> = ({ open, handleOK, close }) => {
  const handleClose = () => {
    close();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirmação</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Esta ação não poderá ser desfeita. Deseja continuar?
        </DialogContentText>
      </DialogContent>
      <S.Actions>
        <Button onClick={handleClose} text="CANCELAR" />
        <S.ExcludeButton onClick={handleOK}>EXCLUIR</S.ExcludeButton>
      </S.Actions>
    </Dialog>
  );
};

export default AlertDialog;
