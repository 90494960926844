import React, { useContext, useEffect, useMemo, useState } from 'react';
import api from '../../../_core/api';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { Container, Wrapper, Actions } from './styles';
import { AuthContext } from '../../../_main/contexts/auth';
import { handleErrors, success } from '../../../_core/services/toast';
import Table from '../../../_core/_components/table';
import { downloadXls } from '../../../_core/services/download';
import { Button, Checkbox } from '../../../_core/_components';

interface ICliente {
  id: number;
  shoppingName: string;
  customersCount: number;
  slipCount: number;
  guarantorCount: number;
  selected?: boolean;
  idCiclo?: number;
}

export const ClientesElegiveis: React.FC = () => {
  const { id } = useContext(AuthContext);
  const [clientes, setClientes] = useState<ICliente[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<number[]>([]);

  const [totalCostumers, setTotalCostumers] = useState(0);
  const [totalSlips, setTotalSlips] = useState(0);
  const [totalGuarantors, setTotalGuarantors] = useState(0);
  const [allClientes, setAllClientes] = useState(false);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (clientes.length > 0) {
      getTotalCostumers();
      getTotalSlips();
      getTotalGuarantors();
    }
  }, [clientes]);

  const clickAprovacao = async () => {
    if (selectedShoppings.length === 0) return;
    setLoading(true);
    try {
      await api.post('/workflow/serasa/serasa-negativacao/recomendar-boletos', {
        shoppings: selectedShoppings,
        idCiclo: clientes[0].idCiclo,
        idUsuario: id,
      });
      success('Envio realizado com sucesso');
      await getData();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const getData = async () => {
    setLoading(true);
    const listClientes: ICliente[] = [];
    const response = await api.post('/workflow/serasa/serasa-shopping/totalshopping', {
      idShopping: 1,
      codigoStatus: 'AGUARDANDO_QA_LIBERAR_PARA_O_SHOPPING',
    });
    response.data.content.forEach((item: any) => {
      listClientes.push({
        id: item.idShopping,
        shoppingName: item.shopping.nomeShopping,
        customersCount: item.quantidadeClientes,
        slipCount: item.quantidadeBoletos,
        guarantorCount: item.quantidadeFiador,
        selected: false,
        idCiclo: item.cicloNegativacao.id,
      });
    });
    setClientes(listClientes);
    setLoading(false);
  };

  const getTotalCostumers = () => {
    let tempCustomersCount = 0;
    clientes.forEach(c => {
      tempCustomersCount += c.customersCount;
    });
    setTotalCostumers(tempCustomersCount);
  };

  const getTotalSlips = () => {
    let tempSlipCount = 0;
    clientes.forEach(c => {
      tempSlipCount += c.slipCount;
    });
    setTotalSlips(tempSlipCount);
  };

  const getTotalGuarantors = () => {
    let tempGuarantorCount = 0;
    clientes.forEach(c => {
      tempGuarantorCount += c.guarantorCount;
    });
    setTotalGuarantors(tempGuarantorCount);
  };

  const pegarClientesSelecionados = () => {
    return clientes.filter((c: ICliente) => c.selected);
  };

  const toggleSelection = (cliente: ICliente) => {
    if (selectedShoppings.includes(cliente.id)) {
      const listShoppings = [...selectedShoppings];
      const index = selectedShoppings.indexOf(cliente.id);
      listShoppings.splice(index, 1);
      setSelectedShoppings(listShoppings);
    } else {
      setSelectedShoppings([...selectedShoppings, cliente.id]);
    }
    const tempClientes = clientes.map(item => ({
      ...item,
      selected: item.id === cliente.id ? !item.selected : item.selected,
    }));
    setClientes(tempClientes);

    if (allClientes) {
      setAllClientes(false);
    }
    if (pegarClientesSelecionados().length === clientes.length) {
      setAllClientes(true);
    }
  };

  const allToggleSelection = () => {
    if (allClientes) {
      setAllClientes(false);
      mudarSelectionAll(false);
      setSelectedShoppings([]);
    } else {
      setAllClientes(true);
      mudarSelectionAll(true);
      const listShoppings: number[] = [];
      clientes.forEach(i => {
        listShoppings.push(i.id);
      });
      setSelectedShoppings(listShoppings);
    }
  };

  function mudarSelectionAll(value: boolean) {
    const tempClientes = clientes.map(cliente => ({
      ...cliente,
      selected: value,
    }));
    setClientes(tempClientes);
  }

  const reqManual = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        '/workflow/serasa/serasa-negativacao/download/boletos-shopping',
        {
          idShopping: selectedShoppings,
        },
        {
          responseType: 'arraybuffer',
        },
      );
      downloadXls(response);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const renderedData = useMemo(() => {
    if (clientes.length > 0) {
      return clientes.map(item => ({
        toggle: <Checkbox checked={item.selected === true} onClick={() => toggleSelection(item)} />,
        id: item.id,
        shoppingName: item.shoppingName,
        customersCount: item.customersCount,
        slipCount: item.slipCount,
        guarantorCount: item.guarantorCount,
      }));
    }
    return [];
  }, [clientes]);

  return (
    <>
      <FullScreenLoading isEnabled={isLoading} />
      <Wrapper>
        <Container>
          <h2>Lista de clientes elegíveis para negativação</h2>

          <Actions>
            <Button onClick={getData} text="ATUALIZAR" />
            <Button onClick={clickAprovacao} text="ENCHAMINHAR PARA APROVAÇÃO" />
            <Button onClick={reqManual} text="Exportar" disabled={renderedData.length === 0} />
          </Actions>
          {renderedData.length > 0 && (
            <>
              <Table
                columns={[
                  {
                    label: <Checkbox type="secondary" checked={allClientes} onClick={allToggleSelection} />,
                    key: 'toggle',
                  },
                  { label: 'Nome do Shopping', key: 'shoppingName', orderable: true },
                  { label: 'Clientes para negativação', key: 'customersCount', orderable: true },
                  { label: 'Boletos para negativação', key: 'slipCount', orderable: true },
                  { label: 'Fiadores para negativação', key: 'guarantorCount', orderable: true },
                ]}
                data={renderedData}
                footer={['', '', totalCostumers, totalSlips, totalGuarantors]}
              />
            </>
          )}
        </Container>
      </Wrapper>
    </>
  );
};
