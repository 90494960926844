import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField, TextFieldProps } from '@material-ui/core';

const CustomTF = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#00959c',
    },
    '& input': {
      color: '#6f6f6f',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#00959c',
      },
    },
  },
})(TextField);

type Props = {
  state: [string, React.Dispatch<React.SetStateAction<string>>];
  pattern?: (value: string) => string;
} & TextFieldProps;

const CustomTextField: React.FC<Props> = ({ state: [text, setText], pattern, ...props }) => {
  const onSetText = (value: string) => {
    if (pattern) {
      const newValue = pattern(value);
      setText(newValue);
      return;
    }
    setText(value);
  };

  return <CustomTF value={text} onChange={(e: any) => onSetText(e.target.value)} {...props} />;
};

export default CustomTextField;
