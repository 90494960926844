import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import * as S from './styles';
import Table from './table';
import Button from '../../../components/button';
import ModalContatos, { Contact } from './modal';
import AlertDialog from '../../../../../_core/_components/confirmation';
import { PriorizationItem, ShoppingEntity } from '../../../priorizacao/types';
import api from '../../../../../_core/api';
import { ContatoDB, ContractResponse } from '../../types';
import { failure, success } from '../../../../../_core/services/toast';

type Props = {
  customerPriorizationData: PriorizationItem & {
    selectedDate: Date;
    shopping?: ShoppingEntity;
  };
  contracts?: ContractResponse;
};

type GetContatosResponse = {
  data: ContatoDB[];
  pageInfo: any;
};

type ContatoSavePayload = ContatoDB & {
  idContract: string;
};

const Contatos: React.FC<Props> = ({ customerPriorizationData, contracts }) => {
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [listaContatos, setListaContatos] = useState<ContatoDB[]>();
  const [selectedRemoval, setSelectedRemoval] = useState<ContatoDB>();
  const [isLoading, setLoading] = useState(false);
  const [editingContact, setEditingContact] = useState<ContatoDB>();

  useEffect(() => {
    getData();
  }, []);

  const getContatosFromContactId = async (contractId: string): Promise<GetContatosResponse> => {
    const response = await api.get<GetContatosResponse>(
      `/api/allos/v1/prioritization/contact/contractId/${contractId}`,
      {
        params: {
          size: 9999,
        },
      },
    );
    return response.data;
  };

  const getData = async () => {
    if (contracts) {
      try {
        setLoading(true);
        const contractIds = Array.from(new Set(contracts.prioritizationContractList.map(c => c.contractId)));

        const requests = contractIds.map(contractId => getContatosFromContactId(contractId));
        const responses = await Promise.all(requests);
        const allContatos = responses.flatMap(response => response.data);
        setListaContatos(allContatos);
      } catch (e) {
        failure('Falha ao tentar carregar lista, entre em contato com a equipe técnica');
      }
      setLoading(false);
    }
  };

  const saveContact = async (c: Contact) => {
    try {
      setLoading(true);
      setEditingContact(undefined);
      const payload: ContatoSavePayload = {
        idContract: contracts!.prioritizationContractList[0].contractId,
        shopping: String(customerPriorizationData.shopping!.id),
        contactName: c.nome,
        email: c.mail,
        contactType: c.tipo,
        phoneNumber: c.phone,
      };

      if (editingContact?.id) {
        await api.put(`/api/allos/v1/prioritization/contact/${c.id}`, payload);
        success('Contato atualizado com sucesso');
      } else {
        await api.post(`/api/allos/v1/prioritization/contact`, payload);
        success('Contato adicionado com sucesso');
      }

      getData();
    } catch (e) {
      failure('Falha ao tentar adicionar/editar contato, entre com contato com a equipe técnica');
      setLoading(false);
    }
  };

  const handleRemove = (contato: ContatoDB) => {
    setSelectedRemoval(contato);
    setConfirmationOpen(true);
  };

  const removeContact = async () => {
    try {
      setLoading(true);
      setConfirmationOpen(false);
      await api.delete(`/api/allos/v1/prioritization/contact/${selectedRemoval?.id}`);
      success('Contato removido com sucesso');
      getData();
    } catch (e) {
      setLoading(false);
      failure('Erro ao tentar remover contato, entre em contato com a equipe técnica');
    }
  };

  return (
    <S.Wrapper>
      <AlertDialog open={isConfirmationOpen} close={() => setConfirmationOpen(false)} handleOK={removeContact} />
      {editingContact && (
        <ModalContatos
          editingContact={editingContact}
          onSaveContact={(c: Contact) => saveContact(c)}
          onClose={() => setEditingContact(undefined)}
        />
      )}
      <S.Content>
        {isLoading ? (
          <S.LoadingWrapper>
            <CircularProgress />
          </S.LoadingWrapper>
        ) : (
          <Table listaContatos={listaContatos} onEdit={setEditingContact} onRemove={handleRemove} />
        )}
      </S.Content>
      <S.Actions>
        <Button
          onClick={() =>
            setEditingContact({
              shopping: '',
              contactName: '',
              email: '',
              contactType: '',
              phoneNumber: '',
            })
          }
          startIcon={<GroupAddIcon />}
          text="Novo Contato"
        />
      </S.Actions>
    </S.Wrapper>
  );
};

export default Contatos;
