import React, { useState, useEffect, useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import * as S from '../styles';
import api from '../../../../_core/api';
import { handleErrors, success } from '../../../../_core/services/toast';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { Button, Input, PaginatedTable } from '../../../../_core/_components';
import ConfirmationModal from '../../../../_core/_components/confirmation-modal';
import Modal from './modal';

export type Status = {
  idAjzStatusAcao: number;
  nome: string;
};

const StatusAcao: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [registerList, setRegisterList] = useState<Status[]>([]);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [searchText, setSearchText] = useState('');
  const [selectedRegister, setSelectedRegister] = useState<Status | null>(null);
  const [creationModalOpen, setCreationModalOpen] = useState<any>(false);

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get<Status[]>(`/setup/rules/status-acao`);
      setRegisterList(data);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const filteredItems = useMemo(() => {
    if (searchText) {
      const filtered = registerList.filter(reg => reg.nome.toUpperCase().includes(searchText.toUpperCase()));
      return filtered;
    }
    return registerList;
  }, [searchText, registerList]);

  const renderedData = useMemo(() => {
    if (filteredItems.length > 0) {
      return filteredItems.map(item => ({
        ...item,
        excluir: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.idAjzStatusAcao.toString()}
            onClick={() => setSelectedRegister(item)}
          >
            <DeleteIcon />
            <S.IconLabel>Remover</S.IconLabel>
          </IconButton>
        ),
      }));
    }
    return [];
  }, [filteredItems]);

  const onSave = async (payload: any) => {
    setLoading(true);
    try {
      setCreationModalOpen(false);
      await api.post('/setup/rules/status-acao', {
        nome: payload.conta,
      });
      getData();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onRemove = async () => {
    try {
      await api.delete(`/setup/rules/status-acao/${selectedRegister?.idAjzStatusAcao}`);
      success('O registro foi removido');
      getData();
      setSelectedRegister(null);
    } catch (e) {
      handleErrors(e);
    }
    setSelectedRegister(null);
  };

  return (
    <S.DataContainer>
      <FullScreenLoading isEnabled={loading} />
      {creationModalOpen && <Modal onSave={onSave} onClose={() => setCreationModalOpen(false)} />}

      {selectedRegister && (
        <ConfirmationModal
          open={Boolean(selectedRegister)}
          handleOk={() => onRemove()}
          handleCancel={() => setSelectedRegister(null)}
          text={`Confirma exclusão do item "${selectedRegister.nome}" ?`}
        />
      )}
      <S.TopBar>
        <S.SearchBar>
          <S.SearchField>
            <Input label="Buscar" state={[searchText, setSearchText]} />
          </S.SearchField>
        </S.SearchBar>
        <Button text="Criar novo" onClick={() => setCreationModalOpen(true)} />
      </S.TopBar>
      {renderedData.length > 0 && (
        <S.TableContainer>
          <PaginatedTable
            columns={[
              { label: 'ID', key: 'idAjzStatusAcao' },
              { label: 'Status da Ação', key: 'nome' },
              { label: 'Excluir', key: 'excluir' },
            ]}
            data={renderedData}
            pagination={pageInfo}
            onChangePage={setPage}
          />
        </S.TableContainer>
      )}
    </S.DataContainer>
  );
};

export default StatusAcao;
