import styled from 'styled-components';

export const SwitchText = styled.p`
  position: relative;
  left: -1rem;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
