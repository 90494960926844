export const toReal = (val: any) => {
  if (val) {
    const with2Decimals = val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    return parseFloat(with2Decimals).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }
  return 'R$ 0,00';
};

export const completarComEspacos = (palavra: string, quantidade: number): string => {
  // Verifica se a palavra já possui o tamanho mínimo desejado
  if (palavra.length >= quantidade) {
    return palavra; // Retorna a palavra original sem modificação
  }

  // Calcula quantos caracteres de espaço são necessários
  const espacosNecessarios = quantidade - palavra.length;

  // Cria uma string com espaços usando o caractere especial &nbsp;
  const espacosHtml = '&nbsp;'.repeat(espacosNecessarios);

  // Retorna a palavra concatenada com os espaços em HTML
  return palavra + espacosHtml;
};

export const truncateString = (str: string, num: number) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str && str.length <= num) {
    return str;
  }

  if (!str) {
    return '';
  }
  // Return str truncated with '...' concatenated to the end of str.
  return `${str.slice(0, num)}...`;
};

export const convertFromDatabaseDate = (date: string): string => {
  const arr = date.split('-');
  return `${arr[2]}/${arr[1]}/${arr[0]}`;
};

export const convertToDatabaseDate = (date: string): string => {
  const arr = date.split('/');
  return `${arr[2]}-${arr[1]}-${arr[0]}`;
};
