import styled from 'styled-components';

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1em 2em;
`;

export const ExcludeButton = styled.p`
  color: #d74242;
  font-weight: bold;
  cursor: pointer;
`;
