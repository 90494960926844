import React from 'react';
import { Checkbox as MaterialCheckbox, FormControlLabel } from '@material-ui/core';

type Props = {
  label?: string;
  checked: boolean;
  disabled?: boolean;
  type?: 'primary' | 'secondary';
  onClick: () => void;
};

export function Checkbox({ label, checked, disabled = false, type = 'primary', onClick }: Props): JSX.Element {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <FormControlLabel
      control={<MaterialCheckbox disabled={disabled} checked={checked} onClick={e => handleClick(e)} color={type} />}
      label={label}
    />
  );
}
