import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import api from '../../../_core/api';
import { Container, Actions, TopActions } from './styles';
import { failure, handleErrors, success } from '../../../_core/services/toast';
import { downloadXls } from '../../../_core/services/download';
import { Table } from '../../../_core/_components/table';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { Button, Checkbox } from '../../../_core/_components';
import { AuthContext } from '../../../_main/contexts/auth';
import ConfirmationModal from '../../../_core/_components/confirmation-modal';
import DetailsModal from './detailsModal';

interface Itype {
  quantity: number;
  type: string;
}

interface ICliente {
  shoppingId: number;
  shoppingName: string;
  types: Itype[];
}

export const ContratosElegiveis: React.FC = () => {
  const [clients, setClients] = useState<ICliente[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [selectedShopping, setSelectedShopping] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const { userName } = useContext(AuthContext);

  const getContratosElegiveis = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('workflow/ajuizamento/contratos-elegiveis');
      setClients(data);
    } catch (error) {
      if ((error as any).response.status) {
        failure('Já existe um processo em andamento');
      } else {
        handleErrors(error);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getContratosElegiveis();
  }, []);

  const onSingleToggle = (id: number) => {
    const arr = [...selectedIds];
    if (arr.includes(id)) {
      setSelectedIds(arr.filter(e => e !== id));
      return;
    }
    arr.push(id);
    setSelectedIds(arr);
  };

  const allToggle = useMemo(() => {
    if (clients && selectedIds.length > 0) {
      const ids = clients.map(e => e.shoppingId);
      return _.isEqual(ids, selectedIds);
    }
    return false;
  }, [clients, selectedIds]);

  const onToggleAll = () => {
    const ids = clients ? clients.map(e => e.shoppingId) : [];
    if (allToggle) {
      setSelectedIds(selectedIds.filter(e => !ids.includes(e)));
      return;
    }
    setSelectedIds([...selectedIds, ...ids]);
  };

  const handleExportButton = async () => {
    setLoading(true);
    try {
      const names = clients.filter(item => selectedIds.includes(item.shoppingId)).map(item => item.shoppingId);
      const responseXLS = await api.get(
        `workflow/ajuizamento/contratos-elegiveis/xls?shoppingsIds=${names.join(',')}`,
        {
          responseType: 'arraybuffer',
        },
      );
      downloadXls(responseXLS);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const getAllTypesValues = (types: Itype[]) => {
    const values = {};
    types.forEach((item: Itype) => {
      const name = item.type;
      Object.assign(values, { [name]: item.quantity });
    });
    return values;
  };

  const renderedTable = useMemo(() => {
    if (clients.length > 0) {
      const types = clients[0].types.map(e => ({ label: e.type, key: e.type, orderable: true }));
      const data = clients.map(item => ({
        ...item,
        ...getAllTypesValues(item.types),
        toggle: (
          <Checkbox checked={selectedIds.includes(item.shoppingId)} onClick={() => onSingleToggle(item.shoppingId)} />
        ),
      }));
      return (
        <Table
          columns={[
            {
              label: <Checkbox type="secondary" checked={allToggle} onClick={onToggleAll} />,
              key: 'toggle',
            },
            { label: 'Nome do Shopping', key: 'shoppingName', orderable: true },
            ...types,
          ]}
          data={data}
          onRowClick={(c: ICliente) => setSelectedShopping(c.shoppingId)}
        />
      );
    }
    return null;
  }, [clients, selectedIds, getAllTypesValues]);

  const submit = async () => {
    setLoading(true);
    try {
      const shoppingIds = selectedIds.join(',');
      await api.put(`/workflow/ajuizamento/liberar?shoppingIds=${shoppingIds}`);
      setSelectedIds([]);
      const { data } = await api.get('workflow/ajuizamento/contratos-elegiveis');
      setClients(data);
      success('Submetido com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const dispararElegiveis = async () => {
    try {
      setLoading(true);
      await api.get(`/ajuizamento/allos/automacao/carregar-contratos?force=true`, {
        headers: {
          'BRM-User-Identifier': userName,
        },
      });
      success('Executado com sucesso.');
      setClients([]);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
    setConfirmOpen(false);
  };

  return (
    <Container>
      <FullScreenLoading isEnabled={loading} />
      <DetailsModal
        shoppingId={selectedShopping}
        open={!!selectedShopping}
        onClose={() => setSelectedShopping(undefined)}
      />
      <ConfirmationModal
        handleCancel={() => setConfirmOpen(false)}
        open={isConfirmOpen}
        handleOk={dispararElegiveis}
        text="Atenção ao submeter os Elegíveis o sistema irá gerar uma nova lista de contratos passiveis de Ajuizamento
            com base no aging configurado, deseja seguir? Obs.: Devido a complexidade do processo essa etapa irá demorar
            a finalizar, qualquer dúvida sinalize o time técnico."
        confirmButtonText="Continuar"
      />
      {renderedTable !== null && (
        <>
          <TopActions>
            <Button variant="outlined" onClick={() => setConfirmOpen(true)} text="Disparar Elegíveis" />
          </TopActions>

          {renderedTable}
          <Actions>
            <Button onClick={handleExportButton} text="Exportar" />
            <Button onClick={submit} text="Iniciar workflow de aprovação" />
          </Actions>
        </>
      )}
    </Container>
  );
};
