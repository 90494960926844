import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal as CustomModal } from '../../../_core/_components';

type Props = {
  onSave: (value: string) => void;
  onClose: () => void;
  currentSelection: any;
};

export const Modal: React.FC<Props> = ({ onSave, onClose, currentSelection }: Props) => {
  const [obs, setObs] = useState('');

  useEffect(() => {
    setObs(currentSelection?.observation);
  }, [currentSelection]);

  return (
    <CustomModal
      title="Justificativa"
      onClose={onClose}
      content={<Form items={[<Input label="Observação" state={[obs, setObs]} />]} />}
      actionItems={[<Button onClick={() => onSave(obs)} text="Salvar" />]}
    />
  );
};
