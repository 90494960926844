import styled, { css } from 'styled-components';

export const Content = styled.div`
  font-size: 10px;
  border-radius: 6px;
  background-color: #fff;
`;
export const Header = styled.div`
  background-color: #fbfbfb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px 6px 0px 0px;
  display: flex;
  padding: 2em 1em;
`;
export const HeaderLeft = styled.div`
  flex: 0 0 40%;
  padding: 0 2em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 3em;
`;
export const HeaderRight = styled.div`
  flex: 0 0 60%;
  border-left: 1px solid #e1e1e1;
  padding: 0 2em;
`;
export const HeaderInfoLabel = styled.div`
  font-size: 1.4em;
  font-weight: 400;
  color: #8890b5;
`;
export const HeaderInfo = styled.div``;

export const HeaderInfoValue = styled.div`
  font-weight: 700;
  font-size: 1.6em;
  color: #555555;
`;

export const HeaderRightLine = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 1em 0;

  > div {
    width: 20em;
  }
`;
export const HeaderIndicatorWrapper = styled.div``;
export const HeaderIndicatorLabel = styled.div`
  font-size: 1.4em;
  font-weight: 400;
  color: #8890b5;
`;

const hoverIndicatorModifier: any = {
  red: () => css`
    color: #c27272;
  `,
  yellow: () => css`
    color: #c2b172;
  `,
  green: () => css`
    color: #72c2bd;
  `,
};

type HeaderIndicatorValueProps = {
  textColor?: string;
};
export const HeaderIndicatorValue = styled.div<HeaderIndicatorValueProps>`
  font-weight: 700;
  font-size: 1.5em;
  color: #555555;
  ${({ textColor }) => textColor && hoverIndicatorModifier[textColor]}
`;

export const TabsWrapper = styled.div`
  background-color: #fbfbfb;
  border-radius: 0 0 6px 6px;
  padding: 2em 1em;
  min-height: 10em;
  margin-top: 8em;
  position: relative;
`;
export const Tabs = styled.div`
  display: flex;
  position: absolute;
  top: -2.8em;
  left: 0em;
`;

type TabProps = {
  active?: boolean;
};

export const Tab = styled.div<TabProps>`
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 90% 0%);
  padding: 0.3em 5em 0.3em 3em;
  font-size: 1.4em;
  z-index: 6;
  background-color: #dddddd;
  color: #8e8e8e;
  position: relative;
  transition: all 0.7s ease;

  &:nth-child(2) {
    left: -1.6em;
    z-index: 5;
  }

  &:nth-child(3) {
    left: -3.4em;
    z-index: 4;
  }

  &:nth-child(4) {
    left: -4.9em;
    z-index: 3;
  }

  ${({ active }) =>
    active &&
    css`
      z-index: 7 !important;
      color: #00959c !important;
      background-color: #fbfbfb !important;
    `}

  ${({ active }) =>
    !active &&
    css`
      &:hover {
        cursor: pointer;
        color: #fff !important;
        background-color: #00959c !important;
      }
    `}
`;
export const TabsContent = styled.div``;

export const Back = styled.div`
  cursor: pointer;
  display: flex;
  gap: 1em;
  color: #00959c;
  align-items: center;

  svg {
    fill: #00959c;
  }
`;

export const LoadingBolWrapper = styled.div`
  display: flex;
  height: 20em;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

export const TopActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
  user-select: none;
  font-size: 1.2em;
`;

export const ClientChanger = styled.div`
  display: flex;
  gap: 4em;
  align-items: center;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const AccordionGroup = styled.div`
  padding: 0 1em;
`;
export const AccordionLabel = styled.span`
  font-size: 1em;
  color: #8890b5;
`;
export const AccordionValue = styled.div`
  font-size: 1.6em;
  color: #555555;
  font-weight: 600;
  width: 11em;
`;

export const Separator = styled.div`
  height: 0.1em;
  background-color: #e1e1e1;
  margin: 6em 0 2em 0;
`;
export const ActionsWrapper = styled.div`
  display: flex;
`;

export const Actions = styled.div`
  flex: 1;
`;
export const ActionLabel = styled.div``;
export const ActionButtons = styled.div`
  display: flex;
  gap: 1em;
  margin: 1em 0;
`;

export const DialogWrapper = styled.div`
  font-size: 10px;
  position: relative;
`;
export const DialogHeader = styled.div`
  background-color: #00959c;
  font-weight: bold;
  padding: 2em;
  h1 {
    font-size: 2em;
    color: #fff;
  }
`;
export const DialogContent = styled.div`
  padding: 2em;
  display: flex;
  gap: 1em;
`;

export const DialogActions = styled.div`
  margin: 2em;
  /* border-top: 1px solid #e1e1e1; */
  padding: 2em 0;
  display: flex;
  justify-content: space-between;

  button {
    margin: 0 1em;
  }
`;

export const DialogSelecao = styled.div`
  height: 30em;
  overflow-y: auto;
`;
export const DialogDados = styled.div`
  height: 30em;
  overflow-y: auto;
`;

export const Section = styled.div`
  border: 1px solid #00959c;
  position: relative;
  border-radius: 0.4em;
  height: 100%;
  padding: 1.8em 2em;
  background-color: #fbfbfb;
  flex: 0 0 calc(60% - 1em);

  :nth-of-type(1) {
    flex: 0 0 calc(40% - 1em);
  }
`;
export const SectionTitle = styled.h3`
  background-color: #00959c;
  font-size: 1em;
  color: #fff;
  position: absolute;
  padding: 0.27em 1.2em;
  top: -1em;
  left: 1em;
  border-radius: 0.4em;
`;

export const TreeLabelItem = styled.div`
  span {
    width: 8em;
    display: inline-block;
  }
`;

export const TreeLabelBolHeader = styled.div`
  color: #00959c;
  font-weight: bold;
  span {
    width: 8em;
    display: inline-block;
  }
`;

export const InputLine = styled.div`
  display: flex;
  gap: 1em;
  margin: 1em 0;
`;
export const InputGroup = styled.div`
  flex: 0 0 calc(33% - 1em);
`;

export const InputGroupFull = styled.div`
  flex: 1;
`;

export const Label = styled.div`
  color: #8890b5;
  font-size: 1.2em;
`;

export const MainBoletoInfo = styled.div`
  display: flex;
  justify-content: center;
  gap: 18em;
  padding: 2em;
  border-bottom: 1px solid #e1e1e1;
`;
export const InfoGroup = styled.div``;
export const InfoValue = styled.div`
  font-size: 2.2em;
  color: #444;
  font-weight: bold;
`;

export const ExpectativaSections = styled.div`
  display: flex;
`;
export const VerticalSection = styled.div`
  flex: 0 0 25%;
  padding: 2em;
  border: 1px solid #e1e1e1;
  border-top: none;
  border-right: none;
`;
export const VerticalSectionTittle = styled.div`
  font-size: 1.4em;
  color: #444;
  font-weight: bold;
`;
export const VerticalInputGroup = styled.div`
  margin: 2em 0;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20em;
  flex: 1;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20em;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.6;
  z-index: 999;
`;

export const ActionRelatorios = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CloseAction = styled.span`
  position: absolute;
  top: 2em;
  right: 2em;
  cursor: pointer;

  svg {
    fill: white;
  }
`;

export const SelectLabel = styled.p`
  color: #6d6d6d;
`;
