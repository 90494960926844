import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { removeInterceptor, setBearerToken, setInterceptor } from '../../_core/api';
import { Route } from '../routes/_route';

type Shopping = {
  id: number;
  name: string;
};

interface AuthState {
  id: number;
  token: string;
  name: string;
  userName: string;
  email: string;
  profile: string;
  shoppingProfileName?: string | null;
  shoppingProfileId?: string | null;
  shoppings?: Shopping[] | null;
  routes: Route[];
}

interface SignInCredentials {
  id: number;
  token: any;
  name: any;
  userName: string;
  email: any;
  profile: any;
  shoppingProfileName: string;
  shoppingProfileId: string;
  shoppings?: Shopping[] | null;
  routes: Route[];
}

interface AuthContextData {
  id: number;
  name: string;
  token: string;
  userName: string;
  email: string;
  profile: string;
  shoppingProfileName?: string | null;
  shoppingProfileId?: string | null;
  shoppings?: Shopping[] | null;
  routes: Route[];
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const { instance, accounts } = useMsal();

  const [data, setData] = useState<AuthState>(() => {
    const id = localStorage.getItem('@Siscob:id');
    const token = localStorage.getItem('@Siscob:token');
    const name = localStorage.getItem('@Siscob:name');
    const userName = localStorage.getItem('@Siscob:userName');
    const email = localStorage.getItem('@Siscob:email');
    const profile = localStorage.getItem('@Siscob:profile');
    const shoppingProfileName = localStorage.getItem('@Siscob:shoppingProfileName');
    const shoppingProfileId = localStorage.getItem('@Siscob:shoppingProfileId');
    const localShoppings = localStorage.getItem('@Siscob:shoppings');
    const routes = localStorage.getItem('@Siscob:routes');

    const shoppings = localShoppings ? JSON.parse(localShoppings) : [];

    if (id && token && userName && email && profile && name && routes) {
      return {
        id: parseInt(id, 10),
        token,
        name,
        userName,
        email,
        profile,
        shoppingProfileName,
        shoppingProfileId,
        shoppings,
        routes: JSON.parse(routes),
      };
    }
    return {} as AuthState;
  });

  const signIn = useCallback(
    async ({
      id,
      token,
      name,
      userName,
      email,
      profile,
      shoppingProfileName,
      shoppingProfileId,
      shoppings,
      routes,
    }) => {
      localStorage.setItem('@Siscob:id', id);
      localStorage.setItem('@Siscob:token', token);
      localStorage.setItem('@Siscob:name', name);
      localStorage.setItem('@Siscob:userName', userName);
      localStorage.setItem('@Siscob:email', email);
      localStorage.setItem('@Siscob:profile', profile);
      localStorage.setItem('@Siscob:shoppingProfileName', shoppingProfileName);
      localStorage.setItem('@Siscob:shoppingProfileId', shoppingProfileId);
      localStorage.setItem('@Siscob:shoppings', JSON.stringify(shoppings));

      localStorage.setItem('@Siscob:routes', JSON.stringify(routes));
      setData({ id, token, name, userName, email, profile, shoppingProfileName, shoppingProfileId, shoppings, routes });
    },
    [],
  );

  const signOut = async () => {
    localStorage.removeItem('@Siscob:id');
    localStorage.removeItem('@Siscob:token');
    localStorage.removeItem('@Siscob:name');
    localStorage.removeItem('@Siscob:userName');
    localStorage.removeItem('@Siscob:email');
    localStorage.removeItem('@Siscob:profile');
    localStorage.removeItem('@Siscob:shoppingProfileName');
    localStorage.removeItem('@Siscob:shoppingProfileId');
    localStorage.removeItem('@Siscob:shoppings');
    localStorage.removeItem('@Siscob:routes');
    setData({} as AuthState);
    // try {
    //   const { homeAccountId } = accounts[0];
    //   const logoutRequest = {
    //     account: instance.getAccountByHomeId(homeAccountId),
    //     postLogoutRedirectUri: 'https://siscob-hml.integracao.brmalls.com.br/',
    //     mainWindowRedirectUri: 'https://siscob-hml.integracao.brmalls.com.br/',
    //   };
    //   await instance.logoutPopup(logoutRequest);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  setBearerToken(data.token);

  useEffect(() => {
    if (data.id) {
      setInterceptor();
    } else {
      removeInterceptor();
    }
  }, [data]);

  return (
    <AuthContext.Provider
      value={{
        id: data.id,
        token: data.token,
        name: data.name,
        userName: data.userName,
        email: data.email,
        profile: data.profile,
        shoppingProfileName: data.shoppingProfileName,
        shoppingProfileId: data.shoppingProfileId,
        shoppings: data.shoppings,
        routes: data.routes,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
