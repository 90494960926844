import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import api from '../../../_core/api';
import { AuthContext } from '../../../_main/contexts/auth';
import { Container, Wrapper } from './styles';
import { handleErrors, success, warning } from '../../../_core/services/toast';
import Table from '../../../_core/_components/table';
import { Button, Form, Option, Select } from '../../../_core/_components';

interface IData {
  idShopping: number;
  quantidadeBoletos: number;
  quantidadeClientes: number;
  quantidadeFiador: number;
  quantidadeExcecao: number;
  shopping: {
    nomeShopping: string;
  };
  cicloNegativacao: {
    id: number;
  };
}

interface ITotal {
  totalBoletos: number;
  totalClientes: number;
  totalFiadores: number;
  totalExcecoes: number;
}

export const NegativarLojista: React.FC = () => {
  const { id, shoppings: profileShoppings } = useContext(AuthContext);
  const [data, setData] = useState<IData[]>([]);
  const [shoppings, setShoppings] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);

  useEffect(() => {
    if (profileShoppings) {
      const listShopping: Option[] = profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
      listShopping.sort((item1: Option, item2: Option) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppings(listShopping);
      setSelectedShoppings(listShopping);
    }
  }, [profileShoppings]);

  const total = useMemo((): ITotal => {
    const values = {
      totalBoletos: 0,
      totalClientes: 0,
      totalFiadores: 0,
      totalExcecoes: 0,
    };
    if (data.length > 0) {
      data.forEach(item => {
        values.totalBoletos += item.quantidadeBoletos;
        values.totalClientes += item.quantidadeClientes;
        values.totalFiadores += item.quantidadeFiador;
        values.totalExcecoes += item.quantidadeExcecao;
      });
    }
    return values;
  }, [data]);

  const renderedData = useMemo(() => {
    if (data.length > 0) {
      return data.map(item => ({
        nmShopping: item.shopping.nomeShopping,
        quantidadeClientes: item.quantidadeClientes,
        quantidadeBoletos: item.quantidadeBoletos,
        quantidadeFiador: item.quantidadeFiador,
        quantidadeExcecao: item.quantidadeExcecao,
      }));
    }
    return [];
  }, [data]);

  const getClientes = async () => {
    setLoading(true);
    try {
      const arr: IData[] = [];
      const response = await api.post('/workflow/serasa/serasa-shopping/totalshopping/', {
        shoppings: selectedShoppings.map(item => item.value),
        codigoStatus: 'PENDENTE_DE_ENVIO',
      });
      if (response.data.content && response.data.content.length > 0) {
        response.data.content.forEach((item: IData) => {
          arr.push(item);
        });
        setData(arr);
      } else {
        setData([]);
        warning('Não obteve resultados.');
      }
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const submit = async () => {
    if (data.length === 0) return;
    setLoading(true);
    try {
      await api.post('/workflow/serasa/serasa-negativacao/negativar-boletos', {
        shoppings: data.map(item => item.idShopping),
        idCiclo: data[0].cicloNegativacao.id,
        idUsuario: id,
      });
      success('Submetido com sucesso.');
      await getClientes();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  return (
    <>
      <FullScreenLoading isEnabled={loading} />
      <Wrapper>
        <Container>
          <Form
            items={[<Select state={[selectedShoppings, setSelectedShoppings]} options={shoppings} />]}
            submitButton={<Button onClick={getClientes} text="Pesquisar" />}
          />
          {renderedData.length > 0 && (
            <>
              <Table
                columns={[
                  { label: 'Nome do Shopping', key: 'nmShopping', orderable: true },
                  { label: 'Clientes', key: 'quantidadeClientes', orderable: true },
                  { label: 'Boletos', key: 'quantidadeBoletos', orderable: true },
                  { label: 'Fiadores', key: 'quantidadeFiador', orderable: true },
                  { label: 'Exceções', key: 'quantidadeExcecao', orderable: true },
                ]}
                data={renderedData}
                footer={[
                  'Quantidade total',
                  total.totalClientes,
                  total.totalBoletos,
                  total.totalFiadores,
                  total.totalExcecoes,
                ]}
              />
              <Button text="ENVIAR PARA NEGATIVAÇÃO" onClick={submit} />
            </>
          )}
        </Container>
      </Wrapper>
    </>
  );
};
