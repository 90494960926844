import React from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import Routes from './routes';
import { AuthProvider } from './contexts/auth';
import { PriorizacaoProvider } from './contexts/priorizacao';
import { ParametrosCalculoInadimplenciaProvider } from './contexts/parametros-calculo-inadimplencia';
import { ToastContainer } from '../_core/services/toast';
import './App.scss';
import { msalConfig } from '../_core/config/authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: twentyFourHoursInMs,
      staleTime: twentyFourHoursInMs,
      retry: false,
    },
  },
});

const App: React.FC = () => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#008C90',
      },
    },
  });

  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AuthProvider>
              <PriorizacaoProvider>
                <ParametrosCalculoInadimplenciaProvider>
                  <Routes />
                </ParametrosCalculoInadimplenciaProvider>
              </PriorizacaoProvider>
            </AuthProvider>
          </BrowserRouter>
          <ToastContainer />
        </QueryClientProvider>
      </ThemeProvider>
    </MsalProvider>
  );
};

export default App;
