import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 10px;
  padding: 3em;
`;
export const Title = styled.div`
  color: #00959c;
  font-weight: bold;
  font-size: 1.6em;
`;
export const InputGroup = styled.div`
  margin: 2em 0;
`;
export const Actions = styled.div`
  display: flex;
  gap: 0 2em;
  justify-content: flex-end;
`;

export const Label = styled.div`
  color: #8890b5;
  font-size: 1.2em;
`;
