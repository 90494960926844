import React, { useEffect, useMemo, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { handleErrors } from '../../../../_core/services/toast';
import * as S from '../styles';
import api from '../../../../_core/api';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { Button, Checkbox, Form, Input, Modal, SingleSelect, Table } from '../../../../_core/_components';

export function TipoMovimentoAcaoDivida(): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [registerList, setRegisterList] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRegister, setSelectedRegister] = useState<any>();
  const [arrTipoAcao, setArrTipoAcao] = useState<any[]>([]);
  const [arrTipoMovimento, setArrTipoMovimento] = useState<any[]>([]);
  const [arrTipoDivida, setArrTipoDivida] = useState<any[]>([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const { data: dataTipoMovimento } = await api.get<any[]>(`/setup/rules/movimentos`);
      const { data: dataTipoAcao } = await api.get('/setup/rules/tipo-acao');
      const { data: dataTipoDivida } = await api.get(`/setup/rules/tipo-divida`);
      const { data } = await api.get<any[]>(`/setup/rules/tipo-movimento-acao-divida`);
      setRegisterList(data);
      setArrTipoAcao(dataTipoAcao.map((e: any) => ({ label: e.nomTipoAcao, value: e.idAjzTipoAcao })));
      setArrTipoMovimento(dataTipoMovimento.map(e => ({ label: e.codMovimento, value: e.idTipoMovimento })));
      setArrTipoDivida(dataTipoDivida.map((e: any) => ({ label: e.codTipoDivida, value: e.idAjzTipoDivida })));
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const filteredItems = useMemo(() => {
    if (searchText) {
      const filtered = registerList.filter(reg => reg.codTipoDivida.toUpperCase().includes(searchText.toUpperCase()));
      return filtered;
    }
    return registerList;
  }, [searchText, registerList]);

  const renderedData = useMemo(() => {
    if (filteredItems.length > 0) {
      return filteredItems.map(item => ({
        ...item,
        flagAtivoString: item.flagAtivo ? 'Sim' : 'Não',
        dsTipoAcao: arrTipoAcao.find(e => e.value === item.idAjzTipoAcao)?.label,
        dsTipoDivida: arrTipoDivida.find(e => e.value === item.idAjzTipoDivida)?.label,
        editar: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.idAjzTipoMovimentoAcaoDivida.toString()}
            onClick={() => setSelectedRegister(item)}
          >
            <Edit />
          </IconButton>
        ),
        excluir: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.idAjzTipoMovimentoAcaoDivida.toString()}
            onClick={() => setSelectedRegister(item)}
          >
            <Delete />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [filteredItems, arrTipoAcao, arrTipoDivida]);

  const onSave = async () => {
    setLoading(true);
    try {
      if (selectedRegister?.idAjzTipoMovimentoAcaoDivida) {
        await api.put(
          `/setup/rules/tipo-movimento-acao-divida/${selectedRegister!.idAjzTipoMovimentoAcaoDivida}`,
          selectedRegister,
        );
      } else {
        await api.post('/setup/rules/tipo-movimento-acao-divida', selectedRegister);
      }
      setSelectedRegister(null);
      const { data } = await api.get<any[]>(`/setup/rules/tipo-movimento-acao-divida`);
      setRegisterList(data);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const modalContent = selectedRegister && (
    <Form
      items={[
        <SingleSelect
          state={[
            arrTipoMovimento.find(e => e.value === selectedRegister.idAjzTipoMovimento),
            option => setSelectedRegister({ ...selectedRegister, idAjzTipoMovimento: option?.value as number }),
          ]}
          options={arrTipoMovimento}
          placeholder="Tipo Movimento"
        />,
        <SingleSelect
          state={[
            arrTipoAcao.find(e => e.value === selectedRegister.idAjzTipoAcao),
            option => setSelectedRegister({ ...selectedRegister, idAjzTipoAcao: option?.value as number }),
          ]}
          options={arrTipoAcao}
          placeholder="Tipo Ação"
        />,
        <SingleSelect
          state={[
            arrTipoDivida.find(e => e.value === selectedRegister.idAjzTipoDivida),
            option => setSelectedRegister({ ...selectedRegister, idAjzTipoDivida: option?.value as number }),
          ]}
          options={arrTipoDivida}
          placeholder="Tipo Dívida"
        />,
        <Checkbox
          label="Ativo"
          checked={selectedRegister.flagAtivo}
          onClick={() => setSelectedRegister({ ...selectedRegister, flagAtivo: !selectedRegister.flagAtivo })}
        />,
      ]}
    />
  );

  return (
    <S.DataContainer>
      <FullScreenLoading isEnabled={loading} />
      {selectedRegister && (
        <Modal
          title="Criar/Editar Tipo de Movimento"
          onClose={() => setSelectedRegister(undefined)}
          content={modalContent}
          actionItems={[
            <Button
              text="Salvar"
              onClick={onSave}
              disabled={[
                selectedRegister.idAjzTipoMovimento,
                selectedRegister.idAjzTipoAcao,
                selectedRegister.idAjzTipoDivida,
              ].some(e => e === undefined)}
            />,
          ]}
        />
      )}
      <S.TopBar>
        <S.SearchBar>
          <S.SearchField>
            <Input label="Buscar" state={[searchText, setSearchText]} />
          </S.SearchField>
        </S.SearchBar>
        <Button
          text="Criar novo"
          onClick={() =>
            setSelectedRegister({
              idAjzTipoMovimento: 0,
              idAjzTipoAcao: 0,
              idAjzTipoDivida: 0,
              flagAtivo: true,
            })
          }
        />
      </S.TopBar>
      {renderedData.length > 0 && (
        <Table
          columns={[
            { label: 'ID', key: 'idAjzTipoMovimentoAcaoDivida' },
            { label: 'Tipo de Movimento', key: 'dsTipoMovimentoAcaoDivida' },
            { label: 'Tipo de ação', key: 'dsTipoAcao' },
            { label: 'Tipo de dívida', key: 'dsTipoDivida' },
            { label: 'Ativo', key: 'flagAtivoString' },
            { label: 'Editar', key: 'editar' },
          ]}
          data={renderedData}
        />
      )}
    </S.DataContainer>
  );
}
