import styled from 'styled-components/macro';
import DatePicker from 'react-datepicker';

export const Content = styled.div``;

export const Filters = styled.div`
  border-radius: 6px 6px 0 0;
  background-color: #fff;
  padding: 3em 4em;
`;

export const Result = styled.div`
  border-radius: 0 0 6px 6px;
  background-color: #fff;
`;

export const Inputs = styled.div`
  display: flex;
  gap: 4em;
  margin-bottom: 3em;
`;
export const InputGroup = styled.div``;
export const InputLabel = styled.div`
  color: #777777;
  font-size: 1.4em;
  margin-bottom: 0.7em;
`;

export const Dpicker = styled(DatePicker)`
  border: 1px solid #0000003b;
  border-radius: 4px;
  width: 129px;
  height: 39px;
  padding: 8px 10px;
  text-align: center;
  color: #7e9395;
`;

export const SkeletonStyle = {
  height: '4em',
  margin: '1.5em 0',
};

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;
