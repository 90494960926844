import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
  background-color: #fff;
  border-radius: 15px;
  position: relative;
  > h2 {
    color: #7e9395;

    margin-bottom: 1rem;
    font-weight: 600;
  }

  > p {
    color: #00959c;
  }
`;

export const Actions = styled.div`
  display: flex;
  margin-top: 2rem;
  gap: 1rem;

  .right-aligned {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    button {
      margin: 0 1rem;
    }
  }
`;

export const TopActions = styled.div`
  margin: 10px 0;
`;

export const BottomActions = styled.div``;
