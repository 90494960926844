import styled from 'styled-components/macro';

export const Container = styled.div`
  background-color: #f9f9f9;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

export const SideMenu = styled.div`
  display: flex;
  height: 30px;
`;

export const ItemMenu = styled.div<{ active: boolean }>`
  cursor: pointer;
  width: 220px;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  font-weight: 600;
  background-color: ${({ active }) => (active ? '#008C90' : '#d8d8d8')};
  color: ${({ active }) => (active ? '#fff' : '#6d6d6d')};

  &:hover {
    background-color: #008c90;
    color: #fff;
  }
`;

export const DataContainer = styled.div`
  padding: 1.5rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

export const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const SearchBar = styled.div``;

export const SearchField = styled.div``;
