import React, { useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { Form, Input, Option, SingleSelect } from '../../../../_core/_components';
import { CONTATO, EditShoppingProperty } from './reducer';
import { mascaraDDMMAAAA, mascaraNumeros } from '../../../../_core/masks';
import { Contato, TipoContato } from './types';
import { estados } from '../estados-cidades.json';
import { ORIGENS } from '..';

const TIPOS_CONTATO: Option[] = [
  {
    value: 'EMAIL',
    label: 'EMAIL',
  },
  {
    value: 'TELEFONE',
    label: 'TELEFONE',
  },
];

type Props = {
  form: any;
  dispatch: React.Dispatch<{
    type: EditShoppingProperty;
    payload: any;
  }>;
};

const ModalContent: React.FC<Props> = ({ form, dispatch }: Props) => {
  const onClickDelete = (index: number) => {
    const arr = [...form.contatos];
    if (arr.length > 1) {
      arr.splice(index, 1);
      dispatch({ type: 'SET_CONTATOS', payload: arr });
    }
  };

  const camposContatos = useMemo(() => {
    const setTipoContato = (payload: Contato, index: number) => {
      const arr = [...form.contatos];
      arr[index] = payload;
      return arr;
    };
    const arr: any[] = [];
    form.contatos.forEach((item: Contato, i: number) => {
      arr.push(
        <SingleSelect
          state={[
            { label: item.tipo, value: item.tipo },
            option =>
              dispatch({
                type: 'SET_CONTATOS',
                payload: setTipoContato({ tipo: option!.value as TipoContato, contato: item.contato }, i),
              }),
          ]}
          options={TIPOS_CONTATO}
          isClearable={false}
        />,
      );
      arr.push(
        <Input
          state={[
            item.contato,
            (value: React.SetStateAction<string>) =>
              dispatch({
                type: 'SET_CONTATOS',
                payload: setTipoContato({ tipo: item.tipo, contato: value as string }, i),
              }),
          ]}
          label="Contato"
        />,
      );
      const removeButton = (
        <IconButton onClick={() => onClickDelete(i)}>
          <Delete />
        </IconButton>
      );
      const addButton = (
        <IconButton onClick={() => dispatch({ type: 'SET_CONTATOS', payload: [...form.contatos, CONTATO] })}>
          <Add />
        </IconButton>
      );
      if (form.contatos.length === i + 1) {
        arr.push(
          <>
            {addButton}
            {removeButton}
          </>,
        );
      } else {
        arr.push(removeButton);
      }
    });
    return arr;
  }, [form.contatos]);

  return (
    <Form
      items={[
        <Input
          state={[form.nome, (value: React.SetStateAction<string>) => dispatch({ type: 'SET_NOME', payload: value })]}
          label="Nome"
        />,
        <Input
          state={[
            form.dataInicio,
            (value: React.SetStateAction<string>) =>
              dispatch({ type: 'SET_DATA_INICIO', payload: mascaraDDMMAAAA(value.toString()) }),
          ]}
          label="Data de início"
        />,
        <Input
          state={[
            form.dataFim,
            (value: React.SetStateAction<string>) =>
              dispatch({ type: 'SET_DATA_FIM', payload: mascaraDDMMAAAA(value.toString()) }),
          ]}
          label="Data de fim"
        />,
        <Input
          pattern={mascaraNumeros}
          state={[
            form.participacao,
            (value: React.SetStateAction<string>) => dispatch({ type: 'SET_PARTICIPACAO', payload: value }),
          ]}
          label="Participação"
        />,
        <Input
          state={[
            form.portfolio,
            (value: React.SetStateAction<string>) => dispatch({ type: 'SET_PORTFOLIO', payload: value }),
          ]}
          label="Portfólio"
        />,
        <Input
          state={[
            form.codigoUnidadeEconomica,
            (value: React.SetStateAction<string>) => dispatch({ type: 'SET_UNIDADE_ECONOMICA', payload: value }),
          ]}
          label="Unidade econômica"
        />,
        <SingleSelect
          state={[
            { label: form.origem, value: form.origem },
            option => dispatch({ type: 'SET_ORIGEM', payload: option?.value }),
          ]}
          placeholder="Origem"
          options={ORIGENS}
        />,
        <Input
          pattern={mascaraNumeros}
          state={[
            form.endereco.cep,
            (value: React.SetStateAction<string>) => dispatch({ type: 'SET_CEP', payload: value }),
          ]}
          label="CEP"
        />,
        <Input
          state={[
            form.endereco.logradouro,
            (value: React.SetStateAction<string>) => dispatch({ type: 'SET_ENDERECO', payload: value }),
          ]}
          label="Logradouro"
        />,
        <SingleSelect
          state={[
            form.endereco?.uf ? { label: form.endereco.uf, value: form.endereco.uf } : undefined,
            option =>
              dispatch({
                type: 'SET_UF',
                payload: option?.value,
              }),
          ]}
          options={estados.map(e => ({ label: e.sigla, value: e.sigla }))}
          placeholder="UF"
        />,
        <Input
          state={[
            form.endereco?.cidade ?? '',
            (value: React.SetStateAction<string>) => dispatch({ type: 'SET_CIDADE', payload: value }),
          ]}
          label="Cidade"
        />,
        null,
        ...camposContatos,
      ]}
    />
  );
};

export default ModalContent;
