import styled from 'styled-components';

type OrderableProps = {
  arrowDirection?: 'desc' | 'asc';
};

export const Container = styled.div`
  width: 100%;
`;

export const CustomTable = styled.table`
  width: 100%;
  text-align: left;
  border-spacing: 0px;
`;

export const Row = styled.tr`
  color: #3f3f3f;
  font-size: 14px;
`;

export const SubRowItem = styled.td`
  padding: 0.15rem 1rem;
`;

export const RowItem = styled.td`
  padding: 8px 12px;
`;

export const TableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 10;

  tr td:not(:last-child) {
    border-right: 1px white solid;
  }

  tr td:first-child {
    border-top-left-radius: 5px;
  }

  tr td:last-child {
    border-top-right-radius: 5px;
  }
`;

export const HeaderItem = styled.td`
  color: #fff;
  background-color: #008c90;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  padding: 8px 12px;
`;

export const Orderable = styled.div<OrderableProps>`
  cursor: pointer;
  width: fit-content;
  svg {
    display: ${props => (props.arrowDirection ? 'initial' : 'none')};
    margin-left: 5px;
    transform: scaleY(${props => (props.arrowDirection === 'desc' ? 1 : -1)});
  }
`;

export const TableBody = styled.tbody``;

export const Footer = styled.tfoot``;

export const FooterRow = styled.tr`
  background-color: #7e9395;
  color: #fff;
  opacity: 1;
`;

export const FooterItem = styled.td`
  padding: 1rem;
`;

export const PaginationWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  .MuiPaginationItem-root {
    margin: 0 !important;
    border-radius: 0 !important;
    border: 1px solid #edeeee !important;
    color: #7e9395 !important;
    font-weight: 600 !important;
    font-family: 'Open Sans', sans-serif !important ;
  }

  .MuiPaginationItem-page.Mui-selected {
    background-color: #edeeee !important;
  }

  .MuiPaginationItem-ellipsis {
    border: none !important;
  }
`;
