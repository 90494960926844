import React, { useMemo, useState, useEffect, useReducer } from 'react';
import { Edit } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import api from '../../../../_core/api';
import * as S from '../styles';
import { Button, Input, Option, PaginatedTable, Modal } from '../../../../_core/_components';
import { handleErrors, success } from '../../../../_core/services/toast';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { CONTATO, INITIAL_STATE, reducer } from './reducer';
import { requests } from '../../../../requests';
import { useCache } from '../../../../_core/cache';
import ModalContent from './modal-content';

export const Empresa: React.FC = () => {
  const { response, isLoading: isLoadingCache } = useCache({
    key: 'getTipoEmpresa',
    fn: requests.get('getTipoEmpresa'),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [nomEmpresa, setNomEmpresa] = useState('');
  const [empresas, setEmpresas] = useState<any[]>([]);
  const [form, dispatch] = useReducer(reducer, undefined);

  const tipoEmpresaOptions: Option[] = useMemo(() => {
    if (response !== undefined) {
      return response.data.map((item: any) => ({
        value: item.id,
        label: item.nome,
      }));
    }
    return [];
  }, [response]);

  useEffect(() => {
    getEmpresas(1);
  }, []);

  const getEmpresas = async (page: number) => {
    setIsLoading(true);
    try {
      const queryNomeEmpresa = nomEmpresa.length > 0 ? `&razaoSocial=${nomEmpresa}` : '';
      const { data } = await api.get(`/empresas?page=${page}${queryNomeEmpresa}`);
      setEmpresas(data.data);
      setPageInfo(data.pageInfo);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const data = useMemo(() => {
    return empresas.map(e => ({
      ...e,
      editar: (
        <IconButton
          onClick={() =>
            dispatch({
              type: 'SET_EMPRESA',
              payload: {
                ...e,
                contatos: e.contatos?.length > 0 ? e.contatos : [CONTATO],
              },
            })
          }
        >
          <Edit />
        </IconButton>
      ),
    }));
  }, [empresas, nomEmpresa]);

  const onClickSave = async () => {
    try {
      setIsLoading(true);
      const payload = {
        ...form,
        idErp: parseInt(form.idErp, 10),
      };
      if (form.id) {
        await api.put(`/empresas/${form.id}`, payload);
      } else {
        await api.post(`/empresas`, payload);
      }
      dispatch({ type: 'UNSET_EMPRESA' });
      const { data } = await api.get(`/empresas?page=1`);
      setEmpresas(data.data);
      setPageInfo(data.pageInfo);
      success('Empresa salva com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      {form && (
        <Modal
          title="Adicionar / Alterar Empresa"
          onClose={() => dispatch({ type: 'UNSET_EMPRESA' })}
          content={<ModalContent form={form} dispatch={dispatch} tipoEmpresaOptions={tipoEmpresaOptions} />}
          actionItems={[<Button onClick={onClickSave} text="Salvar" />]}
        />
      )}
      <FullScreenLoading isEnabled={isLoading || isLoadingCache} />
      <S.DataContainer>
        <S.TopBar>
          <S.SearchBar>
            <S.SearchField>
              <Input
                state={[nomEmpresa, setNomEmpresa]}
                label="Buscar pela razão social"
                onBlur={() => getEmpresas(1)}
              />
            </S.SearchField>
          </S.SearchBar>
          <Button text="Novo" onClick={() => dispatch({ type: 'SET_EMPRESA', payload: INITIAL_STATE })} />
        </S.TopBar>
        {empresas.length > 0 && (
          <PaginatedTable
            columns={[
              { label: 'Razão Social', key: 'razaoSocial' },
              { label: 'CNPJ', key: 'cnpj' },
              { label: 'ID ERP', key: 'idErp' },
              { label: 'Editar', key: 'editar' },
            ]}
            data={data}
            pagination={pageInfo}
            onChangePage={getEmpresas}
          />
        )}
      </S.DataContainer>
    </>
  );
};
