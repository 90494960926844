import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 2rem;
`;

export const Content = styled.div`
  flex: 4;
  h1 {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: #6d6d6d;
  }
`;

export const ImportArea = styled.div`
  cursor: pointer;
  width: 100%;
  height: 370px;
  background: transparent linear-gradient(180deg, #31c6d30d 0%, #00959c27 100%) 0% 0% no-repeat padding-box;
  border: 1px dashed #7e939580;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 1rem;

  h2 {
    font-weight: 600;
    color: #7e9395;
    width: 100%;
    text-align: center;
  }
  p {
    width: 100%;
    text-align: center;
    color: #7e9395;
  }
  span {
    width: 100%;
    text-align: center;
    color: #7e9395;
  }
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;
`;

export const InputGroupColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
`;
