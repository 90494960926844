import React from 'react';
import { Grid } from '@material-ui/core';
import { Checkbox, Form, Input } from '../../../../_core/_components';
import { TipoContrato } from './types';

type Props = {
  state: [TipoContrato, React.Dispatch<React.SetStateAction<TipoContrato | undefined>>];
};

export const ModalContent: React.FC<Props> = ({ state }: Props) => {
  const [tipoContrato, setTipoContrato] = state;

  return (
    <Grid>
      <Form
        items={[
          <Input
            label="Código"
            state={[tipoContrato.codigo, value => setTipoContrato({ ...tipoContrato, codigo: value.toString() })]}
          />,
          <Input
            label="Descrição"
            state={[tipoContrato.descricao, value => setTipoContrato({ ...tipoContrato, descricao: value.toString() })]}
          />,
          <Checkbox
            label="Ativo"
            checked={tipoContrato.ativo}
            onClick={() => setTipoContrato({ ...tipoContrato, ativo: !tipoContrato.ativo })}
          />,
        ]}
      />
    </Grid>
  );
};
