import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { toReal } from '../../../../_core/services/formaters';
import { Title } from '../types';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#00959c',
      color: '#fff',
      fontWeight: 'bold',
      fontSize: 10,
    },
    body: {
      fontSize: 10,
    },
    root: {
      padding: '4px',
      borderBottom: 'none',
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#fff',
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  table: {
    margin: '2em auto',
    width: '95%',
  },
});

type Props = {
  transacoes?: Title[];
};

const CustomizedTables: React.FC<Props> = ({ transacoes }) => {
  const classes = useStyles();

  return (
    <Table className={classes.table} aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell>Nº Documento</StyledTableCell>
          <StyledTableCell>Tipo Documento</StyledTableCell>
          <StyledTableCell>Tipo Movimento</StyledTableCell>
          <StyledTableCell>Competência</StyledTableCell>
          <StyledTableCell>Data Vencimento</StyledTableCell>
          <StyledTableCell>Data Venc. Original</StyledTableCell>
          <StyledTableCell>Valor</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transacoes?.map(row => (
          <>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                {row.numeroTitulo}
              </StyledTableCell>
              <StyledTableCell>{row.nomeTitulo}</StyledTableCell>
              <StyledTableCell>{row.tipoMovimento}</StyledTableCell>
              <StyledTableCell>{moment(row.dataCompetencia).format('DD/MM/YYYY')}</StyledTableCell>
              <StyledTableCell>{moment(row.dataVencimento).format('DD/MM/YYYY')}</StyledTableCell>
              <StyledTableCell>
                {row.dataContabilizacao ? moment(row.dataContabilizacao).format('DD/MM/YYYY') : '-'}
              </StyledTableCell>
              <StyledTableCell>{toReal(row.valorVencimentoOriginal)}</StyledTableCell>
            </StyledTableRow>
          </>
        ))}
      </TableBody>
    </Table>
  );
};

export default CustomizedTables;
